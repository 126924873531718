import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NpiApi } from 'src/app/api/npi.api';
import { NpiInfo } from 'src/app/shared/models/npi';
import { ApplicationCacheService } from 'src/app/shared/providers/application-cache.service';
import { NavigationService } from 'src/app/shared/providers/navigation.service';
import { AnalyzePageStorageInfo, AnalyzePageTab, LOCAL_STORAGE_KEY, PORTAL_TYPE, PROVIDER_TYPE, ROUTE_CONSTANTS, ProviderTypeArray, AnalyzePortalPageTab } from 'src/app/shared/shared.constants';
import Utils from 'src/app/shared/Utils';
import { AnalyzeDataCacheService } from './analyze-data-cache.service';

export interface AnalyzeTabNavLink {
	tabGuid: string;
	display: string;
	title: string;
	route: string;
	showRemove: boolean;
	colorClass: string;
	icon: string;
	providerType?: PROVIDER_TYPE;
	id: string;
}

@Injectable({
	providedIn: 'root'
})
export class AnalyzeTabService {
	tabRemoved = new Subject<AnalyzePageTab>();
	currentTabNavLinks: AnalyzeTabNavLink[];
	npiListExpanded = false;
	showNpiList = true;

	get storageKey() {
		return this.appCache.userSpecificKey(LOCAL_STORAGE_KEY.ANALYZE_PAGE);
	}

	get usersPortals() {
		return this.appCache.user && this.appCache.user.portals;
	}

	get portalTabs(): AnalyzePageTab[] {
		let tabs = this.localPageInfo && this.localPageInfo.portalTabs.find(p => p.portal == this.portal);
		// user had new portal added to their profile
		if (!tabs) {
			this.localPageInfo = this.initializePortalTabs(this.localPageInfo);
			tabs = this.localPageInfo && this.localPageInfo.portalTabs.find(p => p.portal == this.portal);
		}
		return tabs && tabs.openTabs;
	}

	get localPageInfo(): AnalyzePageStorageInfo {
		const pageInfo = localStorage.getItem(this.storageKey);
		if (pageInfo) {
			return JSON.parse(pageInfo);
		}

		const newInfo: AnalyzePageStorageInfo = {
			userId: this.appCache.userId,
			portalTabs: [],
			openTabs: []
		};

		this.localPageInfo = this.initializePortalTabs(newInfo);
		return newInfo;
	}

	set localPageInfo(info: AnalyzePageStorageInfo) {
		localStorage.setItem(this.storageKey, JSON.stringify(info));
	}

	get currentPageTabs(): AnalyzePageTab[] {
		const allTabs = this.localPageInfo.openTabs;
		const portalTabs = this.localPageInfo.portalTabs;

		// if openTabs still has data, perform migration to the portalTabs model
		if (allTabs && allTabs.length) {
			this.localPageInfo = this.createPortalSpecificProviderTabs(allTabs)
		}

		return this.portalTabs;
	}

	get portal() {
		return this.appCache.portal;
	}

	constructor(private navigationService: NavigationService,
		private lookupApi: NpiApi, private analyzeDataCacheService: AnalyzeDataCacheService, private appCache: ApplicationCacheService) { }

	async navigateToDefault() {
		this.cleanInvalidTabs();
		const currentTabs = this.currentPageTabs;
		if (!currentTabs.length) {
			await this.navigationService.navigateToNewAnalyzeTab();
			return;
		}
		const providerTypes = Utils.getAvailableProviderTypesForPortal(this.portal);

		const currentTab = this.portalTabs.reverse().find(x => providerTypes.includes(x.type));
		if (currentTab) {
			await this.navigationService.navigateToAnalyzeTab(currentTab);
			return;
		}
		await this.navigationService.navigateToNewAnalyzeTab();
	}

	initializePortalTabs(newInfo: AnalyzePageStorageInfo) {
		this.appCache.user.portals.forEach(portal => {
			if (!newInfo.portalTabs.find(p => p.portal == this.portal)) {
				const portalTabs: AnalyzePortalPageTab = { portal: portal, openTabs: [] };
				newInfo.portalTabs.push(portalTabs);
			}
		});

		return newInfo;
	}

	updateTabs(tabs: AnalyzePageTab[]) {
		if (!this.localPageInfo.portalTabs || !this.localPageInfo.portalTabs.length) {
			this.initializePortalTabs(this.localPageInfo);
		}

		const newInfo = this.localPageInfo;
		newInfo.portalTabs.forEach(element => {
			if (element.portal === this.portal) {
				element.openTabs = tabs;
			}
		});

		this.localPageInfo = newInfo;
	}



	addNewTab(type: PROVIDER_TYPE, id: any, display: string) {
		if (!type || !id || !display) {
			return;
		}

		const tabs = this.currentPageTabs.filter(x => !(x.id === id && x.type === type));


		const newTab = {
			guid: Utils.generateGuid(),
			display: display,
			type: type,
			id: id
		};

		tabs.push(newTab);

		this.updateTabs(tabs);
		this.buildTabRoutes();
	}

	cleanInvalidTabs() {
		const tabs = this.currentPageTabs;
		const validTabs = tabs.filter(x => x.display && x.type && x.id && x.guid && ProviderTypeArray.find(prov => prov == x.type));

		if (tabs.length === validTabs.length) {
			return;
		}

		// There are some invalid tabs
		this.updateTabs(validTabs);
	}

	removeTab(tabGuid: any) {
		if (!tabGuid) {
			return;
		}

		const tabs = this.currentPageTabs;
		const tabIndex = tabs.findIndex(x => x.guid === tabGuid);

		if (tabIndex === -1) {
			return;
		}

		const tabToDelete = tabs[tabIndex];

		tabs.splice(tabIndex, 1);
		this.updateTabs(tabs);
		this.tabRemoved.next(tabToDelete);

		this.buildTabRoutes();
	}

	async validateAndAddTab(providerType: PROVIDER_TYPE, id: string) {
		// Check that the npi is correct and get lookup info
		const lookupSub = this.lookupApi.getNpiInfo(id);
		if (!lookupSub) {
			return false;
		}

		const lookupInfo = await lookupSub
			.toPromise()
			.catch(x => {
				return false;
			});

		if (!lookupInfo) {
			return false;
		}

		// This is valid. Let's add this tab and data to cache and continue
		const npiLookupInfo: NpiInfo = lookupInfo as NpiInfo;

		const cachedData = this.analyzeDataCacheService.getCachedData(providerType, id);
		if (!cachedData) {
			this.analyzeDataCacheService.updateCachedData(providerType, id, npiLookupInfo);
		}


		this.addNewTab(providerType, id, npiLookupInfo.display || npiLookupInfo.displayOther || npiLookupInfo.npi);
		return true;
	}

	buildTabRoutes() {
		this.currentTabNavLinks = null;

		this.cleanInvalidTabs();
		const tabs = this.currentPageTabs;
		const tabNavSet = [];

		// Shouldn't see Hospice tabs on HHA and Vice Versa
		const invalidTypeForPortal = this.portal === PORTAL_TYPE.HHA ? PROVIDER_TYPE.hospice : PROVIDER_TYPE.homehealth;

		tabs.filter(x => x.type != invalidTypeForPortal).forEach(x => {
			const newTab = this.getAnalyzeTabNav(x);
			if (newTab) {
				tabNavSet.push(newTab);
			}
		})

		this.currentTabNavLinks = tabNavSet.reverse();
	}

	getAnalyzeTabNav(tab?: AnalyzePageTab): AnalyzeTabNavLink {

		const providerConfig = Utils.getProviderTypeConfig(tab.type);
		if (!providerConfig) {
			return null;
		}

		return {
			tabGuid: tab.guid,
			display: tab.display,
			title: this.appCache.providerTypeSingularLabel(providerConfig),
			providerType: providerConfig.enum,
			colorClass: providerConfig.colorClass,
			icon: null,
			route: this.navigationService.buildAnalyzeTabRoute(tab),
			showRemove: true,
			id: tab.id,
		};
	}

	removeCard(navLink: AnalyzeTabNavLink) {
		this.removeTab(navLink.tabGuid);
		this.buildTabRoutes();
	}

	toggleAnalyzeTabs() {
		this.npiListExpanded = !this.npiListExpanded;
	}

	hideNpiList(){
		this.npiListExpanded = false;
		this.showNpiList = false;
	}

	createPortalSpecificProviderTabs(allTabs: AnalyzePageTab[]): AnalyzePageStorageInfo {
		const newInfo = {
			userId: this.appCache.userId,
			portalTabs: [],
			openTabs: []
		};

		this.initializePortalTabs(newInfo);

		newInfo.portalTabs.forEach(p => {
			allTabs.filter(t => {
				const newTab = this.getAnalyzeTabNav(t);
				if (!Utils.isAgencyProviderType(t.type) && !p.openTabs.includes(p => p.npi == t.id)) {
					p.openTabs.push(t);
				} else if (Utils.getAgencyForPortal(p) == t.type) {
					p.openTabs.push(t);
				}
			});
		});

		return newInfo;
	}

}
