export class TrellaErrorResponse {
	FrontEndMessageId: number;
	StatusCode: number;
	Message: string;
	TimeStamp: number;
	DisplayTimeStamp: boolean;
}

export class TrellaError extends Error {
	message: string;
	frontEndMessageId: number;
	statusCode: number;
	timeStamp: number;
	displayTimeStamp: boolean;

	constructor(error: TrellaErrorResponse) {
		super(); // 'Error' breaks prototype chain here

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, TrellaError);
		}

		Object.setPrototypeOf(this, TrellaError.prototype);

		this.name = 'TrellaError';
		this.message = error.Message;
		this.frontEndMessageId = error.FrontEndMessageId;
		this.statusCode = error.StatusCode;
		this.timeStamp = error.TimeStamp;
		this.displayTimeStamp = error.DisplayTimeStamp;
	}
}
