import {Component, ViewChild, OnDestroy, OnInit} from '@angular/core';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NavigationService } from './shared/providers/navigation.service';
import { UserFeedbackService } from './shared/providers/user-feedback.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PORTAL_KEY } from './shared/shared.constants';
import { AuthenticationService } from './shared/providers/authentication.service';
import { MarketScapeResolverService } from './shared/services/market-scape.resolver';
import {Subscription, Observable, fromEvent} from 'rxjs';
import { interval } from 'rxjs';
import {CheckForUpdateService} from './shared/providers/check-for-update.service';
import {MobileUpdateModalComponent} from './components/mobile-update-modal/mobile-update-modal.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
	@ViewChild(MobileUpdateModalComponent, { static: true }) updatePopup: MobileUpdateModalComponent;

	cognitoTenMinuteRefresh = 600000;

	isOnline: boolean = true;

	onlineEvent: Observable<Event>;
	offlineEvent: Observable<Event>;
	subscriptions: Subscription[] = [];
	connectionStatusMessage: string;
	connectionStatus: string;

	@ViewChild(NotificationsComponent, { static: true }) notifications: NotificationsComponent;

	constructor(
		private marketScapeResolver: MarketScapeResolverService,
		private navigationService: NavigationService,
		private authenticationService: AuthenticationService,
		private userFeedbackService: UserFeedbackService,
		private checkForUpdateService: CheckForUpdateService) {

		this.checkForUpdateService.checkForUpdates();
		this.checkForUpdateService.triggerUpdateMessage$.subscribe((event) => {
			document.location.reload();
			// this.updatePopup.open();
		});
	}
	@ViewChild(SpinnerComponent, { static: true }) spinner: SpinnerComponent;

	ngOnInit() {
		// this will track route changes and populate proper market segment
		this.onlineEvent = fromEvent(window, 'online');
		this.offlineEvent = fromEvent(window, 'offline');

		this.subscriptions.push(this.onlineEvent.subscribe(event => {
			this.connectionStatusMessage = 'Connected to internet! You are online';
			this.connectionStatus = 'online';
			this.isOnline = true;
		}));

		this.subscriptions.push(this.offlineEvent.subscribe(e => {
			this.connectionStatusMessage = 'Connection lost! You are offline';
			this.connectionStatus = 'offline';
			this.isOnline = false;
		}));

		this.marketScapeResolver
			.trackMarketScape()
			.subscribe();

		this.navigationService.initUrlStack();
		this.userFeedbackService.setGlobalNotificationComponent(this.notifications);
		this.userFeedbackService.setSpinnerComponent(this.spinner);
	
		AuthenticationService.getCognitoToken().then(token => {
			if(token){
				const portal = localStorage.getItem(PORTAL_KEY);
				if(!portal){
					return this.navigationService.navigateToChooseApp();
				}
			}

		})
		const counter = interval(this.cognitoTenMinuteRefresh);
		const subscription = counter.subscribe(() => {
			this.authenticationService.checkTokenExpiryAndRefresh();
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}
}
