import { Injectable } from '@angular/core';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { SpinnerComponent } from '../../components/spinner/spinner.component';

@Injectable({
	providedIn: 'root'
})
export class UserFeedbackService {
	public globalNotification: NotificationsComponent;
	public spinner: SpinnerComponent;

	setGlobalNotificationComponent(component: NotificationsComponent) {
		this.globalNotification = component;
	}

	setSpinnerComponent(component: SpinnerComponent) {
		this.spinner = component;
		this.spinner.isGlobal = true;
	}

	startSpinner() {
		this.spinner.showSpinner = true;
	}

	stopSpinner() {
		this.spinner.showSpinner = false;
	}

	showCustomSaveSuccess(object: string) {
		this.globalNotification.showCustomSuccessMessage(object);
	}

	showCustomWarning(object: string) {
		this.globalNotification.showCustomWarningMessage(object);
	}

	showSaveSucess(object: string) {
		this.globalNotification.showSaveSucess(object);
	}

	showCreateSucess(object: string) {
		this.globalNotification.showCreateSucess(object);
	}

	showDeleteSucess(object: string) {
		this.globalNotification.showDeleteSucess(object);
	}

	showClearSuccess(object: string) {
		this.globalNotification.showClearSuccess(object);
	}

	showSaveError(object: string) {
		this.globalNotification.showSaveError(object);
	}

	showUnexpectedError() {
		this.globalNotification.showUnexpectedError();
	}

	showSuccess(message) {
		this.globalNotification.showSuccess(message);
	}

	showWarning(message) {
		this.globalNotification.showWarning(message);
	}

	showError(message) {
		this.globalNotification.showError(message);
	}

	showCustomError(error) {
		this.globalNotification.showCustomError(error);
	}

	resetNotifications() {
		this.globalNotification.reset();
	}
}
