import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ApplicationCacheService } from './application-cache.service';
import { PORTAL_TYPE } from '../shared.constants';
import { AuthenticationService } from './authentication.service';
import { NavigationService } from './navigation.service';

@Injectable({
	providedIn: 'root'
})
export class SnfGuard implements CanActivate {
	constructor(private authService: AuthenticationService, private appCache: ApplicationCacheService,
				private navigationService: NavigationService) { }

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// get cached user
		const user = await this.authService.getCachedUser();
		const userPortals = user && user.portals;

		if (!userPortals.includes(PORTAL_TYPE.SNF)) {
			return await this.navigationService.navigateToUnauthorized();
		}

		this.appCache.setPortalType(PORTAL_TYPE.SNF);

		return true;
	}
}
