import {Component, EventEmitter, Output} from '@angular/core';
import Sticky from 'src/app/shared/Sticky';
import { AnalyzeTabService } from 'src/app/main/analyze/analyze-tab.service';

@Component({
	selector: 'app-alert-dialog',
	templateUrl: './alert-dialog.component.html',
	styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {
	@Output() okPressed = new EventEmitter();
	@Output() openDialog = new EventEmitter();

	title: string;
	message: string;
	okButtonLabel: string;
	opened = false;
	forceOnTop = false;
	hideOkButton = false;
	loadingAnimation = false;

	// ToDo: Cross-referencing tabs and cart within dialog will work for now but we
	// will need to abstract all the top-layer components (Sticky, Float buttons) to their own accessible layer.
	constructor(private analyzeTabService: AnalyzeTabService) {

	}

	open(title: string, message: string, okButtonLabel?: string, hideOkButton?: boolean, forceOnTop?: boolean, loadingAnimation?: boolean) {
		this.title = title;
		this.message = message;
		this.okButtonLabel = okButtonLabel || 'OK';
		this.opened = true;
		this.openDialog.emit();
		this.analyzeTabService.hideNpiList();
		this.forceOnTop = forceOnTop;
		this.hideOkButton = hideOkButton;
		this.loadingAnimation = loadingAnimation;
	}

	close() {
		this.opened = false;
		this.analyzeTabService.showNpiList = true;
	}

	ok() {
		if (this.okPressed) {
			this.okPressed.emit();
		}

		this.close();
	}
}
