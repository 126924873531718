import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

export class EnvService {

	// The values that are defined here are the default values that can
	// be overridden by env.js

	// API url
	public apiUrl = '';

	// Admin url
	public adminUrl = '';

	public pendoPrefix = 'L';

	public cognito_only = false;

	public cognitoLoginUrl = '';

	public cognitoUrl_logout = '';

	public awsconfig = '';

	public domain = '';

	public redirectSignIn = '';

	public redirectSignOut = '';

	public defaultClientId = '';

	public userPoolId = '';

	public cognitoUrl(id: string) {
		const clientId = id || this.defaultClientId;
		return `https://${this.domain}/login?client_id=${clientId}&response_type=code&scope=email+openid+profile&redirect_uri=${this.redirectSignIn}`;
	}

	// Whether or not to enable debug mode
	public enableDebug = true;
	public isLocal = true;
	public isProduction = true;

	constructor() {
	}

}
