import { ModuleWithProviders, NgModule } from '@angular/core';
import { ExcelComponentsModule } from '../components/components.module';
import { MarketScapeResolverService } from './services/market-scape.resolver';

@NgModule({
  imports: [
    ExcelComponentsModule,
  ],
  declarations: [
      ],
  providers: [
    MarketScapeResolverService
  ],
  exports: [
    ExcelComponentsModule,
  ]
})
export class SharedModule {
  static forRoot(config: SharedModule): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}