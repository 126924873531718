import { Component } from '@angular/core';
import { NavigationService } from '../shared/providers/navigation.service';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['../../styles/components/cover-page.scss', './logout.component.scss']
})
export class LogoutComponent {

	constructor(private navigationService: NavigationService) { }

	toLogin() {
		this.navigationService.navigateToLogin();
	}
}
