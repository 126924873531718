import {CHARTS, COMMON, GRIDS} from '../main/analyze/analyze-facilities/component.constants';

export const USER_MESSAGES = {
	default: 'Sorry, an unexpected error occurred while attempting to contact the server. Please try again.',
	unauthorized_provider: 'Your current permissions do not allow access to this area of Marketscape',
	processing_login: 'Processing...',
	processing_requesting: 'Requesting access to Trella...',
	login_failed: 'Login attempt failed. We apologize for the inconvenience - please contact the Trella Health Help Desk.',
	authentication_failed: 'Authentication failed. We apologize for the inconvenience - please contact the Trella Health Help Desk.',
	user_not_found: 'Oops! It looks like you don\'t have access to Marketscape. Contact your Trella Health administrator.',
	email_send_error: 'An error occurred - the email could not be sent. Please contact Trella Health Help Desk.',
	recents_error: 'Sorry, you do not have any recents to visit yet.'
};

export const PERMISSIONS = {
	ADMIN_ACCESS: 'trella.super.admin',
	NPI_GROUPS_CREATE: 'npigroups.create',
	NPI_GROUPS_COMPARISON_CREATE: 'npigroups.comparison.create',
	NPI_GROUPS_COMPARISON_ADD: 'npigroups.comparison.npi.add',
	NPI_GROUPS_COMPARISON_DELETE: 'npigroups.comparison.npi.delete',
	NPI_GROUPS_DELETE: 'npigroups.delete',
	NPI_GROUPS_LIST: 'npigroups.list',
	FAVORITES_LIST: 'favorites.list',
	FAVORITES_DELETE: 'favorites.delete',
	FAVORITES_CREATE: 'favorites.create',
	TARGETS_CREATE: 'targets.create',
	TARGETS_DELETE: 'targets.delete',
	TARGETS_EDIT: 'targets.update',
	TARGETS_LIST: 'targets.list',
	CHART_CART_CREATE: 'chartcart.create',
	CHART_CART_LIST: 'chartcart.list',
	CHART_CART_DELETE: 'chartcart.delete',
	PAGE_DASHBOARD_SHOW: 'page.dashboard.show',
	PAGE_EXPLORE_SHOW: 'page.explore.show',
	PAGE_ANALYZE_SHOW: 'page.analyze.show',
	PAGE_SHARE_SHOW: 'page.share.show',
	SHARE_PUBLIC_OPTION: 'datakit.create.public',
	SHARE_CUSTOM_OPTION: 'datakit.create.share',
	DATAKIT_CREATE: 'datakit.create',
	DATAKIT_GET: 'datakit.get',
	DATAKIT_CREATE_PMV: 'datakit.create.pmv',
	DATAKIT_UPDATE: 'datakit.update',
	DATAKIT_LIST: 'datakit.list',
	NPI_ASSIGNEE_LIST: 'npiassignee.list',
	NPI_ASSIGNEE_ADD: 'npiassignee.add',
	NPI_ASSIGNEE_DELETE: 'npiassignee.delete',
	PAGE_REPORTS_SHOW: 'page.reports.show',
	TOTAL_COST_CARE: 'page.reports.totalcostcare',
	MEDICARE_REIMBURSEMENT: 'page.reports.medicarereimbursement',
	PROVIDER_EDIT: 'provider.edit',
	PROVIDER_EDIT_NOTES: 'provider.edit.notes',
	FILTER_SHARE: 'filters.share',
	FILTER_SHARE_PUBLIC: 'filters.share.public',
	ALLOW_E_BUTTON: 'config.edit.allow',
	MARKET_SHARE: 'page.reports.marketshare',
	CONFIG_SAVE: 'config.save.allow',
	PHYSICIAN_PROVIDER: 'marketscape.physician.allow',
	FACILITY_PROVIDER: 'marketscape.facility.allow',
	HOME_HEALTH_PROVIDER: 'marketscape.homehealth.allow',
	SKILLED_NURSING_PROVIDER: 'marketscape.skillednursing.allow',
	HOSPICE_PROVIDER: 'marketscape.hospice.allow',
	UNLIMITED_EXPORT: 'unlimited.export.allow',
	SALES_SPOTLIGHT: 'salesspotlight.allow'
};

export interface RouteAttr {
	route: string;
	label: string;
	icon?: string;
	permission: string;
	svg?: string;
	svgActive?: string;
	backgroundColor?: string;
}

export enum ANALYTIC_ACTION {
	analyze = 'analyze',
	metrics = 'metrics',
	lists = 'lists',
	latest = 'latest',
	search = 'search'
}

export enum RAW_VALUE {
	negativeOne = '-1',
	negativeTwo = '-2',
	negativeThree = '-3',
	negativeFour = '-4',
	negativeFive = '-5', // this value is for the footer rows of tables
	zero = '0'
}

export enum RAW_VALUE_NUMBER {
	negativeOne = -1,
	negativeTwo = -2,
	negativeThree = -3,
	negativeFour = -4,
	negativeFive = -5, // this value is for the footer rows of tables
	zero = 0
}

export enum DISPLAY_VALUE {
	lessThanEleven = '<11',
	dash = '-',
	insufficient = 'ins',
}

export enum CHART_DATA_TYPE {
	percent = 'percent',
	count = 'count',
	currency = 'currency'
}

export const SPECIAL_DATA_VALUES = [DISPLAY_VALUE.lessThanEleven, DISPLAY_VALUE.dash, DISPLAY_VALUE.insufficient];

export enum PORTAL_TYPE {
	HOS = 'hos',
	HHA = 'hha',
	ADMIN = 'admin',
	SNF = 'snf',
	NONE = '',
	HME = 'hme',
	DEFAULT = 'default'
}

export const PORTALNAME  = [
	{key: PORTAL_TYPE.HHA, value: 'Home Health'},
	{key: PORTAL_TYPE.HOS, value: 'Hospice'},
	{key: PORTAL_TYPE.SNF, value: 'Skilled Nursing'},
];

export enum ANALYZE_PAGE_VIEW_TYPE {
	GRID = 'grid',
	CHART = 'chart'
}

export enum PAGE_TYPE {
	settings = 'settings',
	lists = 'lists',
	analyze = 'analyze'
}


export enum PROVIDER_TYPE {
	physician = 'physician',
	facility = 'facility',
	hospice = 'hospice',
	homehealth = 'homehealth',
	skillednursing = 'skillednursing',
	noProvider = 'noProvider',

	organization = 'organization',
}

export const SNF_Facility_Plural_Label = 'Hospitals & Agencies';
export const SNF_Facility_Singular_Label = 'Hospital & Agency';

export const CUSTOM_FILTER_DISPLAY_NAMES = [
	{
		providerType: PROVIDER_TYPE.physician,
		portal: PORTAL_TYPE.SNF,
		filterType: 'Affiliations',
		title: 'Hospital Affiliation'
	}
];

export enum REPORT_TYPES {
	marketShare = 'marketshare',
	totalCostCare = 'totalCostCare',
	medicareReimbursement = 'medicareReimbursement'
}

export enum LIST_TYPES {
	favorites = 'favorites',
	targets = 'targets',
	assignments = 'assignments',
	recent = 'recent'
}

export enum MARKET_SHARE_REPORTS {
	state = 'state',
	county = 'county'
}

export const ProviderTypePermissionMap = new Map<PROVIDER_TYPE, string>([
	[PROVIDER_TYPE.physician, PERMISSIONS.PHYSICIAN_PROVIDER],
	[PROVIDER_TYPE.facility, PERMISSIONS.FACILITY_PROVIDER],
	[PROVIDER_TYPE.homehealth, PERMISSIONS.HOME_HEALTH_PROVIDER],
	[PROVIDER_TYPE.hospice, PERMISSIONS.HOSPICE_PROVIDER],
	[PROVIDER_TYPE.skillednursing, PERMISSIONS.SKILLED_NURSING_PROVIDER]
]);

export const ProviderTypeArray = [PROVIDER_TYPE.physician, PROVIDER_TYPE.facility, PROVIDER_TYPE.hospice, PROVIDER_TYPE.homehealth, PROVIDER_TYPE.skillednursing];

export enum TARGET_TYPE {
	UA = 'UA',
	CA = 'CA',
	UU = 'UU',
	US = 'US'
}

export const TARGET_TYPE_OPTIONS = [
	{
		display: `Underutilizing (${TARGET_TYPE.UU})`,
		value: TARGET_TYPE.UU
	},
	{
		display: `Unaffiliated (${TARGET_TYPE.UA})`,
		value: TARGET_TYPE.UA
	},
	{
		display: `Competitor Affiliated (${TARGET_TYPE.CA})`,
		value: TARGET_TYPE.CA
	},
	{
		display: `User Selected (${TARGET_TYPE.US})`,
		value: TARGET_TYPE.US
	}
];

export const TARGET_TYPES = Object.values(TARGET_TYPE);

export enum CLASSIFICATION_TYPE {
	NONE = 'None',
	LOW = 'Low',
	MEDIUM = 'Medium',
	HIGH = 'High'
}

export const CLASSIFICATION_TYPES = Object.values(CLASSIFICATION_TYPE);

export enum RISK_CATEGORY {
	VeryLow = 'Very Low',
	Low = 'Low',
	MediumLow = 'Medium - Low',
	Medium = 'Medium',
	MediumHigh = 'Medium - High',
	High = 'High',
	VeryHigh = 'Very High'
}

export const RISK_CATEGORIES = Object.values(RISK_CATEGORY);

export interface TargetTypeConfig {
	enum: TARGET_TYPE;
	label: string;
}

type TargetTypeConfigKey = TARGET_TYPE.UA | TARGET_TYPE.CA | TARGET_TYPE.UU | TARGET_TYPE.US;
export const TargetTypeConfigs: { [key in TargetTypeConfigKey]: TargetTypeConfig } = {
	UA: {
		enum: TARGET_TYPE.UA,
		label: 'Unaffiliated',
	},
	CA: {
		enum: TARGET_TYPE.CA,
		label: 'Competitor Affiliated',
	},
	UU: {
		enum: TARGET_TYPE.UU,
		label: 'Underutilizing',
	},
	US: {
		enum: TARGET_TYPE.US,
		label: 'User Selected',
	}
};

export const TargetTypeConfigArray = [TargetTypeConfigs.UA, TargetTypeConfigs.CA, TargetTypeConfigs.UU, TargetTypeConfigs.US];

export interface ProviderTypeConfig {
	enum?: PROVIDER_TYPE;
	routeString?: string;
	label: string;
	labelSingular?: string;
	icon?: string;
	colorClass?: string;
	bgClass?: string;
	permission?: string;
}

type ProviderTypeConfigKey =
	PROVIDER_TYPE.physician
	| PROVIDER_TYPE.facility
	| PROVIDER_TYPE.homehealth
	| PROVIDER_TYPE.hospice
	| PROVIDER_TYPE.skillednursing
	| PROVIDER_TYPE.noProvider;
export const ProviderTypeConfigs: { [key in ProviderTypeConfigKey]: ProviderTypeConfig } = {
	physician: {
		enum: PROVIDER_TYPE.physician,
		routeString: PROVIDER_TYPE.physician,
		label: 'Physicians',
		labelSingular: 'Physician',
		icon: 'fas fa-user-md',
		colorClass: 'text-physician',
		bgClass: 'bg-physician',
		permission: PERMISSIONS.PHYSICIAN_PROVIDER
	},
	facility: {
		enum: PROVIDER_TYPE.facility,
		routeString: PROVIDER_TYPE.facility,
		label: 'Facilities',
		labelSingular: 'Facility',
		icon: 'fas fa-hospital',
		colorClass: 'text-facility',
		bgClass: 'bg-facility',
		permission: PERMISSIONS.FACILITY_PROVIDER
	},
	homehealth: {
		enum: PROVIDER_TYPE.homehealth,
		routeString: PROVIDER_TYPE.homehealth,
		label: 'Home Health Agencies',
		labelSingular: 'Home Health Agency',
		icon: 'fas fa-home-heart',
		colorClass: 'text-agency',
		bgClass: 'bg-agency',
		permission: PERMISSIONS.HOME_HEALTH_PROVIDER
	},
	hospice: {
		enum: PROVIDER_TYPE.hospice,
		routeString: PROVIDER_TYPE.hospice,
		label: 'Hospices',
		labelSingular: 'Hospice',
		icon: 'fas fa-hands-heart',
		colorClass: 'text-agency',
		bgClass: 'bg-agency',
		permission: PERMISSIONS.HOSPICE_PROVIDER
	},
	skillednursing: {
		enum: PROVIDER_TYPE.skillednursing,
		routeString: PROVIDER_TYPE.skillednursing,
		label: 'Skilled Nursing',
		labelSingular: 'Skilled Nursing',
		icon: 'fas fa-user-nurse',
		colorClass: 'text-agency',
		bgClass: 'bg-agency',
		permission: PERMISSIONS.SKILLED_NURSING_PROVIDER
	},
	noProvider: {
		enum: PROVIDER_TYPE.noProvider,
		label: 'None'
	},
};

export const ProviderTypeConfigArray = [ProviderTypeConfigs.physician, ProviderTypeConfigs.facility, ProviderTypeConfigs.homehealth,
	ProviderTypeConfigs.hospice, ProviderTypeConfigs.skillednursing];

export const ROUTE_CONSTANTS = {
	sso: {
		tokenGetLabel: 'sso',
		portalGetLabel: 'portal',
		redirectRouteLabel: 'redirect',
	},
	returnUrlLabel: 'returnUrl',
	passwordResetUrlLabel: 'token',
	newRouteParam: 'new',
	idRouteParam: 'id',
	settingsRouteParam: 'settings',
	redirect: {
		route: 'redirect',
		label: 'Redirect',
		icon: ''
	},
	hos: {
		route: PORTAL_TYPE.HOS,
		label: 'Hospice'
	},
	hha: {
		route: PORTAL_TYPE.HHA,
		label: 'Home Health'
	},
	snf: {
		route: PORTAL_TYPE.SNF,
		label: 'Skilled Nursing'
	},
	login: {
		route: 'login',
		label: 'Login',
		icon: ''
	},
	landing: {
		route: 'landing',
		label: 'Landing',
		icon: ''
	},
	loginlegacy: {
		route: 'loginlegacy',
		label: 'Loginlegacy',
		icon: ''
	},
	passwordReset: {
		route: 'reset',
		label: 'Reset',
		icon: ''
	},
	chooseApplication: {
		route: 'chooseApplication',
		label: 'chooseApplication',
		icon: ''
	},
	logout: {
		route: 'logout',
		label: 'Logout',
		icon: ''
	},
	physician: {
		route: ProviderTypeConfigs.physician.routeString,
		label: ProviderTypeConfigs.physician.label,
		icon: ProviderTypeConfigs.physician.icon
	},
	facility: {
		route: ProviderTypeConfigs.facility.routeString,
		label: ProviderTypeConfigs.facility.label,
		icon: ProviderTypeConfigs.facility.icon
	},
	hospice: {
		route: ProviderTypeConfigs.hospice.routeString,
		label: ProviderTypeConfigs.hospice.label,
		icon: ProviderTypeConfigs.hospice.icon
	},
	homehealth: {
		route: ProviderTypeConfigs.homehealth.routeString,
		label: ProviderTypeConfigs.homehealth.label,
		icon: ProviderTypeConfigs.homehealth.icon,
	},
	skillednursing: {
		route: ProviderTypeConfigs.skillednursing.routeString,
		label: ProviderTypeConfigs.skillednursing.label,
		icon: ProviderTypeConfigs.skillednursing.icon,
	},
	settings: {
		route: 'settings',
		label: 'Settings',
	},
	analyze: {
		route: ANALYTIC_ACTION.analyze,
		label: 'Analyze',
		icon: 'fas fa-chart-bar',
		permission: PERMISSIONS.PAGE_ANALYZE_SHOW
	},
	lists: {
		route: ANALYTIC_ACTION.lists,
		label: 'My Lists',
		icon: 'fas fa-list',
		permission: PERMISSIONS.PAGE_ANALYZE_SHOW
	},
	search: {
		route: ANALYTIC_ACTION.search,
		label: 'Search',
		icon: 'fas fa-search',
		permission: PERMISSIONS.PAGE_DASHBOARD_SHOW
	},
	latest: {
		route: ANALYTIC_ACTION.latest,
		label: 'Top Metrics',
		icon: 'fas fa-chart-bar',
		permission: PERMISSIONS.PAGE_ANALYZE_SHOW
	},
	print: {
		route: 'print',
	},
	favorites: {
		route: LIST_TYPES.favorites,
		label: 'Favorites',
		permission: PERMISSIONS.FAVORITES_LIST
	},
	targets: {
		route: LIST_TYPES.targets,
		label: 'Targets',
		permission: PERMISSIONS.TARGETS_LIST
	},
	assignments: {
		route: LIST_TYPES.assignments,
		label: 'Assignments',
		permission: PERMISSIONS.NPI_ASSIGNEE_LIST
	},
	recent: {
		route: LIST_TYPES.recent,
		label: 'Recent',
		permission: PERMISSIONS.PAGE_DASHBOARD_SHOW
	},
	metrics: {
		route: ANALYTIC_ACTION.metrics,
		label: 'Metrics',
		icon: '',
		permission: PERMISSIONS.PAGE_ANALYZE_SHOW
	},
	metricsfacility: {
		route: PROVIDER_TYPE.facility,
		label: 'Facility',
		permission: PERMISSIONS.PAGE_ANALYZE_SHOW
	},
	metricsphysician: {
		route: PROVIDER_TYPE.physician,
		label: 'Physician',
		permission: PERMISSIONS.PAGE_ANALYZE_SHOW
	},
	metricshomehealth: {
		route: PROVIDER_TYPE.homehealth,
		label: 'Home Health',
		permission: PERMISSIONS.PAGE_ANALYZE_SHOW
	},
	metricshospice: {
		route: PROVIDER_TYPE.hospice,
		label: 'Hospice',
		permission: PERMISSIONS.PAGE_ANALYZE_SHOW
	},
	metricsskillednursing: {
		route: PROVIDER_TYPE.skillednursing,
		label: 'Skilled Nursing',
		permission: PERMISSIONS.PAGE_ANALYZE_SHOW
	},
	unauthorized: {
		route: 'unauthorized'
	},
	eula: {
		route: 'eula',
		label: 'eula',
		icon: ''
	},
	dataEula: {
		route: 'dataEula',
		label: 'dataEula',
		icon: ''
	},
	talking_points_sales_rep: {
		route: 'sales-rep'
	},
	talking_points_marketing: {
		route: 'marketing'
	},
	error: {
		route: 'error',
		label: 'Error'
	},
};

export const LOCAL_STORAGE_KEY = {
	JWT: 'excel_health_access_token',
	ANALYZE_PAGE: 'excel_health_analyze',
	ANALYZE_PAGE_VIEW: 'excel_analyze_page_view_chart_view',
	MARKET_SHARE_STATE: 'excel_market_share_selected_state',
	MARKET_SHARE_COUNTY: 'excel_market_share_selected_county',
	MARKET_SHARE_TYPE: 'excel_market_share_type',
	MEDICARE_REIMBURSEMENT_TYPE: 'medicare_reimbursement_type',
	MEDICARE_REIMBURSEMENT_DATE_TYPE: 'medicare_reimbursement_date_type',
	SSO_SOURCE: 'excel_sso_source',
	SSO_AUTH: 'excel_sso_auth',
	SSO_CLIENTID: 'excel_sso_clientid',
	AGENCY_NPI: 'selectedAgencyNpi',
	SPOTLIGHT_TOGGLE : 'spotlightToggle'
};

export interface AnalyzePageStorageInfo {
	userId: any;
	portalTabs: AnalyzePortalPageTab[];
	openTabs: AnalyzePageTab[];
}

export interface AnalyzePortalPageTab {
	portal: PORTAL_TYPE;
	openTabs: AnalyzePageTab[];
}

export interface AnalyzePageTab {
	guid: any;
	type: PROVIDER_TYPE;
	display: string;
	id: any;
}

export interface JWT_DECODE {
	userNo: number;
	uID: string;
	username: string;
	customerID: number;
	isManager: string;
	email: string;
}

export enum CRUD_ACTION {
	create = 'create',
	read = 'read',
	update = 'update',
	delete = 'delete'
}

export enum CHART_TYPE {
	bar = 'bar',
	pie = 'pie',
	venn = 'venn',
	line = 'line',
	donut = 'donut',
	column = 'column',
	bubble = 'bubble',
	columnWithAverages = 'columnWithAverages',
	insight = 'insight',
	insightStarRating = 'insightStarRating',
	labelDataExtra = 'labelDataExtra',
	topInsight = 'topInsight',
	sunburst = 'sunburst',
	benchMarkInsight = 'benchMarkInsight',
	topPlacesInsight = 'topPlacesInsight'
}

export enum INSIGHT_CHART_TYPE {
	insight = 'insight',
	insightStarRating = 'insightStarRating',
	labelDataExtra = 'labelDataExtra',
	topInsight = 'topInsight',
	benchMarkInsight = 'benchMarkInsight',
	topPlacesInsight = 'topPlacesInsight',
	patientVolumeInsight = 'patientVolumeInsight'
}

export enum PAGE_SECTION {
	insights = 'Insights',
	patientPopulation = 'Patient Population',
	qualityMetrics = 'Quality Metrics',
	quality = 'Quality',
	operational = 'Operational',
	sources = 'Sources',
	practiceDetails = 'Practice Details',
	hhaUtilization = 'Utilization',
	destinations = 'Destinations',
	utilization = 'Utilization',
	PDGMSection = 'PDGM',
	utilizationQuality = 'Utilization & Quality',
	qualityOperational = 'Quality & Operational',
	medicareAdvantage = 'Medicare Advantage',
	topMetricsForPhysician = 'Top Metrics for this Physician',
	ourAgencyRelationship = 'Our Agency Relationship:'
}

export const INSIGHT_PAGE_TITLE = [
	{
		portal: PORTAL_TYPE.HHA,
		provider: PROVIDER_TYPE.physician,
		title: 'Sales Spotlight'
	},
	{
		portal: PORTAL_TYPE.HOS,
		provider: PROVIDER_TYPE.physician,
		title: 'Sales Spotlight'
	},
	{
		portal: PORTAL_TYPE.SNF,
		provider: PROVIDER_TYPE.physician,
		title: 'Sales Spotlight'
	},
];

export enum NPI_GROUP_TYPE {
	myAgency = 1,
	competitor = 2,
	comparison = 3
}

export enum NPI_TYPE {
	agency = 1,
	physician = 2,
	facility = 3
}

export interface NpiGroupTypeConfig {
	enum: NPI_GROUP_TYPE;
	label: string;
}

type NpiGroupTypeConfigKey = 'myAgency' | 'competitor' | 'comparison';
export const NpiGroupTypeConfigs: { [key in NpiGroupTypeConfigKey]: NpiGroupTypeConfig } = {
	myAgency: {
		enum: NPI_GROUP_TYPE.myAgency,
		label: 'My Agency'
	},
	competitor: {
		enum: NPI_GROUP_TYPE.competitor,
		label: 'Competitor'
	},
	comparison: {
		enum: NPI_GROUP_TYPE.comparison,
		label: 'Comparison'
	}
};

export const AGENCY_PROVIDER_TYPES = [PROVIDER_TYPE.homehealth, PROVIDER_TYPE.hospice, PROVIDER_TYPE.skillednursing];

export enum SHARE_LEVELS {
	PRIVATE = 1,
	COMPANY = 2,
	CUSTOM = 3
}

export const FORMAT_FUNCTION_TARGET_TYPE = 'targetTypeTransform';
export const FORMAT_FUNCTIONS = [FORMAT_FUNCTION_TARGET_TYPE];

export enum ShareType {
	datakit = 'datakit',
	filter = 'filter'
}

export const SHARE_LEVEL_OPTIONS = {
	'datakit': [
		{value: SHARE_LEVELS.PRIVATE, label: 'Private'},
		{value: SHARE_LEVELS.COMPANY, label: 'My Organization', permission: PERMISSIONS.SHARE_PUBLIC_OPTION},
		{value: SHARE_LEVELS.CUSTOM, label: 'Custom List', permission: PERMISSIONS.SHARE_CUSTOM_OPTION}
	],
	'filter': [
		{value: SHARE_LEVELS.PRIVATE, label: 'Private'},
		{value: SHARE_LEVELS.COMPANY, label: 'My Organization', permission: PERMISSIONS.FILTER_SHARE_PUBLIC},
		{value: SHARE_LEVELS.CUSTOM, label: 'Custom List', permission: PERMISSIONS.FILTER_SHARE}
	]
};

export enum TemplateType {
	None = 'none',
	PMV = 'pmv',
	Comparison = 'comparison'
}

export enum FacilityTypeDisplay {
	HOS = 'Hospice',
	INP = 'Inpatient',
	OUTP = 'Outpatient',
	SNF = 'Skilled Nursing',
	HHA = 'Home Health'
}

export enum FacilityType {
	HOS = 'HOS',
	INP = 'INP',
	OUTP = 'OUTP',
	SNF = 'SNF',
	HHA = 'HHA'
}

export enum FacilitySpecialty {
	Hospice = 'Hospice',
	SNF = 'SNF',
	Hospital = 'Hospital',
	Default = 'Default',
	HomeHealth = 'Home Health Agency',
}

export enum TrellaType {
	Unknown = 'UNKNOWN',
	HHA = 'HHA',
	HOS = 'HOS',
	HOSP = 'HOSP',
	OTHF = 'OTHF',
	OTHP = 'OTHP',
	PHYS = 'PHYS-APP',
	SNF = 'SNF'
}

export const DEFAULT_CHART_HEIGHT = 450;

export const FAVORITE_ICON = 'i.favorite-icon-default';
export const TARGET_ICON = 'i.target-icon-default';

export enum AVERAGE {
	Above = 'Above',
	Below = 'Below',
	None = 'None',
	Equal = 'Equal',
}

export enum GreenOverRed {
	false,
	true
}

export interface AlternateColor {
	TextAbove: string;
	TextBelow: string;
	TextEqual: string;
	NoColor: string;
	BackGroundAbove: string;
	BackGroundBelow: string;
	BackGroundNeutral: string;
}

export const AlternateColor: { [key in GreenOverRed]: AlternateColor } = {
	0: {
		TextAbove: 'text-danger',
		TextBelow: 'text-success',
		TextEqual: 'average-transparent',
		NoColor: '',
		BackGroundAbove: 'bg-danger',
		BackGroundBelow: 'bg-success',
		BackGroundNeutral: 'bg-primary'
	},
	1: {
		TextAbove: 'text-success',
		TextBelow: 'text-danger',
		TextEqual: 'average-transparent',
		NoColor: '',
		BackGroundAbove: 'bg-success',
		BackGroundBelow: 'bg-danger',
		BackGroundNeutral: 'bg-primary'
	}
};

export enum ComparisonColors {
	TextAbove = 'text-danger',
	TextBelow = 'text-success',
	TextEqual = 'average-transparent',
	NoColor = '',
	BackGroundAbove = 'bg-danger',
	BackGroundBelow = 'bg-success',
	BackGroundNeutral = 'bg-primary',
}


export const PORTAL_KEY = 'portal';

export const STATES_AND_ABBREVIATIONS = [
	{
		'name': 'Alabama',
		'abbreviation': 'AL'
	},
	{
		'name': 'Alaska',
		'abbreviation': 'AK'
	},
	{
		'name': 'Arizona',
		'abbreviation': 'AZ'
	},
	{
		'name': 'Arkansas',
		'abbreviation': 'AR'
	},
	{
		'name': 'California',
		'abbreviation': 'CA'
	},
	{
		'name': 'Colorado',
		'abbreviation': 'CO'
	},
	{
		'name': 'Connecticut',
		'abbreviation': 'CT'
	},
	{
		'name': 'Delaware',
		'abbreviation': 'DE'
	},
	{
		'name': 'District Of Columbia',
		'abbreviation': 'DC'
	},
	{
		'name': 'Florida',
		'abbreviation': 'FL'
	},
	{
		'name': 'Georgia',
		'abbreviation': 'GA'
	},
	{
		'name': 'Hawaii',
		'abbreviation': 'HI'
	},
	{
		'name': 'Idaho',
		'abbreviation': 'ID'
	},
	{
		'name': 'Illinois',
		'abbreviation': 'IL'
	},
	{
		'name': 'Indiana',
		'abbreviation': 'IN'
	},
	{
		'name': 'Iowa',
		'abbreviation': 'IA'
	},
	{
		'name': 'Kansas',
		'abbreviation': 'KS'
	},
	{
		'name': 'Kentucky',
		'abbreviation': 'KY'
	},
	{
		'name': 'Louisiana',
		'abbreviation': 'LA'
	},
	{
		'name': 'Maine',
		'abbreviation': 'ME'
	},
	{
		'name': 'Maryland',
		'abbreviation': 'MD'
	},
	{
		'name': 'Massachusetts',
		'abbreviation': 'MA'
	},
	{
		'name': 'Michigan',
		'abbreviation': 'MI'
	},
	{
		'name': 'Minnesota',
		'abbreviation': 'MN'
	},
	{
		'name': 'Mississippi',
		'abbreviation': 'MS'
	},
	{
		'name': 'Missouri',
		'abbreviation': 'MO'
	},
	{
		'name': 'Montana',
		'abbreviation': 'MT'
	},
	{
		'name': 'Nebraska',
		'abbreviation': 'NE'
	},
	{
		'name': 'Nevada',
		'abbreviation': 'NV'
	},
	{
		'name': 'New Hampshire',
		'abbreviation': 'NH'
	},
	{
		'name': 'New Jersey',
		'abbreviation': 'NJ'
	},
	{
		'name': 'New Mexico',
		'abbreviation': 'NM'
	},
	{
		'name': 'New York',
		'abbreviation': 'NY'
	},
	{
		'name': 'North Carolina',
		'abbreviation': 'NC'
	},
	{
		'name': 'North Dakota',
		'abbreviation': 'ND'
	},
	{
		'name': 'Ohio',
		'abbreviation': 'OH'
	},
	{
		'name': 'Oklahoma',
		'abbreviation': 'OK'
	},
	{
		'name': 'Oregon',
		'abbreviation': 'OR'
	},
	{
		'name': 'Pennsylvania',
		'abbreviation': 'PA'
	},
	{
		'name': 'Puerto Rico',
		'abbreviation': 'PR'
	},
	{
		'name': 'Rhode Island',
		'abbreviation': 'RI'
	},
	{
		'name': 'South Carolina',
		'abbreviation': 'SC'
	},
	{
		'name': 'South Dakota',
		'abbreviation': 'SD'
	},
	{
		'name': 'Tennessee',
		'abbreviation': 'TN'
	},
	{
		'name': 'Texas',
		'abbreviation': 'TX'
	},
	{
		'name': 'Utah',
		'abbreviation': 'UT'
	},
	{
		'name': 'Vermont',
		'abbreviation': 'VT'
	},
	{
		'name': 'Virginia',
		'abbreviation': 'VA'
	},
	{
		'name': 'Washington',
		'abbreviation': 'WA'
	},
	{
		'name': 'West Virginia',
		'abbreviation': 'WV'
	},
	{
		'name': 'Wisconsin',
		'abbreviation': 'WI'
	},
	{
		'name': 'Wyoming',
		'abbreviation': 'WY'
	}
];

export const SHARE_LIST = {
	public: 'My Organization',
	customList: 'Custom List'
};

export const filterOffColor = 'text-secondary-light';

export const MAX_NUMBER_OF_STARS = 5;

export const ALLOWED_RATING_ARRAY = [
	PROVIDER_TYPE.facility,
	PROVIDER_TYPE.homehealth,
	PROVIDER_TYPE.skillednursing
];

export const MEDICARE_STAYS_EPISODES = [
	{
		id: 'stay',
		display: 'Stays',
	},
	{
		id: 'claim',
		display: 'Claims'
	}
];

export const CUSTOM_MEDICARE_REIMBRUSEMENT_DISPLAY_NAMES = [
	{
		portal: PORTAL_TYPE.HHA,
		filterType: 'claim',
		title: 'Billing Periods'
	}
];

export const MEDICARE_DATES = [
	{
		id: 'clm_from_dt',
		display: 'By Start Date'
	},
	{
		id: 'clm_thru_dt',
		display: 'By End Date'
	}
];

export const PORTAL_PENDO_KEY = {
	hos: '98ea3662-5291-4273-5fc3-955c80d30318',
	hha: 'bd1ecf01-6aec-414c-6eb0-97882962a51a',
	snf: '5031e392-0978-4fe4-6ae8-5939b0618be5'
};

export enum FILTER_LOCATION {
	Normal = 'Normal',
	FilterBox = 'FilterBox',
}

export enum FILTERBOX_LOCATION {
	Explore = 'explore',
	Analyze = 'analyze',
	DataKit = 'datakit'
}

export const DEFAULT_ERROR_ADDONS = [
	{
		key: 0,
		message: '',
	},
	{
		key: 1,
		message: 'Please try again.',
	},
	{
		key: 2,
		message: 'Please contact <a href=\'mailto:support@trellahealth.com\'>Customer Support</a> at <a href=\'mailto:support@trellahealth.com\'>support@trellahealth.com</a>',
	},
	{
		key: 3,
		message: 'Please contact <a href=\'mailto:support@trellahealth.com\'>Customer Support</a> at <a href=\'mailto:support@trellahealth.com\'>support@trellahealth.com</a> or your manager.',
	},
	{
		key: 4,
		message: 'Please contact <a href=\'mailto:support@trellahealth.com\'>Customer Support</a> at <a href=\'mailto:support@trellahealth.com\'>support@trellahealth.com</a> if you believe this is in error.',
	}
];

export enum DIALOG_TYPE {
	ConfigDataInfo = 'ConfigDataInfo',
	DevInfo = 'DevInfo'
}

export const PMVQualityMetrics = [
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.physician,
		reports: [
			{
				name: 'avg_nurse_ther_visits',
				displayName: 'Average Visits per Episode',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'physician_quality_utilization_stage',
				displayName: 'Agency Hospitalization and Readmission',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'agency_quality',
				displayName: 'Agency Quality Summary',
				visible: false,
				bubbleChart: true,
			}
		]
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.physician,
		reports: [
			{
				name: 'avg_visits_last_three',
				displayName: 'Average Visits - Last Three Days',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'avg_visits_last_seven',
				displayName: 'Average Visits - Last Seven Days',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'physician_quality_utilization_stay',
				displayName: 'Agency Length of Stay',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'agency_quality',
				displayName: 'Agency Quality Summary',
				visible: false,
				bubbleChart: false,
			}
		]
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.physician,
		reports: [
			{
				name: 'physician_quality_utilization_stage',
				displayName: 'Hospitalization and Readmit Rates',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'agency_quality',
				displayName: 'Quality Summary',
				visible: false,
				bubbleChart: true,
			},
		]
	},
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.facility,
		reports: [
			{
				name: 'facility_quality_utilization_stage',
				displayName: 'Hospitalizations and Readmission Rates',
				visible: false,
				bubbleChart: false,
			}
		]
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.facility,
		reports: [
			{
				name: 'facility_quality_utilization_stage',
				displayName: 'Hospitalizations and Readmission Rates',
				visible: false,
				bubbleChart: false,
			}
		]
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.facility,
		reports: [
			{
				name: 'facility_quality_utilization_stage',
				displayName: 'Hospitalizations and Readmission Rates',
				visible: false,
				bubbleChart: false,
			}
		]
	},
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.physician,
		reports: [
			{
				name: 'avg_nurse_ther_visits',
				displayName: 'Average Visits per Episode',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'physician_quality_utilization_stage',
				displayName: 'Agency Hospitalization and Readmission',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'agency_quality',
				displayName: 'Agency Quality Summary',
				visible: false,
				bubbleChart: true,
			}
		]
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.physician,
		reports: [
			{
				name: 'avg_visits_last_three',
				displayName: 'Average Visits - Last Three Days',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'avg_visits_last_seven',
				displayName: 'Average Visits - Last Seven Days',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'physician_quality_utilization_stay',
				displayName: 'Agency Length of Stay',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'agency_quality',
				displayName: 'Agency Quality Summary',
				visible: false,
				bubbleChart: false,
			}
		]
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.physician,
		reports: [
			{
				name: 'physician_quality_utilization_stage',
				displayName: 'Hospitalization and Readmit Rates',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'agency_quality',
				displayName: 'Quality Summary',
				visible: false,
				bubbleChart: true,
			},
		]
	},
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.homehealth,
		reports: [
			{
				name: 'avg_nurse_ther_visits',
				displayName: 'Average Visits per Episode',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'physician_quality_utilization_stage',
				displayName: 'Agency Hospitalization and Readmission',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'agency_quality',
				displayName: 'Agency Quality Summary',
				visible: false,
				bubbleChart: true,
			}
		]
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.hospice,
		reports: [
			{
				name: 'avg_visits_last_three',
				displayName: 'Average Visits - Last Three Days',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'avg_visits_last_seven',
				displayName: 'Average Visits - Last Seven Days',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'physician_quality_utilization_stay',
				displayName: 'Agency Length of Stay',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'agency_quality',
				displayName: 'Agency Quality Summary',
				visible: false,
				bubbleChart: false,
			}
		]
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.skillednursing,
		reports: [
			{
				name: 'physician_quality_utilization_stage',
				displayName: 'Hospitalization and Readmit Rates',
				visible: false,
				bubbleChart: false,
			},
			{
				name: 'agency_quality',
				displayName: 'Quality Summary',
				visible: false,
				bubbleChart: true,
			},
		]
	}
];

export const PMVUtilizationSpecificGrids = [
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.physician,
		reports: ['pmv_physall', 'pmv_physfollowed', 'pmv_physoriginated', 'pmv_physlastclaim']
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.physician,
		reports: ['pmv_physall', 'pmv_physattended', 'pmv_physoriginated', 'pmv_physlastclaim']
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.physician,
		reports: ['pmv_physall', 'pmv_physattended', 'pmv_physoriginated', 'pmv_physlastclaim']
	},
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.facility,
		reports: [GRIDS.FAC_METRIC_ALL, GRIDS.FAC_METRIC_INP, GRIDS.FAC_METRIC_OUTP, GRIDS.FAC_METRIC_HH, GRIDS.FAC_METRIC_SNF]
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.facility,
		reports: [GRIDS.FAC_METRIC_ALL, GRIDS.FAC_METRIC_INP, GRIDS.FAC_METRIC_OUTP, GRIDS.FAC_METRIC_HH, GRIDS.FAC_METRIC_SNF]
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.facility,
		reports: [GRIDS.FAC_SNF_METRIC_ALL, GRIDS.FAC_SNF_METRIC_INP, GRIDS.FAC_SNF_METRIC_OUTP, GRIDS.FAC_SNF_METRIC_HH, GRIDS.FAC_SNF_METRIC_SNF]
	},
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.homehealth,
		reports: ['pmv_physall', 'pmv_physfollowed', 'pmv_physoriginated', 'pmv_physlastclaim']
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.hospice,
		reports: ['pmv_physall', 'pmv_physattended', 'pmv_physoriginated', 'pmv_physlastclaim']
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.skillednursing,
		reports: ['pmv_physoriginated', 'pmv_physlastclaim']
	},
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.homehealth,
		reports: ['pmv_physall', 'pmv_physfollowed', 'pmv_physoriginated', 'pmv_physlastclaim']
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.hospice,
		reports: ['pmv_physall', 'pmv_physattended', 'pmv_physoriginated', 'pmv_physlastclaim']
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.skillednursing,
		reports: ['pmv_physoriginated', 'pmv_physlastclaim']
	},
];

export const PMVUtilizationMetrics = [
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.physician,
		reports: [
			{
				name: 'hh_destinations',
				displayName: 'Trended Destinations',
				visible: false,
				isChart: true,
				isGrid: false,
			},
			{
				name: 'pmv_physoriginated',
				displayName: 'Home Health Agency Destinations - 3 Months Prior',
				visible: false,
				isChart: false,
				isGrid: true,
			}
		]
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.physician,
		reports: [
			{
				name: 'trended_hospice_destinations',
				displayName: 'Trended Destinations',
				visible: false,
				isChart: true,
				isGrid: false,
			},
			{
				name: 'pmv_physall',
				displayName: 'Hospice Destinations - All',
				visible: false,
				isChart: false,
				isGrid: true,
			}
		]
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.physician,
		reports: [
			{
				name: 'snf_pmv_destinations',
				displayName: 'Trended Destinations',
				visible: false,
				isChart: true,
				isGrid: false,
			},
			{
				name: 'pmv_physall',
				displayName: 'SNF Destinations - All',
				visible: false,
				isChart: false,
				isGrid: true,
			},
		]
	},
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.facility,
		reports: [
			{
				name: 'pmv_facility_utilization_inp',
				displayName: 'Facility Utilization - Inpatient',
				visible: false,
				isChart: true,
				isGrid: false,
				hideComparison: true
			},
			{
				name: CHARTS.PAD_OUTCOMES_BY_SETTING_INP,
				displayName: 'Outcomes by Setting: Inpatient',
				visible: false,
				isChart: true,
				isGrid: false,
				hideComparison: true
			},
			{
				name: GRIDS.FAC_METRIC_ALL,
				displayName: 'Home Health Destinations',
				visible: false,
				isChart: false,
				isGrid: true,
			},
			{
				name: COMMON.TRENDED_READMIT_RATE,
				displayName: 'Trended Readmission Rate',
				visible: false,
				isChart: true,
				isGrid: false,
				hideComparison: true
			},
		]
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.facility,
		reports: [
			{
				name: 'pmv_facility_utilization_inp',
				displayName: 'Facility Utilization - Inpatient',
				visible: false,
				isChart: true,
				isGrid: false,
				hideComparison: true
			},
			{
				name: CHARTS.PAD_OUTCOMES_BY_SETTING_INP,
				displayName: 'Outcomes by Setting: Inpatient',
				visible: false,
				isChart: true,
				isGrid: false,
				hideComparison: true
			},
			{
				name: GRIDS.FAC_METRIC_ALL,
				displayName: 'Hospice Destinations',
				visible: false,
				isChart: false,
				isGrid: true,
			},
			{
				name: COMMON.TRENDED_READMIT_RATE,
				displayName: 'Trended Readmission Rate',
				visible: false,
				isChart: true,
				isGrid: false,
				hideComparison: true
			},
		]
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.facility,
		reports: [
			{
				name: 'pmv_facility_utilization_inp',
				displayName: 'Facility Utilization - Inpatient',
				visible: false,
				isChart: true,
				isGrid: false,
				hideComparison: true
			},
			{
				name: CHARTS.PAD_OUTCOMES_BY_SETTING_INP,
				displayName: 'Outcomes by Setting: Inpatient',
				visible: false,
				isChart: true,
				isGrid: false,
				hideComparison: true
			},
			{
				name: GRIDS.FAC_SNF_METRIC_ALL,
				displayName: 'SNF Destinations',
				visible: false,
				isChart: false,
				isGrid: true,
			},
			{
				name: COMMON.TRENDED_READMIT_RATE,
				displayName: 'Trended Readmission Rate',
				visible: false,
				isChart: true,
				isGrid: false,
				hideComparison: true
			},
		]
	},
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.homehealth,
		reports: [
			{
				name: 'hh_destinations',
				displayName: 'Trended Destinations',
				visible: false,
				isChart: true,
				isGrid: false,
			},
			{
				name: 'pmv_physoriginated',
				displayName: 'Home Health Agency Destinations - 3 Months Prior',
				visible: false,
				isChart: false,
				isGrid: true,
			}
		]
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.hospice,
		reports: [
			{
				name: 'trended_hospice_destinations',
				displayName: 'Trended Destinations',
				visible: false,
				isChart: true,
				isGrid: false,
			},
			{
				name: 'pmv_physall',
				displayName: 'Hospice Destinations - All',
				visible: false,
				isChart: false,
				isGrid: true,
			}
		]
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.skillednursing,
		reports: [
			{
				name: 'snf_pmv_destinations',
				displayName: 'Trended Destinations',
				visible: false,
				isChart: true,
				isGrid: false,
			},
			{
				name: 'pmv_physoriginated',
				displayName: 'Home Health Agency Destinations - 3 Months Prior',
				visible: false,
				isChart: false,
				isGrid: true,
			},
		]
	},
];


export const PMVPatientPopulationCharts = [
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.physician,
		reports: [
			{
				name: 'patient_mix',
				displayName: 'Patient Diagnostic Mix',
				visible: false
			},
			{
				name: 'age',
				displayName: 'Patient Age',
				visible: false,
			},
			{
				name: 'utilization_originated',
				displayName: '3 Months Prior',
				visible: false
			}
		]
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.physician,
		reports: [
			{
				name: 'patient_mix',
				displayName: 'Patient Diagnostic Mix',
				visible: false
			},
			{
				name: 'age',
				displayName: 'Patient Age',
				visible: false,
			},
			{
				name: 'physician_utilization',
				displayName: 'Hospice Utilization',
				visible: false
			}
		]
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.physician,
		reports: [
			{
				name: 'patient_mix',
				displayName: 'Patient Diagnostic Mix',
				visible: false
			},
			{
				name: 'age',
				displayName: 'Patient Age',
				visible: false,
			},
			{
				name: 'utilization_originated',
				displayName: '3 Months Prior',
				visible: false
			}
		]
	},
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.facility,
		reports: [
			{
				name: 'pmv_facility_patient_volume_inp',
				displayName: 'Patient Volume - Inpatient',
				visible: false
			},
			{
				name: 'facility_mdc_all',
				displayName: 'Diagnostic Breakout by Stay',
				visible: false,
			},
			{
				name: 'facility_age',
				displayName: 'Patient Age',
				visible: false
			}
		]
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.facility,
		reports: [
			{
				name: 'pmv_facility_patient_volume_inp',
				displayName: 'Patient Volume - Inpatient',
				visible: false
			},
			{
				name: 'facility_mdc_all',
				displayName: 'Diagnostic Breakout by Stay',
				visible: false,
			},
			{
				name: 'facility_age',
				displayName: 'Patient Age',
				visible: false
			}
		]
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.facility,
		reports: [
			{
				name: 'pmv_facility_patient_volume_inp',
				displayName: 'Patient Volume - Inpatient',
				visible: false
			},
			{
				name: 'facility_mdc_all',
				displayName: 'Diagnostic Breakout by Stay',
				visible: false,
			},
			{
				name: 'facility_age',
				displayName: 'Patient Age',
				visible: false
			}
		]
	},
	{
		portal: PORTAL_TYPE.HHA,
		key: PROVIDER_TYPE.homehealth,
		reports: [
			{
				name: 'patient_mix',
				displayName: 'Patient Diagnostic Mix',
				visible: false
			},
			{
				name: 'age',
				displayName: 'Patient Age',
				visible: false,
			},
			{
				name: 'utilization_originated',
				displayName: '3 Months Prior',
				visible: false
			}
		]
	},
	{
		portal: PORTAL_TYPE.HOS,
		key: PROVIDER_TYPE.hospice,
		reports: [
			{
				name: 'patient_mix',
				displayName: 'Patient Diagnostic Mix',
				visible: false
			},
			{
				name: 'age',
				displayName: 'Patient Age',
				visible: false,
			},
			{
				name: 'physician_utilization',
				displayName: 'Hospice Utilization',
				visible: false
			}
		]
	},
	{
		portal: PORTAL_TYPE.SNF,
		key: PROVIDER_TYPE.skillednursing,
		reports: [
			{
				name: 'patient_mix',
				displayName: 'Patient Diagnostic Mix',
				visible: false
			},
			{
				name: 'age',
				displayName: 'Patient Age',
				visible: false,
			},
			{
				name: 'utilization_originated',
				displayName: '3 Months Prior',
				visible: false
			}
		]
	},
];

export const LogSources = {
	Desktop: 1,
	Mobile: 2
};

export const NavButtonColors = {
	Enabled: '#5B6770',
	Disabled: '#939393'
};

export enum NavigationDirection {
	Back,
	Forward
}

export interface NavButtonContent {
	direction: NavigationDirection;
	isEnabled: boolean;
}

export const PORTALPROVIDERMAP = [
	{
		portal: PORTAL_TYPE.SNF,
		keys: [
			{
				provider: PROVIDER_TYPE.noProvider,
				correctProvider: PROVIDER_TYPE.facility
			},
			{
				provider: PROVIDER_TYPE.hospice,
				correctProvider: PROVIDER_TYPE.facility
			},
			{
				provider: PROVIDER_TYPE.homehealth,
				correctProvider: PROVIDER_TYPE.facility
			},
		]
	},
	{
		portal: PORTAL_TYPE.HHA,
		keys: [
			{
				provider: PROVIDER_TYPE.noProvider,
				correctProvider: PROVIDER_TYPE.facility
			},
			{
				provider: PROVIDER_TYPE.hospice,
				correctProvider: PROVIDER_TYPE.facility
			},
			{
				provider: PROVIDER_TYPE.skillednursing,
				correctProvider: PROVIDER_TYPE.facility
			},
		]
	},
	{
		portal: PORTAL_TYPE.HOS,
		keys: [
			{
				provider: PROVIDER_TYPE.noProvider,
				correctProvider: PROVIDER_TYPE.facility
			},
			{
				provider: PROVIDER_TYPE.homehealth,
				correctProvider: PROVIDER_TYPE.facility
			},
			{
				provider: PROVIDER_TYPE.skillednursing,
				correctProvider: PROVIDER_TYPE.facility
			},
		]
	}
];
