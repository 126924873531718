import { Injectable } from '@angular/core';
import { ApplicationCacheService } from './application-cache.service';
import { PageLogRequest } from '../models/log';
import { LogApi } from '../../api/log.api';
import {LogSources, PAGE_TYPE, PORTAL_TYPE} from '../shared.constants';
import { Observable, of } from 'rxjs';
import Utils from '../Utils';

@Injectable({
	providedIn: 'root'
})

export class LogService {
	get portal() {
		return this.applicationCacheService.portal;
	}

	constructor(private applicationCacheService: ApplicationCacheService, private logApi: LogApi) { }

	getPageLogRequest(url?: string): PageLogRequest {
		const logRequest = new PageLogRequest();

		if (!url || url.length === 0) {
			logRequest.source = LogSources.Mobile; // 2 is for mobile
			logRequest.login = true;
			return logRequest;
		}
		const splitUrl = this.splitUrl(url);

		logRequest.page = this.getPageId(splitUrl);
		logRequest.route = url;
		logRequest.portal = this.portal;
		logRequest.npi = this.getNpi(splitUrl);
		logRequest.source = LogSources.Mobile; // 2 is for mobile
		return logRequest;
	}

	public logLoginRequest(portal: string): Observable<true> {
		if (!portal) {
			return;
		}
		// parse the pieces
		const pageLogRequest = this.getPageLogRequest();
		pageLogRequest.portal = portal;
		return this.logApi.savePageLog(pageLogRequest);
	}

	public logUsageRequest(fullUrl: string, login: boolean = false): Observable<true> {
		if (login) {
			return this.logLoginRequest(this.getPortalFromUrl(this.splitUrl(fullUrl)));
		}

		const pageLogRequest = this.getPageLogRequest(fullUrl);
		if (pageLogRequest && pageLogRequest.portal && !pageLogRequest.route.includes('chooseApplication')) {
			return this.logApi.savePageLog(pageLogRequest);
		}

		return of(null);
	}

	splitUrl(url: string) {
		return url.split('/');
	}

	getPortalFromUrl(splitUrl: string[]) {
		const portal =
			splitUrl.find((value, index) => {
				return Object.values(PORTAL_TYPE).find((v) => v === value);
			});
		return portal;
	}

	getPageId(url: string[]) {
		let pageUrl = '';
		if (!url.length) {
			return pageUrl;
		}

		url.forEach((value, index) => {
			if (Object.values(PAGE_TYPE).some((v) => v === value)) {
				// the explore page does not recognize the first time you visit the explore physician page
				let complimentaryComponent = '';
				if (typeof url[index + 1] !== 'undefined') {
					complimentaryComponent = `_${url[index + 1]}`;
				}

				if (value === PAGE_TYPE.lists && !complimentaryComponent) {
					complimentaryComponent = '_physician';
				}

				pageUrl = `${value}${complimentaryComponent}`;
			}
		});
		return pageUrl;
	}

	getNpi(url: string[]) {
		if (!url.length) {
			return '';
		}

		const possibleNpi = url[url.length - 1];
		if (parseInt(possibleNpi, 10)) {
			return possibleNpi;
		}
		return '';
	}
}
