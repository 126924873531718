import { cloneDeep, uniq, round } from 'lodash';
import * as moment from 'moment';
import { SelectListObject } from './models/select-list';
import {
	DISPLAY_VALUE,
	PORTAL_TYPE,
	ProviderTypeConfigs,
	PROVIDER_TYPE,
	NpiGroupTypeConfigs,
	RAW_VALUE,
	ROUTE_CONSTANTS,
	SPECIAL_DATA_VALUES,
	AGENCY_PROVIDER_TYPES,
	TARGET_TYPE,
	FORMAT_FUNCTIONS,
	FORMAT_FUNCTION_TARGET_TYPE,
	CHART_DATA_TYPE,
	RISK_CATEGORY,
	LOCAL_STORAGE_KEY
} from './shared.constants';
import { isNumber } from 'util';
import numeral from 'numeral';
import { COLUMN_DATA_TYPE, GRID_FILTER_TYPE } from './models/grid-column';

export interface EnumItem<E> { ID: keyof E; Name: E; }

export default class Utils {
	public static currentQuarter: string; // Gets set on CurrentUser call

	static exists(val: any): boolean {
		return val !== undefined && val !== null && val !== '';
	}

	static isSpecialValue(data) {
		return SPECIAL_DATA_VALUES.indexOf(data) > -1;
	}

	static deepClone(source: any) {
		return cloneDeep(source);
	}

	static toCalendarString(date) {
		return date ? moment(date).format('LL') : 'Unavailable';
	}

	static stringContains(src: string, val: string) {
		return src.toLowerCase().indexOf(val.toLowerCase()) > -1;
	}

	static arrayLength(array: Array<any>) {
		return (array || []).length;
	}

	static stringReplaceArray(value: string, keyValuePairs: any) {
		if (!value) {
			return;
		}
		if (!keyValuePairs) {
			return value;
		}

		return Object.keys(keyValuePairs).reduce((prev, current) => {
			if (!keyValuePairs[current]) {
				return '';
			}
			return prev.replace(new RegExp(`@${current}`, 'g'), keyValuePairs[current]);
		}, value);
	}

	static generateGuid() {
		const s = [];
		const hexDigits = '0123456789abcdef';
		for (let i = 0; i < 36; i++) {
			s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
		}
		s[14] = '4';
		s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
		s[8] = s[13] = s[18] = s[23] = '-';

		const uuid = s.join('');
		return uuid;
	}

	static getSelectListObjectFromPrimitive(list: any[]): SelectListObject[] {
		if (!list || !list.length) {
			return [];
		}

		const uniqueArray = uniq(list);
		return uniqueArray.map(x => {
			return {
				id: x,
				display: x
			};
		}).sort((a, b) => {
			if (a.display < b.display) {
				return -1;
			}
			if (a.display > b.display) {
				return 1;
			}
			return 0;
		});
	}

	static findKeyCaseInsensitive(obj: any, targetKey: string): string {
		const lowerCaseTargetKey = targetKey.toLowerCase();
		return Object.keys(obj).find(key => key.toLowerCase() === lowerCaseTargetKey);
	}

	static getEnumList(enumObject): EnumItem<any>[] {
		return Object.keys(enumObject).map(key => ({ID: key, Name: enumObject[key]} as EnumItem<typeof enumObject>));
	}

	static getAvailableProviderTypesConfigsForPortal(portal: PORTAL_TYPE) {
		switch (portal) {
			case PORTAL_TYPE.HHA:
				return [ProviderTypeConfigs.physician, ProviderTypeConfigs.facility, ProviderTypeConfigs.homehealth];
			case PORTAL_TYPE.HOS:
				return [ProviderTypeConfigs.physician, ProviderTypeConfigs.facility, ProviderTypeConfigs.hospice];
			case PORTAL_TYPE.SNF:
				return [ProviderTypeConfigs.physician, ProviderTypeConfigs.facility, ProviderTypeConfigs.skillednursing];
			default:
				return [ProviderTypeConfigs.physician, ProviderTypeConfigs.facility];
		}
	}

	static getAvailableProviderTypesForPortal(portal: PORTAL_TYPE) {
		switch (portal) {
			case PORTAL_TYPE.HHA:
				return [PROVIDER_TYPE.physician, PROVIDER_TYPE.facility, PROVIDER_TYPE.homehealth];
			case PORTAL_TYPE.HOS:
				return [PROVIDER_TYPE.physician, PROVIDER_TYPE.facility, PROVIDER_TYPE.hospice];
			case PORTAL_TYPE.SNF:
				return [PROVIDER_TYPE.physician, PROVIDER_TYPE.facility, PROVIDER_TYPE.skillednursing];
			default:
				return [PROVIDER_TYPE.physician, PROVIDER_TYPE.facility];
		}
	}

	static getRouteAttrByProviderType(type: PROVIDER_TYPE) {
		switch (type) {
			case PROVIDER_TYPE.physician:
				return ROUTE_CONSTANTS.physician;
			case PROVIDER_TYPE.facility:
				return ROUTE_CONSTANTS.facility;
			case PROVIDER_TYPE.hospice:
				return ROUTE_CONSTANTS.hospice;
			case PROVIDER_TYPE.homehealth:
				return ROUTE_CONSTANTS.homehealth;
			case PROVIDER_TYPE.skillednursing:
				return ROUTE_CONSTANTS.skillednursing;
			default:
				return null;
		}
	}

	static getProviderTypeConfig(providerType: PROVIDER_TYPE) {
		switch (providerType) {
			case PROVIDER_TYPE.physician:
				return ProviderTypeConfigs.physician;
			case PROVIDER_TYPE.facility:
				return ProviderTypeConfigs.facility;
			case PROVIDER_TYPE.homehealth:
				return ProviderTypeConfigs.homehealth;
			case PROVIDER_TYPE.hospice:
				return ProviderTypeConfigs.hospice;
			case PROVIDER_TYPE.skillednursing:
				return ProviderTypeConfigs.skillednursing;
			default:
				return null;
		}
	}

	static getRouteByProvider(providerType: PROVIDER_TYPE) {
		switch (providerType) {
			case PROVIDER_TYPE.homehealth:
				return PORTAL_TYPE.HHA;
			case PROVIDER_TYPE.hospice:
				return PORTAL_TYPE.HOS;
			case PROVIDER_TYPE.skillednursing:
				return PORTAL_TYPE.SNF;
			default:
				return null;
		}
	}

	static getPercentValueFromDecimal(value: any, multiply: boolean = true): number {
		if (!Utils.exists(value) || Utils.isSpecialValue(value) || value <= 0) {
			return 0;
		}

		return value * (multiply ? 100 : 1);
	}

	static getDefaultDisplayedValue(value: any, format: string, decimalPoints: number = null) {
		// TODO: Create this transform library on the backend!
		if (!value && value !== 0) {
			return;
		}

		const isText = !format || format === GRID_FILTER_TYPE.text;
		const isPercent = format === COLUMN_DATA_TYPE.percent;
		const isCurrency = format === COLUMN_DATA_TYPE.currency || (format && format.includes('$'));
		const isDate = format === COLUMN_DATA_TYPE.date;

		if (Utils.doesFormatFunctionExist(format)) {
			const transformFunc = Utils.getFormatFunction(format);
			return transformFunc(value);
		}


		if (!Utils.exists(format) || !Utils.exists(value) || Utils.isSpecialValue(value)) {
			return value;
		}

		if (value <= 0 && format.indexOf('$') === -1) {
			return Utils.getDisplayedValue(value, format);
		}

		if (format.indexOf('%') > -1 || isPercent) {
			// Turn into percent
			value = Utils.getPercentValueFromDecimal(value);

			if (!value) {
				// Value is special. Handle this generically
				return Utils.getDisplayedValue(value, format);
			}

			return value.toFixed(decimalPoints || 2) + '%';
		}

		if (format.indexOf('I') > -1) {
			// Round to nearest integer
			return round(value);
		}

		if (format.indexOf('1') > -1) {
			// Decimal with 1 digit after dot
			return parseFloat(value).toFixed(1);
		}

		// handle money format
		if (format.indexOf('$') > -1 || isCurrency) {
			const parsedValue = parseFloat(value);
			if (parsedValue === 0) {
				return '$0';
			}

			if (parsedValue < 0) {
				return Utils.getDisplayedValue(value, format);
			}

			return parseFloat(value).toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: decimalPoints,
				maximumFractionDigits: decimalPoints
			});
		}

		if (decimalPoints || decimalPoints > 0) {
			return parseFloat(value).toFixed(decimalPoints);
		}

		return Utils.getDisplayedValue(value, format);
	}

	static getDisplayedValue(value: any, format: string) {
		if (!format && value > 0 || (!value && value !== 0 && value !== false)) {
			return value;
		}

		if (value == RAW_VALUE.negativeOne) {
			return DISPLAY_VALUE.lessThanEleven;
		} else if (value == RAW_VALUE.negativeTwo || value == RAW_VALUE.negativeFour) {
			return DISPLAY_VALUE.dash;
		} else if (value == RAW_VALUE.negativeThree) {
			return DISPLAY_VALUE.insufficient;
		} else if (value == RAW_VALUE.negativeFive) {
			return '';
		} else if (format && format.indexOf('%') > -1) {
			return `${(value * 100).toFixed(1)}%`;
		}

		return this.numberWithCommas(value);
	}

	static numberWithCommas(value: any) {
		if (!value && value != 0) {
			return '';
		}

		if (value == 0) {
			return '0';
		}

		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	static getChartDisplayedValue(value: any, formattedDataType: string, displayBefore: boolean) {
		if (value === undefined) {
			return '';
		}

		if (isNumber(value) && value >= 0) {
			return displayBefore ? `${formattedDataType}${value.toLocaleString()}` : `${value.toLocaleString()}${formattedDataType}`;
		}

		return this.getDisplayedValue(value, '') + formattedDataType;
	}

	static isNumber(value: any) {
		return typeof value === 'number';
	}

	static getYearQuarterStringFromQm(qm: string) {
		if (!qm || qm.length < 2) {
			return qm;
		}

		let quarter = parseInt(qm.charAt(2)); // number of quarters we want to go back
		const pieces = this.currentQuarter.split('-'); // parse out current period fro mDB
		let currentYear = parseInt(pieces[0]); // current year
		const currentQuarter = pieces[1]; // current quarter
		let currentQuarterN = parseInt(currentQuarter.charAt(1)); // current quarter as an integer

		while (quarter > 0) {
			currentQuarterN--;
			if (currentQuarterN <= 0) {
				currentQuarterN = 4;
				currentYear--;
			}
			quarter--;
		}

		return `${currentYear} Q${currentQuarterN}`;
	}

	static getFormattedChartTooltipType(value: number, dataType: string) {
		if (dataType === CHART_DATA_TYPE.percent) {
			return value < 0 ? '' : '%';
		}

		if (dataType === CHART_DATA_TYPE.currency) {
			return value < 0 ? '' : '$';
		}

		return '';
	}

	static getParsedGridColumnHeader(title: string) {
		if (!title) {
			return '';
		}

		const lowerTitle = title.toLowerCase();
		switch (lowerTitle) {
			case '[[qm0]]':
			case '[[qm1]]':
			case '[[qm2]]':
			case '[[qm3]]':
			case '[[qm4]]':
			case '[[qm5]]':
			case '[[qm6]]':
			case '[[qm7]]':
				return Utils.getYearQuarterStringFromQm(title.substr(2, 3));
			default:
				return title;
		}
	}

	static replaceQuarterWithinString(title: string) {
		if (!title) {
			return '';
		}

		const quarterRegEx = /\[\[(.*?)\]\]/gi;
		const quarters = title.match(quarterRegEx);

		for (let i = 0; i < (quarters || []).length; i++) {

			const quarterTagStart = quarters[i].indexOf('[');
			const quarterTagEnd = quarters[i].indexOf(']');

			// want to make sure these string exists
			if (quarterTagStart < 0 || quarterTagEnd < 0) {
				return title;
			}

			// want to make sure the starting brackets are positioned before the ending brackets
			if (quarterTagStart > quarterTagEnd) {
				return title;
			}

			const quarterString = quarters[i].substr(quarterTagStart, ((quarterTagEnd + 1) - quarterTagStart) + 1);
			const quarter = Utils.getParsedGridColumnHeader(quarterString);

			// replace quarter tag with acutal quarter
			title = title.replace(quarterString, quarter);
		}
		return title;
	}

	static isAgencyProviderType(providerType: PROVIDER_TYPE): boolean {
		return AGENCY_PROVIDER_TYPES.includes(providerType);
	}

	static doesFormatFunctionExist(functionName: string) {
		return FORMAT_FUNCTIONS.includes(functionName);
	}

	// TODO: This should be moved to backend!
	static getFormatFunction(formatFunction: string) {
		switch (formatFunction) {
			case FORMAT_FUNCTION_TARGET_TYPE:
				return this.targetTypeTransform;
		}
		return null;
	}

	static targetTypeTransform(val: TARGET_TYPE): string {
		switch (val) {
			case TARGET_TYPE.CA:
				return `Competitor Affiliated`;
			case TARGET_TYPE.UU:
				return `Underutilizing`;
			case TARGET_TYPE.UA:
				return `Unaffiliated`;
			case TARGET_TYPE.US:
				return `User Selected`;
		}
		return '';
	}

	static getPortal(portal: string): PORTAL_TYPE {
		portal = (portal || '').toLowerCase();
		switch (portal) {
			case `admin`:
				return PORTAL_TYPE.ADMIN;
			case `hos`:
				return PORTAL_TYPE.HOS;
			case `hha`:
				return PORTAL_TYPE.HHA;
			case `snf`:
				return PORTAL_TYPE.SNF;
			default:
				return;
		}
	}

	static spotlightFormatting(value: any, format: any) {
		// spotlight percent values in x.xx format
		if (format == '%') {
			// check if number is already percent decimal form, or whole number and handle
			const isPercentFormatted = String(value).match(/\d?\.\d?/gi);

			// Turn into percent
			value = Utils.getPercentValueFromDecimal(value, !isPercentFormatted && !Number.isInteger(value));

			return value.toFixed(2) + '%';
		} else return this.benchMarkFormatting(value, format);

	}

	static benchMarkFormatting(value: any, format: string) {
		return Utils.getDefaultDisplayedValue(value, (format && format.includes('%') ? 'p' : 'i'), (format && format.includes('.00')) ? 2 : null);
	}

	static encodeQueryParam(object: Object) {
		const ret = [];

		for (const key of Object.keys(object)) {
			const value = object[key];
			const qKey = key.toLowerCase()[0];
			if (value instanceof Array) {
				if (value.length) {
					value.forEach(v => {
						ret.push(qKey + '=' + v);
					});
				}

			} else if (value) {
				ret.push(qKey + '=' + value);
			}
		}
		return ret.join('&');
	}

	static convertToPascalCase(title: string) {
		if (!title) {
			return '';
		}

		title = title.replace(/(\w)(\w*)/g,
			function (g0, g1, g2) {
				return g1.toUpperCase() + g2.toLowerCase();
			});

		return title;
	}

	static createFilterList(values: any) {
		if (!values || !values.length) {
			return [];
		}

		const filterList = [];
		values.forEach(v => {
			const item: SelectListObject = {
				id: v,
				display: v
			};

			filterList.push(item);
		});

		return filterList;
	}

	static getCorrectMarket(marketList: SelectListObject[]) {
		const localStorageMarket = localStorage.getItem(LOCAL_STORAGE_KEY.MARKET_SHARE_STATE);
		const hasMarket = marketList.find(m => m.display == localStorageMarket);

		if (localStorageMarket && hasMarket) {
			return marketList.find(m => m.display == localStorageMarket);
		}

		return marketList[0];
	}

	static browserIE() {
		return navigator.userAgent.includes('MSIE') || navigator.appVersion.includes('Trident/');
	}

	static valueIsPositive(value: unknown | number) {
		if (!value) {
			return false;
		}

		return value > 0;
	}

	static removeTags(str) {
		if ((str === null) || (str === '')) {
			return false;
		} else {
			str = str.toString();
		}
		return str.replace(/(<([^>]+)>)/ig, '');
	}

	static getParameterCaseInsensitive(object, objKey) {
		return object[Object.keys(object).find((key) => {
			if (!key || !objKey) {
				return undefined;
			}
			return key.toLowerCase() === objKey.toLowerCase();
		})];
	}

	static getAgencyForPortal(portal: PORTAL_TYPE) {
		switch (portal) {
			case PORTAL_TYPE.HHA:
				return PROVIDER_TYPE.homehealth;
			case PORTAL_TYPE.HOS:
				return PROVIDER_TYPE.hospice;
			case PORTAL_TYPE.SNF:
				return PROVIDER_TYPE.skillednursing;
			case PORTAL_TYPE.HME:
				return PROVIDER_TYPE.organization;
			default:
				return null;
		}
	}
}



export const contains = (string: string, arr: string[]) => arr.every(word => string.includes(word));
export const RiskScore = Object.freeze({
	isLow: (score: string): boolean => score === RISK_CATEGORY.Low || score === RISK_CATEGORY.VeryLow,
	isMedium: (score: string): boolean => score === RISK_CATEGORY.Medium || score === RISK_CATEGORY.MediumHigh || score === RISK_CATEGORY.MediumLow,
	isHigh: (score: string): boolean => score === RISK_CATEGORY.High || score === RISK_CATEGORY.VeryHigh
});

export const BenchMark = Object.freeze({
	isDown: (base: number, comparison: number): boolean => base > comparison,
	isUp: (base: number, comparison: number): boolean => base < comparison,
	isEqual: (base: number, comparison: number): boolean => base < comparison
});
