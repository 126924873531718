<div class="cover-page-container container-fluid">

	<div class="row">
		<div class="col"></div>
		<div class="col col-auto main-content">


			<div class="row">
				<div class="col pt-5 login-container">
					<app-password-change (update)="onUpdate($event)" *ngIf="hasToken"></app-password-change>

					<div *ngIf="!hasToken">
						<div class="text-heading"> Password Reset</div>
						<div class="password-reset">

							<form (ngSubmit)="passwordReset()">

								<div  *ngIf="!confirmReset" class="text-center py-3">
									Enter your username or email address and we will send you a link to reset your password.
								</div>
								<div  *ngIf="confirmReset" class="text-center py-3">
									Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.
								</div>
								<div class="text-center text-danger">
									{{warning}}
								</div>

								<div *ngIf="!confirmReset">
									<div class="input-group mt-4">
										<div class="input-group-prepend">
											<i class="input-group-text fa fa-user text-secondary"></i>
										</div>
										<input type="text" id="username" name="username"
											class="form-control loginTextBox" [(ngModel)]="username"
											placeholder="Username or Email" autofocus />
									</div>


									<button type="submit" class="btn btn-primary btn-block mt-4">Send password reset
										email</button>

								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="col"></div>
	</div>
</div>
