import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { NavigationService } from './navigation.service';
import { ApplicationCacheService } from './application-cache.service';
import { PORTAL_KEY } from '../shared.constants';
import Utils from '../Utils';

@Injectable({
	providedIn: 'root'
})
export class LoginGuard implements CanActivate {
	constructor(private authService: AuthenticationService, private appCache: ApplicationCacheService, private navigationService: NavigationService) { }

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const token = await AuthenticationService.getCognitoToken();
		if (!token) {
			return this.onAuthError();
		}
		const portal = Utils.getPortal(localStorage.getItem(PORTAL_KEY));

		if (this.appCache.user) {
			if (!portal) {
				this.navigationService.navigateToChooseApp();
			}

			this.navigationService.navigateToLists();
			return false;
		}

		//Haven't retrieved the current user yet, so do that now
		try {
			const response = await this.authService.getCurrentUser();
			const { user } = response;

			if (!user) {
				//Something went wrong
				return this.onAuthError();
			}

			// checking if the portal exists, but the user no longer has permission to this portal
			const userHasPortal = portal && !user.userAccess[portal].length;

			if (!portal && !userHasPortal) {
				this.navigationService.navigateToChooseApp();
			}
			this.navigationService.navigateToLists();
			return false;
		} catch (ex) {
			return this.onAuthError();
		}

	}

	private onAuthError() {
		return true;
	}
}
