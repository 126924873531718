import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptions } from '../shared/models/query-options';
import { ApplicationCacheService } from '../shared/providers/application-cache.service';
import { CLASSIFICATION_TYPE, PROVIDER_TYPE, TARGET_TYPE } from '../shared/shared.constants';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class TargetsApi {
	get portal() {
		return this.applicationCacheService.portal;
	}

	get controllerName() {
		return `${this.portal}/Targets`;
	}

	constructor(private api: ApiService, private applicationCacheService: ApplicationCacheService) { }

	getAll(): Promise<any> {
		return this.api.get(this.controllerName).toPromise();
	}

	getPortalTargets(portal: string): Observable<any> {
		return this.api.get(`${portal}/Targets`);
	}

	addTargets(request: AddTargetsRequest): Promise<AddTargetsResponse> {
		return this.api.post(this.controllerName, null, request).toPromise();
	}

	addTargetsTopX(params: TargetsTopXRequest): Promise<AddTargetsResponse> {
		return this.api.post(this.controllerName, 'addtopx', params).toPromise();
	}

	deleteTargetsTopX(request: TargetsTopXRequest) {
		return this.api.post(this.controllerName, 'deletetopx', request).toPromise();
	}

	updateTarget(request: UpdateTargetRequest): Observable<AddTargetsResponse> {
		return this.api.patch(this.controllerName, `${request.targetId}`, request);
	}

	deleteTargets(targetIds: any[]): Observable<any> {
		return this.api.post(this.controllerName, 'delete', {
			targetIds: targetIds
		});
	}

	clearTargets(providerType: PROVIDER_TYPE): Observable<boolean> {
		return this.api.patch(this.controllerName, `clear/${providerType}`);
	}

	moveTarget(request: MoveTargetRequest): Observable<any> {
		return this.api.patch(this.controllerName, `${request.targetId}/position`, {
			newPosition: request.newPosition
		});
	}

}

export class TargetAssignee {
	userId: number;
	providerType: PROVIDER_TYPE;
	customerId?: number;
	modifiedByUserId: number;
}

export class TargetAssigneeX {
	providerType: PROVIDER_TYPE;
	userId: number;
	modifiedByUserId: number;
	worldId: string;
}

export class AddTargetsRequest {
	targetType: TARGET_TYPE;
	providerType: PROVIDER_TYPE;
	classification: CLASSIFICATION_TYPE;
	npis?: string[];
	targetAssignee?: TargetAssignee;
}

export class UpdateTargetRequest {
	targetId: number;
	targetType: TARGET_TYPE;
	providerType: PROVIDER_TYPE;
	classification: CLASSIFICATION_TYPE;
	npi: string;
}

export class TargetsTopXRequest {
	targetType: TARGET_TYPE;
	providerType: PROVIDER_TYPE;
	classification: CLASSIFICATION_TYPE;
	topX?: number;
	queryOptions?: QueryOptions;
	reportNpi: string;
	targetAssignee?: TargetAssigneeX;
}
export class AddTargetsResponse {
	success: boolean;
	data: any;
}

export class MoveTargetRequest {
	targetId: number;
	newPosition: number;
}
