import {Component, OnInit} from '@angular/core';
import { NavigationService } from '../shared/providers/navigation.service';
import {Router} from '@angular/router';

@Component({
	selector: 'app-page-error',
	templateUrl: './page-error.component.html',
	styleUrls: ['../../styles/components/cover-page.scss', './page-error.component.scss']
})
export class PageErrorComponent implements OnInit {

	constructor(private navigationService: NavigationService, private router: Router) { }

	ngOnInit() {
	}

	toHome() {
		this.navigationService.navigateToLists();
	}
}
