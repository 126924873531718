<kendo-dialog *ngIf="opened" (close)="cancel()" class="dialog-sm">
	<kendo-dialog-titlebar>
		<div class="d-flex flex-row align-items-center justify-content-center text-white">
			<i class="fas fa-share-alt mx-3"></i>
			<div class="text-heading">Marketscape Mobile Update</div>
		</div>
	</kendo-dialog-titlebar>
	<div class="px-2">
		<div class="mt-3 col">
			<div class="notes w-100 px-0 text-left">
				<div class="text-left">A new version of Marketscape Mobile is available. Close this window to refresh and continue.</div>
			</div>
		</div>
		<div class="row no-gutters mt-3">
			<div class="col text-right offset-10 ml-2">
				<button type="button" class="btn btn-success" (click)="close()">CLOSE</button>
			</div>
		</div>
	</div>
</kendo-dialog>
