<div class="passwordInput mt-3">
	<div class="text-heading">Change Password</div>
	<div class="text-center text-danger">
		{{warning}}
	</div>
	<form [formGroup]="passwordValidations"  (ngSubmit)="change()">
		<div class="pw-box">
			<div class="icon-container">
				<i class="fas fa-eye-slash pw-icon"></i>
			</div>
			<input class="loginTextBox" type="password" formControlName="password" [(ngModel)]="password" placeholder="New Password" />
		</div>
		<div class="input-group mt-2 ml-5">
			<label
			[class.text-danger]="passwordValidations.controls['password'].hasError('required') || passwordValidations.controls['password'].hasError('minlength')"
			[class.text-success]="!passwordValidations.controls['password'].hasError('required') && !passwordValidations.controls['password'].hasError('minlength')"
		  >
			<i class="material-icons">
			  {{ passwordValidations.controls['password'].hasError('required') ||
			  passwordValidations.controls['password'].hasError('minlength') ? 'cancel' :  'check_circle' }}
			</i>
			Must be at least 8 characters
		  </label>

		  <label
		  [class.text-danger]="passwordValidations.controls['password'].hasError('required') || passwordValidations.controls['password'].hasError('hasNumber')"
		  [class.text-success]="!passwordValidations.controls['password'].hasError('required') && !passwordValidations.controls['password'].hasError('hasNumber')"
	  		  >
			<i class="material-icons">
			  {{ passwordValidations.controls['password'].hasError('required') ||
			  passwordValidations.controls['password'].hasError('hasNumber') ? 'cancel' : 'check_circle' }}
			</i>
			Must contain at least one number
		  </label>

		  <label
		  [class.text-danger]="passwordValidations.controls['password'].hasError('required') || passwordValidations.controls['password'].hasError('hasCapitalCase')"
		  [class.text-success]="!passwordValidations.controls['password'].hasError('required') && !passwordValidations.controls['password'].hasError('hasCapitalCase')"
		  >
			<i class="material-icons">
			  {{ passwordValidations.controls['password'].hasError('required') ||
			  passwordValidations.controls['password'].hasError('hasCapitalCase') ? 'cancel' : 'check_circle' }}
			</i>
			Must contain at least one uppercase letter
		  </label>

		  <label
		  [class.text-danger]="passwordValidations.controls['password'].hasError('required') || passwordValidations.controls['password'].hasError('hasSmallCase')"
		  [class.text-success]="!passwordValidations.controls['password'].hasError('required') && !passwordValidations.controls['password'].hasError('hasSmallCase')"
		  >
			<i class="material-icons">
			  {{ passwordValidations.controls['password'].hasError('required') ||
			  passwordValidations.controls['password'].hasError('hasSmallCase') ? 'cancel' : 'check_circle' }}
			</i>
			Must contain at least one lowercase letter
		  </label>
		  <label
		  [class.text-danger]="passwordValidations.controls['password'].hasError('required') || passwordValidations.controls['password'].hasError('hasSpecialCharacter')"
		  [class.text-success]="!passwordValidations.controls['password'].hasError('required') && !passwordValidations.controls['password'].hasError('hasSpecialCharacter')"
		  >
			<i class="material-icons">
			  {{ passwordValidations.controls['password'].hasError('required') ||
			  passwordValidations.controls['password'].hasError('hasSpecialCharacter') ? 'cancel' : 'check_circle' }}
			</i>
			Must contain at least one special character
		  </label>
		</div>
		<div class="pw-box">
			<div class="icon-container">
				<i class="fas fa-eye-slash pw-icon"></i>
			</div>
			<input class="loginTextBox" type="password" formControlName="confirmPassword" [(ngModel)]="confirmPassword" placeholder="Confirm New Password" />
		</div>
		<button type="submit" class="btn btn-primary btn-block mt-4" [disabled]="passwordValidations.controls['password'].status === 'INVALID'">Change</button>
	</form>
</div>
