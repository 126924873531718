<div class="row p-3">
	<div class="col-auto mx-auto">

		<div class="row">
			<div class="col cover-page-width">

				<div class="row py-md-5">
					<div class="col-auto logo ">
						<img class="img-fluid" src="../../assets/img/logo.jpg" />
					</div>
				</div>

				<div class="row text-center">
					<div class="col">
						<p class="text-primary fa-15x">Oops - The system is not able to provide this resource at this time.<br/>If the problem persists, please contact Trella Health support.</p>
						<p class="text-heading">
							<a href="#" class="btn btn-lg btn-primary" (click)="toHome()">Back to Home</a>
						</p>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
