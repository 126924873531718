import { Component, OnInit, ViewChild } from '@angular/core';
import { ComponentWithSubscription } from 'src/app/shared/component-with-subscriptions';
import { AuthenticationService } from 'src/app/shared/providers/authentication.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ROUTE_CONSTANTS } from 'src/app/shared/shared.constants';
import { UserFeedbackService } from 'src/app/shared/providers/user-feedback.service';
import { PasswordChangeComponent } from 'src/app/components/password-change/password-change.component';
import { NavigationService } from 'src/app/shared/providers/navigation.service';
import { ApplicationCacheService } from 'src/app/shared/providers/application-cache.service';

@Component({
	selector: 'app-password-reset',
	templateUrl: './password-reset.component.html',
	styleUrls: ['../../../styles/components/cover-page.scss', './password-reset.component.scss']
})
export class PasswordResetComponent extends ComponentWithSubscription implements OnInit {
	@ViewChild(PasswordChangeComponent, { static: false }) passwordChange: PasswordChangeComponent;

	get hasToken() {
		return this.validToken;
	}

	constructor(private userFeedbackService: UserFeedbackService,
		private activatedRoute: ActivatedRoute,
		private navigationService: NavigationService,
		private authenticationService: AuthenticationService,
		private applicationCache: ApplicationCacheService) {
		super();
	}
	username: string;
	token: string;
	validToken = false;
	confirmReset = false;
	warning: string;

	ngOnInit() {
		this.token = this.activatedRoute.snapshot.queryParams[ROUTE_CONSTANTS.passwordResetUrlLabel];
		if (this.token) {
			this.checkToken();
		}
	}

	passwordReset() {
		this.warning = '';
		this.username = this.username.trim();

		if (!this.username) {
			this.warning = 'Username or Email are required';
			return;
		}
		this.userFeedbackService.startSpinner();

		this.authenticationService.requestPasswordResetToken(this.username)
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => {
					this.userFeedbackService.stopSpinner();
				})
			).subscribe(async (obj) => {
				if (!obj.success) {
					this.userFeedbackService.showError(obj.errorMsg);
				} else {
					this.confirmReset = true;
				}
			}, x => {
				this.userFeedbackService.showError(x);
			});

	}

	async onUpdate(userInfo: string[]) {
		this.userFeedbackService.showSuccess('Password successfully changed!');

		if (this.applicationCache.user) {
			this.applicationCache.user.isTempPassword = false;
			this.navigationService.navigateToLanding();
			this.userFeedbackService.stopSpinner();
			return;
		}
		/* TEMP TESTING TED FUTURE TODO - PUT THIS BACK?
		if (userInfo.length === 2) {
			this.authenticationService.login(userInfo[0], userInfo[1])
				.subscribe(async obj => {
					this.authenticationService.onLogin(obj);
					await this.authenticationService.getCurrentUser();
					this.navigationService.navigateToLanding();
					this.userFeedbackService.stopSpinner();
				});
		}
		*/
	}

	async checkToken() {
		const errorMessage = 'Reset link is not valid.';
		this.userFeedbackService.startSpinner();
		await this.authenticationService.validPasswordResetToken(this.token).then(
			(validToken: boolean) => {
				this.validToken = validToken;
				if (!validToken) {
					this.userFeedbackService.showError(errorMessage);
				}
				this.userFeedbackService.stopSpinner();
			}
		).catch((err) => {
			this.userFeedbackService.showError(errorMessage);
			this.userFeedbackService.stopSpinner();
		});
	}
}
