<ng-container>
	<ng-container *ngIf="isOnline">
		<router-outlet></router-outlet>
	</ng-container>
	<ng-container *ngIf="!isOnline">
		<app-offline></app-offline>
	</ng-container>
	<app-notifications></app-notifications>
	<app-spinner></app-spinner>
	<app-mobile-update-modal></app-mobile-update-modal>
</ng-container>

