import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import {Subject} from 'rxjs/internal/Subject';

@Injectable({
	providedIn: 'root'
})

export class CheckForUpdateService {

	private triggerUpdateMessage = new Subject<any>();
	triggerUpdateMessage$ = this.triggerUpdateMessage.asObservable();

	constructor(public updates: SwUpdate) {
		updates.available.subscribe(event => {
			console.log('current version is', event.current);
			console.log('available version is', event.available);
		});

		updates.activated.subscribe(event => {
			console.log('old version was', event.previous);
			console.log('new version is', event.current);
		});

		if (updates.isEnabled) {
			console.log('Updates Enabled v5');
			interval(60000).subscribe(() => {
				updates.available.subscribe(event => {
					console.log(event);
					updates.activateUpdate().then(() => console.log('update applied'));
				});
				updates.checkForUpdate().then(() => console.log('checking for updates'));
			});
		}
	}

	public checkForUpdates(): void {
		this.updates.available.subscribe(event => this.promptUser());
	}

	private promptUser(): void {
		console.log('updating to new version');
		this.updates.activateUpdate().then(() => {
			console.log('Update Found');
			this.triggerUpdateMessage.next();
		});
	}
}
