<div class="row p-3">
	<div class="col-auto mx-auto">

		<div class="row text-center">
			<div class="col cover-page-width">

				<div class="row py-md-5">
					<div class="col-12 logo">
						<img class="img-fluid" src="../../assets/img/logo.jpg" />
					</div>
				</div>

				<div class="row text-center">
					<div class="col">
						<p class="text-primary fa-15x">{{ offlineMainMessage }}</p>
						<button class="btn-success btn-lg" (click)="backToLogin()">Back to Login</button>
						<p class="text-primary fa-15x">{{ offlineSubMessage }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
