import {Component} from '@angular/core';
import {NavigationService} from '../shared/providers/navigation.service';
import {Router} from '@angular/router';

@Component({
	selector: 'app-offline',
	templateUrl: './offline.component.html',
	styleUrls: ['./offline.component.scss']
})

export class OfflineComponent {
	offlineMainMessage = 'Oops!';
	offlineSubMessage = 'Looks like the Internet connection appears to be offline. In order to access Marketscape data, please connect to the Internet (and make sure airplane mode is off).';
	constructor(private navigationService: NavigationService) { }

	backToLogin() {
		this.navigationService.navigateToLogin();
		return;
	}
}
