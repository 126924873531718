import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import {ANALYTIC_ACTION, AnalyzePageTab, PORTAL_TYPE, PROVIDER_TYPE, ROUTE_CONSTANTS, MARKET_SHARE_REPORTS, LIST_TYPES, RouteAttr} from '../shared.constants';
import Utils from '../Utils';
import { ApplicationCacheService } from './application-cache.service';
import { LogService } from './log.service';
import { EnvService } from './env.service';
import { SSOService } from './sso.service';
import {MobileListService} from './mobile-list.service';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	urlStack = null;
	marketScape = '';

	get portal() {
		return this.applicationCacheService.portal;
	}

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private applicationCacheService: ApplicationCacheService,
		private logService: LogService,
		private environmentService: EnvService,
		private ssoService: SSOService,
		private mobileListService: MobileListService) { }

	async navigateToUnauthorized() {
		return await this.navigateToInternal([ROUTE_CONSTANTS.unauthorized.route]);
	}

	async navigateToChooseApp() {
		return await this.navigateToInternal([ROUTE_CONSTANTS.chooseApplication.route]);
	}

	async navigateToChangePassword(token: any) {
		return await this.navigateToInternalWithParams([ROUTE_CONSTANTS.passwordReset.route], token);
	}

	async navigateToLanding() {
		const { portals, userIsAdmin } = this.applicationCacheService.user;
		if (portals.length > 1 || userIsAdmin) {
			this.navigateToChooseApp();
		} else {
			this.applicationCacheService.setPortalType(portals[0]);
			this.logService.logLoginRequest(portals[0] as string).toPromise();
			// put in code to navigate to list but a specific tab

			// pull the information from the mobile service
			const populatedList = this.mobileListService.getPopulatedList(portals[0]);
			switch (populatedList) {
				case LIST_TYPES.favorites:
					this.navigateToFavoritesList();
					break;
				case LIST_TYPES.targets:
					this.navigateToTargetsList();
					break;
				case LIST_TYPES.assignments:
					this.navigateToAssigneeList();
					break;
				default:
					this.navigateToUrl(portals[0]);
					break;
			}
		}
	}

	async navigateToApp(...states: string[]) {
		return await this.navigateTo(null, this.portal, ...states);
	}

	async navigateToPortal(portal: PORTAL_TYPE, ...states: string[]) {
		if (portal === this.portal) {
			return;
		}
		return await this.navigateTo(null, portal, ...states);
	}

	/****************************************************
	 ********************* Analyze *********************
	 ****************************************************/

	buildAnalyzeTabRoute(tabInfo: AnalyzePageTab) {
		if (!tabInfo || !tabInfo.type || !tabInfo.id) {
			return '';
		}

		const providerRouteAttr = Utils.getRouteAttrByProviderType(tabInfo.type);
		if (!providerRouteAttr) {
			return '';
		}

		return `/${this.portal}/${ROUTE_CONSTANTS.analyze.route}/${providerRouteAttr.route}/${tabInfo.id}`;
	}

	async navigateToAnalyzeTab(tabInfo: AnalyzePageTab) {
		if (!tabInfo || !tabInfo.type || !tabInfo.id) {
			return await this.navigateToApp(ROUTE_CONSTANTS.analyze.route);
		}

		return await this.navigateToAnalyze(tabInfo.type, tabInfo.id);
	}

	async navigateToAnalyze(providerType?: PROVIDER_TYPE, id?: any) {
		if (!providerType || !id) {
			return await this.navigateToApp(ROUTE_CONSTANTS.analyze.route);
		}

		const routeAttr = Utils.getRouteAttrByProviderType(providerType);
		if (!routeAttr) {
			return await this.navigateToApp(ROUTE_CONSTANTS.analyze.route);
		}

		return await this.navigateToApp(ROUTE_CONSTANTS.analyze.route, routeAttr.route, id);
	}

	async navigateToAnalyzePrint(providerType?: PROVIDER_TYPE, id?: any) {
		if (!providerType || !id) {
			return this.navigateToApp(ROUTE_CONSTANTS.analyze.route);
		}

		const routeAttr = Utils.getRouteAttrByProviderType(providerType);
		if (!routeAttr) {
			return this.navigateToApp(ROUTE_CONSTANTS.analyze.route);
		}

		return await this.navigateToApp(ROUTE_CONSTANTS.analyze.route, routeAttr.route, ROUTE_CONSTANTS.print.route, id);
	}

	async navigateToNewAnalyzeTab() {
		return await this.navigateToApp(ROUTE_CONSTANTS.analyze.route, ROUTE_CONSTANTS.newRouteParam);
	}

	/****************************************************
	 ********************* Data Kits (Report) *********************
	****************************************************/
	async navigateToEula() {
		return await this.navigateToInternal([ROUTE_CONSTANTS.eula.route]);
	}

	async navigateToDataEula() {
		return await this.navigateToInternal([ROUTE_CONSTANTS.dataEula.route]);
	}


	/****************************************************
	 ********************* Mobile *********************
	 ****************************************************/
	async navigateToLists() {
		return await this.navigateToApp(ROUTE_CONSTANTS.lists.route);
	}

	async navigateToFavoritesList() {
		return await this.navigateToUrl(this.buildListLink(ROUTE_CONSTANTS.favorites.route));
	}

	async navigateToAssigneeList() {
		return await this.navigateToUrl(this.buildListLink(ROUTE_CONSTANTS.assignments.route));
	}

	async navigateToTargetsList() {
		return await this.navigateToUrl(this.buildListLink(ROUTE_CONSTANTS.targets.route));
	}

	buildListLink(route: string) {
		return `/${this.portal}/${ROUTE_CONSTANTS.lists.route}/${route}`;
	}
	/****************************************************
	 ********************* Common ***********************
	****************************************************/


	async navigateToLogin(returnUrl?: string, fromLogout: boolean = false, source: string = null) {

		const thisReturnUrl = returnUrl || this.applicationCacheService.getReturnUrl();
		const paramObj = {};
		paramObj[ROUTE_CONSTANTS.returnUrlLabel] = thisReturnUrl || '';
		if (!fromLogout) {
			return await this.ssoService.navigateToSSObySource(source);
		}
		return false;
	}

	async navigateToLogOut() {
		window.location.href = this.environmentService.cognitoUrl_logout;
	}

	async navigateAfterLogin() {
		const returnUrl = this.getReturnUrl();
		if (returnUrl)
			this.navigateToUrl(returnUrl);
		else
			this.navigateToChooseApp();
	}

	async navigateToSettings() {
		return await this.navigateToApp(ROUTE_CONSTANTS.settings.route);
	}

	initUrlStack() {
		if (this.urlStack !== null) {
			return;
		}

		this.urlStack = [];
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.urlStack.push(event.url);
				this.logService.logUsageRequest(event.url).toPromise();
			});
	}

	async navigateBack() {
		if (!this.urlStack.length) {
			return;
		}

		const thisUrl = this.urlStack.pop();
		const previousUrl = this.urlStack.pop();
		if (previousUrl) {
			const routeTo = this.getRouteArrayFromUrl(previousUrl);

			return await this.navigateToUrl(previousUrl);
		}
	}

	numSlashes(url: string) {
		return (url.match(/\//g) || []).length;
	}

	getRouteState(url: string) {
		const lastSlash = url.lastIndexOf('/');
		return url.substring(lastSlash + 1, url.length); // Route extracted from url
	}

	getRouteArrayFromUrl(url: string) {
		if (!url) {
			return [];
		}

		const urlArray = url.split('/');
		return urlArray.filter(x => x !== '');
	}

	async navigateTo(params?: object, ...states: string[]) {
		const statesArray = [];
		for (let i = 0; i < states.length; i++) {
			statesArray.push(states[i]);
		}

		return await this.navigateToInternal(statesArray, params);
	}

	async navigateToUrl(url) {
		return await this.router.navigateByUrl(url);
	}

	async navigateToInternal(relativePath?: Array<string>, params?: any): Promise<boolean> {
		const extras = params ? { queryParams: params } : {};

		if (!relativePath) {
			this.router.navigateByUrl('', extras);
			return;
		}

		const url = this.router.createUrlTree([...relativePath], extras);
		return await this.router.navigateByUrl(url);
	}

	async navigateToInternalWithParams(relativePath?: Array<string>, params?: any): Promise<boolean> {
		const extras = params ? { queryParams: params } : {};

		if (!relativePath) {
			this.router.navigateByUrl('', extras);
			return;
		}

		const url = this.router.createUrlTree([...relativePath], extras);
		return await this.router.navigateByUrl(url);
	}

	navigateToExternal(url: string) {
		window.open(url);
	}

	setReturnUrl(url: string) {
		if (url)
			localStorage.setItem('returnurl', url);
		else
			localStorage.removeItem('returnurl');

	}

	getReturnUrl() {
		return localStorage.getItem('returnurl');
	}
}
