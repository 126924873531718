import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import {NgModule, ErrorHandler, Injector} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { LandingComponent } from './landing/landing.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';
import { ChooseApplicationComponent } from './choose-application/choose-application.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SharedModule } from './shared/shared.module';
import 'hammerjs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { RedirectComponent } from './redirect/redirect.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { UnauthorizedAccessComponent } from './unauthorized-access/unauthorized-access.component';
import { EulaComponent } from './eula/eula.component';
import { DataEulaComponent } from './data-eula/data-eula.component';
import { EnvServiceProvider } from './shared/providers/env.service.provider';
import { authInterceptorProviders } from './api/jwt-interceptor';
import { GlobalErrorHandler } from './shared/global-error-handler';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ComponentsModule } from '@excelhealth/shared-components';
import Amplify from 'aws-amplify';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LOCAL_STORAGE_KEY } from './shared/shared.constants';
import { ServiceWorkerModule } from '@angular/service-worker';
import {OfflineComponent} from './offline/offline.component';
import {PageErrorComponent} from './page-error/page-error.component';

/* Configure Amplify resources */
const cfg = window['__env']['awsconfig'];
try {
	const clientId = localStorage.getItem(LOCAL_STORAGE_KEY.SSO_CLIENTID);
	if (clientId) {
		cfg.aws_user_pools_web_client_id = clientId;
		cfg.oauth.cognitoClientId = clientId;
		const authurl = localStorage.getItem(LOCAL_STORAGE_KEY.SSO_AUTH);
		if (authurl) {
			cfg.oauth.domain = authurl;
		}
	}
} catch {}

Amplify.configure(cfg);

@NgModule({
	imports: [
		HttpClientModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		SharedModule.forRoot(SharedModule),
		ChartsModule,
		ReactiveFormsModule,
		DialogsModule,
		HighchartsChartModule,
		DropDownListModule,
		DropDownsModule,
		MatFormFieldModule,
		MatSelectModule,
		ComponentsModule,
		FormsModule,
		MatInputModule,
		MatSelectModule,
		NgbModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: true, scope: './' })
	],

	declarations: [
		AppComponent,
		LoginComponent,
		LogoutComponent,
		LandingComponent,
		OfflineComponent,
		PageNotFoundComponent,
		ChooseApplicationComponent,
		RedirectComponent,
		PasswordResetComponent,
		UnauthorizedAccessComponent,
		EulaComponent,
		PageErrorComponent,
		DataEulaComponent
	],

	providers: [
		EnvServiceProvider,
		authInterceptorProviders,
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		NgbActiveModal,
	],
	bootstrap: [AppComponent]
})

export class AppModule {
	constructor() { }
}
