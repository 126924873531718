import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { NavigationService } from './navigation.service';
import { ApplicationCacheService } from './application-cache.service';

@Injectable({
	providedIn: 'root'
})
export class EulaGuard implements CanActivate {
	constructor(private authService: AuthenticationService, private appCache: ApplicationCacheService, private navigationService: NavigationService) { }

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		let user = this.appCache.user;
		if (!user) {
			this.appCache.setReturnUrl(state.url && !state.url.includes('login') ? state.url : null);
			const response = await this.authService.getCurrentUser();
			user = response.user;
		}

		if (user.showEula) {
			this.appCache.setReturnUrl(state.url && !state.url.includes('login') ? state.url : null);
			this.navigationService.navigateToEula();
			return false;
		}

		if (user.showDataEula) {
			this.appCache.setReturnUrl(state.url && !state.url.includes('login') ? state.url : null);
			this.navigationService.navigateToDataEula();
			return false;
		}
        return true;
	}
}
