import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ApplicationCacheService } from '../shared/providers/application-cache.service';
import { FavoriteResponse } from '../shared/models/favorite';
import { FavoritesTopXRequest, AddFavoritesRequest } from '../shared/providers/favorites.service';
import { ProviderTypeArray, PROVIDER_TYPE } from '../shared/shared.constants';

@Injectable({
	providedIn: 'root'
})
export class FavoritesApi {

	get portal() {
		return this.applicationCacheService.portal;
	}

	get controllerName() {
		return `${this.portal}/Favorites`;
	}

	constructor(private api: ApiService, private applicationCacheService: ApplicationCacheService) { }

	getAll(): Observable<FavoriteResponse> {
		return this.api.get(this.controllerName);
	}

	getPortalFavorites(portal: string): Observable<FavoriteResponse> {
		return this.api.get(`${portal}/Favorites`);
	}

	addFavorites(request: AddFavoritesRequest): Observable<FavoriteResponse> {
		return this.api.post(this.controllerName, null, request);
	}

	addFavoritesTopX(request: FavoritesTopXRequest): Observable<FavoriteResponse> {
		return this.api.post(this.controllerName, 'addtopx', request);
	}

	deleteFavoritesTopX(request: FavoritesTopXRequest): Observable<FavoriteResponse> {
		return this.api.post(this.controllerName, 'deletetopx', request);
	}

	deleteFavorites(npis: string[], providerType: string): Observable<FavoriteResponse> {
		return this.api.post(this.controllerName, 'delete', {
			providerType: providerType,
			npis: npis
		});
	}

	clearFavorites(providerType: PROVIDER_TYPE): Observable<boolean> {
		return this.api.patch(this.controllerName, `clear/${providerType}`);
	}
}
