import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../shared/providers/navigation.service';
import { UserFeedbackService } from '../shared/providers/user-feedback.service';

@Component({
	selector: 'app-unauthorized-access',
	templateUrl: './unauthorized-access.component.html',
	styleUrls: ['../../styles/components/cover-page.scss', './unauthorized-access.component.scss']
})
export class UnauthorizedAccessComponent implements OnInit {

	constructor(private navigationService: NavigationService, private userFeedbackService: UserFeedbackService) { }

	ngOnInit(): void {
		this.userFeedbackService.stopSpinner(); // Let the page components stop the spinners instead of the subscriptions to prevent flickering spinners -AR
	}
	goBack() {
		return this.navigationService.navigateBack();
	}
}
