import { Component } from '@angular/core';
import { DEFAULT_ERROR_ADDONS, USER_MESSAGES } from 'src/app/shared/shared.constants';
import { TrellaError } from '../../shared/models/trella-error';

@Component({
	selector: 'app-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
	message = '';

	success = false;
	error = false;
	warning = false;
	_slideOutUp = false;
	addOnMessage = '';
	timeStamp: number;
	showTimeStamp = false;

	get showAlert() {
		return this.success || this.error || this.warning;
	}

	get slideOutUp() {
		return this._slideOutUp;
	}

	showCustomSuccessMessage(object: string) {
		this.showSuccess(`${object}`);
	}

	showCustomWarningMessage(object: string) {
		this.showWarning(`${object}`);
	}

	showMoveSuccess(object: string) {
		this.showSuccess(`${object} moved successfully!`);
	}

	showSaveSucess(object: string) {
		this.showSuccess(`${object} saved successfully!`);
	}

	showCreateSucess(object: string) {
		this.showSuccess(`${object} created successfully!`);
	}

	showDeleteSucess(object: string) {
		this.showSuccess(`${object} deleted successfully!`);
	}

	showClearSuccess(object: string) {
		this.showSuccess(`${object} successfully cleared!`);
	}

	showSaveError(object: string) {
		this.showWarning(`Error saving ${object}. Please try again.`);
	}

	showUnexpectedError() {
		this.showWarning(USER_MESSAGES.default);
	}

	showSuccess(message) {
		if (!message) {
			return;
		}

		this.reset();
		this.message = message;
		this.success = true;
		this.autoReset();
	}

	showWarning(message) {
		if (!message) {
			return;
		}

		this.reset();
		this.message = message;
		this.warning = true;
		this.autoReset();
	}

	showError(message) {
		if (!message) {
			return;
		}

		this.reset();
		this.message = message;
		this.error = true;
		this.autoReset(10);
	}

	showCustomError(error: any) {
		if (error instanceof TrellaError ) {
			this.showTrellaError(error);
			return;
		}
		this.showError(error);
	}

	showTrellaError(error: TrellaError) {
		if (!error.message) {
			return;
		}

		let addonMessage = '';
		const addonError = DEFAULT_ERROR_ADDONS.find(x => x.key == error.frontEndMessageId);
		if (addonError) {
			addonMessage = addonError.message;
		}

		this.reset();
		this.message = error.message;
		this.error = true;
		this.addOnMessage = addonMessage;
		this.timeStamp = error && error.timeStamp;
		this.showTimeStamp = error.displayTimeStamp;
		this.autoReset(30);
	}

	reset() {
		this.message = '';
		this.success = false;
		this.error = false;
		this.warning = false;
		this._slideOutUp = false;
	}

	autoReset(seconds?: number) {
		if (seconds) {
			setTimeout(() => this.toggle(), seconds * 1000);
		}
		else {
			setTimeout(() => this.toggle(), 5000); // 5 seconds as default
		}
	}

	toggle() {
		this._slideOutUp = true;
		setTimeout(() => this.reset(), 3000);
	}
}
