import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError } from '../../../node_modules/rxjs/operators';
import { EnvService } from '../shared/providers/env.service';

export interface ExcelHttpResult {
	success: boolean;
	result: any;
	errorMsg: string;
}

export interface HttpOptions {
	skipStringify?: boolean;
	headers?: {
		[name: string]: any
	};
	responseType?: any;
	withCrendetials?: boolean;
	skipExtractData?: boolean;
}

interface HttpClientOptions {
	headers?: HttpHeaders | {
		[header: string]: string | string[];
	};
	params?: HttpParams | {
		[param: string]: string | string[];
	};
	reportProgress?: boolean;
	withCredentials?: boolean;
	responseType?: any;
}

@Injectable({
	providedIn: 'root'
})
export class HttpRequest {

	standardRequestHeaders = [
		{ key: 'Cache-Control', value: 'private' },
		{ key: 'Content-Type', value: 'application/json' },
	];

	overrideRequestHeaders = [
		{ key: 'X-HTTP-Method-Override', value: 'GET' },
	];

	constructor(private http: HttpClient, private environmentService: EnvService) {
		this.handleError = this.handleError.bind(this);
	}

	get(url: string, getParams?: any, useHttpOverride?: boolean): Observable<any> {

		if (useHttpOverride) {
			// We want to send this in as a POST request wth a body, but let the server know this is actually a get
			return this.getWithHttpOverride(url, getParams);
		}

		let headers = new HttpHeaders();

		this.standardRequestHeaders.forEach((value) => {
			headers = headers.set(value.key, value.value);
		});

		if (!getParams || isEmpty(getParams)) {
			return this.wrapObservable(this.http.get(url, {
				headers: headers,
				withCredentials: true
			}));
		}

		let params: HttpParams = new HttpParams();
		for (const key in getParams) {
			if (getParams.hasOwnProperty(key)) {
				params = params.set(key, getParams[key]);
			}
		}

		return this.wrapObservable(this.http.get(url, {
			headers: headers,
			params: params,
			withCredentials: true
		}));
	}

	private getWithHttpOverride(url: string, getParams?: any) {
		let headers = new HttpHeaders();

		const allHeaders = this.standardRequestHeaders.concat(this.overrideRequestHeaders);
		allHeaders.forEach((value) => {
			headers = headers.set(value.key, value.value);
		});

		const clientOptions = this.getClientOptions();
		clientOptions.headers = headers;

		return this.wrapObservable(this.http.post(url, getParams, clientOptions));
	}

	post(url: string, postParams?: any, options?: HttpOptions): Observable<any> {
		const params = options && options.skipStringify ? postParams : JSON.stringify(postParams);
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.post(url, params, clientOptions));
	}

	patch(url: string, postParams?: any, options?: HttpOptions): Observable<any> {
		const params = options && options.skipStringify ? postParams : JSON.stringify(postParams);
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.patch(url, params, clientOptions));
	}

	put(url: string, postParams?: any, options?: HttpOptions): Observable<any> {
		const params = options && options.skipStringify ? postParams : JSON.stringify(postParams);
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.put(url, params, clientOptions));
	}

	delete(url: string, options?: HttpOptions): Observable<any> {
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.delete(url, clientOptions));
	}

	private getClientOptions(options?: HttpOptions): HttpClientOptions {
		const props: HttpClientOptions = {
			withCredentials: true
		};

		let headers = new HttpHeaders();

		this.standardRequestHeaders.forEach((value) => {
			headers = headers.set(value.key, value.value);
		});

		if (options && options.headers) {
			Object.keys(options.headers).forEach(k => {
				headers = headers.set(k, options.headers[k]);
			});
		}
		props.headers = headers;

		if (options && (options.responseType || options.responseType === 0)) {
			props.responseType = options.responseType;
		}

		return props;
	}

	getUrl(baseUrl: string, route: string) {
		let url = this.environmentService.apiUrl;
		if (baseUrl) {
			url += `/${baseUrl}`;
		}

		if (route) {
			url += `/${route}`;
		}

		return url;
	}

	private wrapObservable(observable: Observable<any>, skipExtract: boolean = false): Observable<any> {
		return skipExtract
			? observable
			: observable.pipe(
				catchError(this.handleError)
			);
	}

	private handleError(error: HttpErrorResponse) {
		if (error?.error?.detail) {
			const problemDetails = typeof (error.error.detail) !== 'object' ? { detail: error.error.detail } : JSON.parse(error.error.detail);
			return throwError(problemDetails?.detail ?? 'An unexpected error has occurred.');
		}

		return throwError(error.message);
	}
}
