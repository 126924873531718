import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NpiInfo, Assignee, NpiSearchRequest, NpiSearchOption, Staff, NpiVerifyRequest, AssigneeApiRequest, AssigneeResponse } from '../shared/models/npi';
import { ApplicationCacheService } from '../shared/providers/application-cache.service';
import { ApiService } from './api.service';
import Utils from '../shared/Utils';
import { AssigneesTopXRequest } from '../shared/providers/assignees.service';
import {NpiSearchFilters} from '../components/npi-search/npi-search-filters';

@Injectable({
	providedIn: 'root'
})
export class NpiApi {

	get controllerName() {
		return `${this.appCacheService.portal}/Npi`;
	}

	get assigneeControllerName() {
		return `${this.appCacheService.portal}/Assignee`;
	}

	constructor(private api: ApiService, protected http: HttpClient,
		private appCacheService: ApplicationCacheService) { }

	getNpiInfo(npi: string, providertype: string = null): Observable<NpiInfo> {
		const routeParams = providertype ? `${npi}?providertype=${providertype}` : `${npi}`;
		return this.api.get(this.controllerName, routeParams);
	}

	getNpiInfoWithProvider(npi: string): Observable<NpiInfo> {
		return this.api.get(this.controllerName, npi);
	}

	getNpiAssignee(npi: string, provider: string): Observable<Assignee> {
		return this.api.get(this.assigneeControllerName, npi + '?provider=' + provider);
	}

	saveNpiInfo(npiInfo: NpiInfo): Observable<boolean> {
		return this.api.post(this.controllerName, null, npiInfo);
	}

	saveNpiAssignee(assignee: AssigneeApiRequest): Observable<AssigneeResponse> {
		return this.api.post(this.assigneeControllerName, null, assignee);
	}

	addAssigneesTopX(request: AssigneesTopXRequest): Observable<AssigneeResponse> {
		return this.api.post(this.assigneeControllerName, 'addtopx', request);
	}

	deleteAssigneesTopX(request: AssigneesTopXRequest): Observable<AssigneeResponse> {
		return this.api.post(this.assigneeControllerName, 'removetopx', request);
	}

	deleteNpiAssignees(request: AssigneeApiRequest): Observable<AssigneeResponse> {
		return this.api.post(this.assigneeControllerName, 'remove', request);
	}

	getNpiStaff(id: number): Observable<Assignee> {
		return this.api.get(this.controllerName + '/staff', id.toString());
	}

	saveNpiStaff(staff: Staff): Observable<boolean> {
		return this.api.post(this.controllerName + '/staff', null, staff);
	}

	deleteNpiStaff(id: number): Observable<boolean> {
		return this.api.delete(this.controllerName + '/staff', id.toString());
	}

	search(npiSearchRequest: NpiSearchRequest): Observable<NpiSearchOption[]> {
		const params = Utils.encodeQueryParam(npiSearchRequest);
		return this.api.get(this.controllerName, 'Search?' + params);
	}

	postSearch(npiSearchRequest: NpiSearchRequest, npiSearchFilters: NpiSearchFilters): Observable<NpiSearchOption[]> {
		const params = Utils.encodeQueryParam(npiSearchRequest);
		return this.api.post(this.controllerName, 'Search?' + params, npiSearchFilters);
	}

	verify(npiVerifyRequest: NpiVerifyRequest): Observable<NpiSearchOption[]> {
		const params = Utils.encodeQueryParam(npiVerifyRequest);
		return this.api.get(this.controllerName, 'Verify?' + params);
	}
}
