import {Component, OnInit} from '@angular/core';
import {ComponentWithSubscription} from '../shared/component-with-subscriptions';
import {ApplicationCacheService} from '../shared/providers/application-cache.service';
import {NavigationService} from '../shared/providers/navigation.service';
import {LIST_TYPES, PORTAL_TYPE} from '../shared/shared.constants';
import {LogService} from '../shared/providers/log.service';
import {MobileListService} from '../shared/providers/mobile-list.service';

@Component({
	selector: 'choose-application',
	templateUrl: './choose-application.component.html',
	styleUrls: ['../../styles/components/cover-page.scss', './choose-application.component.scss']
})
export class ChooseApplicationComponent extends ComponentWithSubscription implements OnInit {

	portals: Array<string>;

	portalDisplayNameMap = {
		hha: { name: 'Home Health', icon: '../assets/icons/home-heart.svg' },
		hos: { name: 'Hospice', icon: '../assets/icons/hands-heart.svg' },
		snf: { name: 'Skilled Nursing', icon: '../assets/icons/user-nurse.svg' },
	};

	get userName() {
		return this.applicationCacheService.user && this.applicationCacheService.user.name;
	}

	get hasPortals() {
		return this.portals && this.portals.length;
	}

	constructor(private navigationService: NavigationService,
		private applicationCacheService: ApplicationCacheService,
		private logService: LogService,
		private mobileListService: MobileListService) {
		super();
	}

	ngOnInit() {
		const portals = this.applicationCacheService
			.user
			.userAccess
			.getPortals();
		// remove all portals we do not have icons fors
		const allowedPortals = Object.keys(this.portalDisplayNameMap).map(singlePortal => singlePortal.toLowerCase());
		this.portals = portals.filter(x => allowedPortals.includes(x.toLowerCase()));
	}

	navigateToPortal(portal) {
		this.logService.logLoginRequest(portal).toPromise();
		this.applicationCacheService.setPortalType(portal);

		const populatedList = this.mobileListService.getPopulatedList(portal);

		switch (populatedList) {
			case LIST_TYPES.favorites:
				this.navigationService.navigateToFavoritesList();
				break;
			case LIST_TYPES.targets:
				this.navigationService.navigateToTargetsList();
				break;
			case LIST_TYPES.assignments:
				this.navigationService.navigateToAssigneeList();
				break;
			default:
				this.navigationService.navigateToUrl(portal);
				break;
		}
	}

	getDisplayName(portal: string): string {
		const displayObject = this.portalDisplayNameMap[portal] || { name: portal, icon: ''};
		return displayObject.name;
	}

	getDisplayIcon(portal: string): string {
		const displayObject = this.portalDisplayNameMap[portal] || { name: portal, icon: ''};
		return displayObject.icon;
	}

	appColor(portal: string) {
		return {
			'btn-brand-4': portal === PORTAL_TYPE.HHA,
			'btn-brand-6': portal === PORTAL_TYPE.HOS,
			'btn-brand-2': portal === PORTAL_TYPE.SNF,
			'btn-brand-8': portal === PORTAL_TYPE.ADMIN
		};
	}
}
