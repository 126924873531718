import { NgModule, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChooseApplicationComponent } from './choose-application/choose-application.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { LandingComponent } from './landing/landing.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RedirectComponent } from './redirect/redirect.component';
import { AuthGuard } from './shared/providers/auth.guard';
import { HhaGuard } from './shared/providers/hha.guard';
import { HosGuard } from './shared/providers/hos.guard';
import { ROUTE_CONSTANTS } from './shared/shared.constants';
import { UnauthorizedAccessComponent } from './unauthorized-access/unauthorized-access.component';
import { EulaGuard } from './shared/providers/eula.guard';
import { EulaComponent } from './eula/eula.component';
import { DataEulaComponent } from './data-eula/data-eula.component';
import { LoginGuard } from './shared/providers/login.guard';
import { SnfGuard } from './shared/providers/snf.guard';
import {PageErrorComponent} from './page-error/page-error.component';
import {PasswordResetGuard} from './shared/providers/password-reset.guard';

const winAny: any = window;

export const AppRootRoutes = [
	{ path: ROUTE_CONSTANTS.login.route, component: LoginComponent, canActivate: [LoginGuard] },
	{ path: ROUTE_CONSTANTS.login.route + '/:source', component: LoginComponent, canActivate: [AuthGuard] },
	{ path: ROUTE_CONSTANTS.logout.route, component: LogoutComponent },
	{ path: ROUTE_CONSTANTS.landing.route, component: LandingComponent },
	{ path: ROUTE_CONSTANTS.error.route, component: PageErrorComponent },
	{ path: ROUTE_CONSTANTS.passwordReset.route, component: PasswordResetComponent, canActivate: [PasswordResetGuard] },
	{ path: ROUTE_CONSTANTS.redirect.route, component: RedirectComponent },
	{ path: ROUTE_CONSTANTS.unauthorized.route, component: UnauthorizedAccessComponent },

	{
		path: ROUTE_CONSTANTS.eula.route,
		component: EulaComponent,
		canActivate: [AuthGuard]
	},
	{
		path: ROUTE_CONSTANTS.dataEula.route,
		component: DataEulaComponent,
		canActivate: [AuthGuard]
	},
	{
		path: ROUTE_CONSTANTS.chooseApplication.route,
		component: ChooseApplicationComponent,
		canActivate: [AuthGuard, EulaGuard]
	},
	{
		path: ROUTE_CONSTANTS.hos.route,
		canActivate: [HosGuard, EulaGuard],
		loadChildren: () => import('./main/main.module').then(m => m.MainModule)
	},
	{
		path: ROUTE_CONSTANTS.hha.route,
		canActivate: [HhaGuard, EulaGuard],
		loadChildren: () => import('./main/main.module').then(m => m.MainModule)
	},
	{
		path: ROUTE_CONSTANTS.snf.route,
		canActivate: [SnfGuard, EulaGuard],
		loadChildren: () => import('./main/main.module').then(m => m.MainModule)
	},
	{ path: '', redirectTo: ROUTE_CONSTANTS.chooseApplication.route, pathMatch: 'full' },
	{ path: '**', component: PageNotFoundComponent }
];


@NgModule({
	imports: [
		RouterModule.forRoot(AppRootRoutes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
