import { Component, OnInit, ViewChild } from '@angular/core';
import { UserApi } from '../api/user.api';
import { ComponentWithSubscription } from '../shared/component-with-subscriptions';
import { ApplicationCacheService } from '../shared/providers/application-cache.service';
import { NavigationService } from '../shared/providers/navigation.service';
import { UserFeedbackService } from '../shared/providers/user-feedback.service';
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';

@Component({
	selector: 'data-eula-page',
	templateUrl: './data-eula.component.html',
	styleUrls: ['./data-eula.component.scss']
})
export class DataEulaComponent extends ComponentWithSubscription implements OnInit {
	@ViewChild(AlertDialogComponent, { static: true }) alertDialog: AlertDialogComponent;

	constructor(private navigationService: NavigationService,
				private applicationCacheService: ApplicationCacheService,
				private userApi: UserApi,
				private userFeedbackService: UserFeedbackService) {
		super();
	}

	ngOnInit() {
	}

	async acceptEula() {
		await this.updateEula(true);
		this.applicationCacheService.user.showDataEula = false;
		this.navigationService.navigateAfterLogin();
	}

	async showConfirm(){
		this.alertDialog.open('Are you sure you want to decline?', 'You will not be able to export any data without accepting this agreement.', 'I Decline');
	}

	async onDecline() {
		const newPermissins = await this.updateEula(false);
		this.applicationCacheService.user.setPermissions(newPermissins);
		this.applicationCacheService.user.showDataEula = false;
		this.navigationService.navigateAfterLogin();
	}

	async updateEula(choice: boolean): Promise<string[]>{
		this.userFeedbackService.startSpinner();
		try{
			return await this.userApi.updateDataEula(choice).toPromise();
		} catch(ex) {
			this.userFeedbackService.showCustomError(ex);
		}
		finally {
			this.userFeedbackService.stopSpinner()
		}
	}



}
