import { Injectable } from '@angular/core';
import { ApplicationCacheService } from './application-cache.service';

@Injectable({
	providedIn: 'root'
})
export class PermissionsService {
    constructor(private appCache: ApplicationCacheService) {
     }
    
    /**
     * @param permissions The permissions to verify the user has
     * 
     * @return - true if the user has permission, else false
     */
    public hasPermissions(...permissions: string[]): boolean {
        const userPermissions = this.appCache.user.permissions.map(p => p.toLowerCase())
        return permissions.every(permission => permission && userPermissions.includes(permission.toLowerCase()));
    }
}
