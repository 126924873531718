<kendo-dialog [class.onTop]='forceOnTop' *ngIf="opened" (close)="close()">
	<kendo-dialog-titlebar>
		<div class="d-flex flex-row align-items-center text-white" *ngIf="title">
			<div class="text-heading"> {{title}} </div>
		</div>
	</kendo-dialog-titlebar>

	<div class="row no-gutters p-4 no-gutters">
		<div class="col" *ngIf="message">
			<div class="text-body" [class.loading]='loadingAnimation' [innerHTML]="message"></div>
		</div>
	</div>

	<div *ngIf="!hideOkButton" class="row no-gutters pt-3">
		<div class="col"></div>
		<div class="col-auto">
			<button type="button" class="btn btn-success" (click)="ok()">{{okButtonLabel}}</button>
		</div>
	</div>

</kendo-dialog>
