import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {PageLogRequest} from '../shared/models/log';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LogApi {
	get controllerName() {
		return `Log`;
	}

	constructor(private api: ApiService, protected http: HttpClient) { }

	savePageLog(logRequest: PageLogRequest): Observable<true> {
		return this.api.post(this.controllerName, null, logRequest);
	}
}
