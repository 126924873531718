import {Injectable} from '@angular/core';
import {PROVIDER_TYPE} from '../shared.constants';
import {Observable, of} from 'rxjs';
import {ApplicationCacheService} from './application-cache.service';

@Injectable({
	providedIn: 'root'
})

export class RecentService {
	cachedNpis = {};
	maxCachedNpis = 20;

	get portal() {
		return this._appCacheService.portal;
	}

	constructor(private _appCacheService: ApplicationCacheService) {}

	saveNewItem(npi: string, name: string, specialty: string, provider: PROVIDER_TYPE) {
		const portalArray = this.cachedNpis[this.portal] || [];
		// check for duplicates
		const foundIndex = portalArray.findIndex(x => x.npi === npi);
		if (foundIndex > -1) { portalArray.splice(foundIndex, 1); }

		// move the item to the top of the list per product
		portalArray.unshift({npi: npi, name: name, specialty: specialty, providerType: provider});
		if (portalArray.length > this.maxCachedNpis) {
			portalArray.pop();
		}
		this.cachedNpis[this.portal] = portalArray;
	}

	getRecentItems(): Observable<any> {
		const portalArray = this.cachedNpis[this.portal] || [];
		return of({data: portalArray});
	}

	getRecentNpis() {
		return this.cachedNpis[this.portal] || [];
	}
}
