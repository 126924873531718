import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {ApplicationCacheService} from '../shared/providers/application-cache.service';
import {Observable} from 'rxjs';
import {AssignedNpi} from '../shared/models/assigned-npi';
import {FavoriteResponse} from '../shared/models/favorite';
import {PORTAL_TYPE} from '../shared/shared.constants';

@Injectable({
	providedIn: 'root'
})
export class AssigneeApi {

	get portal() {
		return this.applicationCacheService.portal;
	}

	get controllerName() {
		return `${this.portal}/Assignee`;
	}

	constructor(private api: ApiService, private applicationCacheService: ApplicationCacheService) { }

	getAll(portal: PORTAL_TYPE): Observable<AssignedNpi[]> {
		const controllerName = portal ? `${portal}/Assignee` : this.controllerName;
		return this.api.get(controllerName);
	}

	getAssignedNpis(): Observable<FavoriteResponse> {
		return this.api.get(this.controllerName);
	}
}
