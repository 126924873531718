import {Component} from '@angular/core';

@Component({
	selector: 'app-mobile-update-modal',
	templateUrl: './mobile-update-modal.component.html',
	styleUrls: ['./mobile-update-modal.component.scss']
})

export class MobileUpdateModalComponent {
	height = '200px';
	opened = false;

	async open() {
		this.opened = true;
	}

	cancel() {
		document.location.reload();
		this.close();
	}

	close() {
		document.location.reload();
		this.opened = false;
	}
}
