import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginResponse } from '../shared/models/authentication';
import { CognitoClient } from '../shared/providers/sso.service';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class AuthApi {

	get controllerName() {
		return 'Authentication';
	}

	constructor(private api: ApiService, protected http: HttpClient) { }

	loginCognito(token: string): Observable<LoginResponse> {
		return this.api.post(this.controllerName, 'LoginCognito', {
			jwtToken: token,
			oneDayExpiry: true
		});
	}

	identifyClient(email): Observable<CognitoClient> {
		return this.api.post(this.controllerName, 'IdentifyClient', {
			email: email
		});
	}

	logout(): Observable<any> {
		return this.api.post(this.controllerName, 'Logout');
	}

	getCognitoClient(source: string): Observable<CognitoClient>{
		return this.api.get(this.controllerName, 'CognitoClientBySource', {
			source: source
		});
	}

	getDefaultCognitoClient(): Observable<CognitoClient> {
		return this.api.get(this.controllerName, 'DefaultCognitoClient');
	}
}
