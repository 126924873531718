<div class="row p-3">
	<div class="col-auto mx-auto">

		<div class="row">
			<div class="col cover-page-width">

				<div class="row py-md-5">
					<div class="col-auto logo ">
						<img class="img-fluid" src="../../assets/img/logo.jpg" />
					</div>
				</div>

				<div class="row text-center">
					<div class="col">
						<p class="text-primary fa-15x">Sorry, we could not find the page you were looking for.</p>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
