import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentUserResponse, ResetPasswordResponse, UserListDetail } from '../shared/models/user';
import { ApiService } from './api.service';
import { ExcelHttpResult } from './http-request';
import Utils from '../shared/Utils';
import { ApplicationCacheService } from '../shared/providers/application-cache.service';
import { ReplaceValueMappingService } from '@excelhealth/shared-components';

@Injectable({
	providedIn: 'root'
})
export class UserApi {
	constructor(private api: ApiService , protected http: HttpClient, protected applicationCache: ApplicationCacheService, private replacementValueService: ReplaceValueMappingService) { }

	get controllerName() {
		return `User`;
	}

	get eulaController() {
		return `Eula`;
	}

	saveLoginTimestamp(): Observable<any> {
		return this.api.post(this.controllerName, 'SaveLoginTimestamp');
	}

	getCompanyUserList(isUserAssignedList = false): Observable<UserListDetail[]> {
		const userAssignedString = isUserAssignedList ? `?isuserassignedlist=true` : '';
		return this.api.get(`${this.controllerName}${userAssignedString}`);
	}

	getCurrent(): Observable<CurrentUserResponse> {
		return this.api.get(this.controllerName, 'Current').pipe(map((result: CurrentUserResponse) => {
			 	this.setUtilsLoadDate(result);
			return result;
		}));
	}

	requestPasswordResetToken(username): Observable<ResetPasswordResponse> {
		return this.api.post(this.controllerName, 'ResetPassword', {
			username: username
		});
	}

	updatePassword(newPassword, repeatPassword): Observable<ExcelHttpResult> {
		return this.api.patch(this.controllerName, 'UpdatePassword', {
			newPassword: newPassword,
			repeatPassword: repeatPassword
		});
	}

	validPasswordResetToken(token): Observable<boolean> {
		return this.api.get(this.controllerName, 'ValidToken', {
			token: token
		});
	}

	updateEula(choice: boolean) {
		return this.api.post(this.eulaController, null, {
			choice
		});
	}

	updateDataEula(choice: boolean) {
		return this.api.post(this.eulaController, 'data', {
			choice
		});
	}

	/**
	* Logs an event on the api
	* @param message the message to log
	*/
	logEvent(message: string): Observable<boolean> {
		return this.api.post(this.controllerName, 'Log', {
			message
		});
	}

	private setUtilsLoadDate(result: CurrentUserResponse) {
		const currentYear = this.GetCurrentYearFromResponse(result);

		this.applicationCache.currentYear = currentYear;
		this.applicationCache.currentMAYr = result.configOptions.currentMAYr;
		this.applicationCache.aco_new_year = result.configOptions.acoNewYear;
		this.applicationCache.psdCurrentQ = result.configOptions.psdCurrentQ;
		Utils.currentQuarter = result.configOptions.currentQuarter;

		const map = this.getReplacementValueMap(result);
		this.replacementValueService.setMap(map);
	}

	private getReplacementValueMap(userResponse: CurrentUserResponse): { [key: string]: string; } {
		const currentYear = this.GetCurrentYearFromResponse(userResponse);

		const currentYearInt = parseInt(currentYear, 10);
		const previousYear = currentYearInt - 1;

		const currentMAYear = parseInt(this.applicationCache.currentMAYr, 10);
		const mapreviousyear = currentMAYear - 1;
		const world = '';
		const aco_new_year = this.applicationCache.aco_new_year;

		const map = {...userResponse.configOptions, currentYear, previousYear, mapreviousyear, world, aco_new_year} as any as { [key: string]: string; };
		return map;
	}

	private GetCurrentYearFromResponse(result: CurrentUserResponse): string {
		const currentPeriod = result.configOptions.currentQuarter;
		const pieces = currentPeriod && currentPeriod.split('-');
		const currentYear = pieces && pieces.length === 2 && pieces[0];
		return currentYear;
	}
}
