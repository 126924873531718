import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auth } from 'aws-amplify';
import { ComponentWithSubscription } from '../shared/component-with-subscriptions';
import { ApplicationCacheService } from '../shared/providers/application-cache.service';
import { AuthenticationService } from '../shared/providers/authentication.service';
import { USER_MESSAGES } from '../shared/shared.constants';
import { NavigationService } from '../shared/providers/navigation.service';
import { LogService } from '../shared/providers/log.service';
import { LoginResponse } from '../shared/models/authentication';
import { take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserApi } from '../api/user.api';

@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss']
})
export class LandingComponent extends ComponentWithSubscription implements OnInit {
	landingMessage = USER_MESSAGES.processing_requesting;
	landingSubMessage = '';
	
	// This may be temporary until Product determines whether to include link
	requestAccessUrl = 'https://hhasupport.trellahealth.com/hc/en-us';
	userNotFoundError = 'invalid';

	constructor(private route: ActivatedRoute, private navigationService: NavigationService,
		private applicationCacheService: ApplicationCacheService,
		private authenticationService: AuthenticationService, private logService: LogService, private userApi: UserApi) {
		super();
	}

	ngOnInit() {
		Auth.currentAuthenticatedUser({bypassCache: true}).then(async user => {
			if (user) {
				this.userApi.saveLoginTimestamp().pipe(take(1)).subscribe();
				this.navigationService.navigateAfterLogin();
			}
			else
				throw new Error('Unauthorized: SSO authentication failed.');
		}).catch(err => {
			this.landingMessage = USER_MESSAGES.authentication_failed;
			this.landingSubMessage = err;
		});
	}
}
