import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { LOCAL_STORAGE_KEY, ROUTE_CONSTANTS } from '../shared.constants';
import { EnvService } from './env.service';
import { AuthApi } from 'src/app/api/auth.api';

@Injectable({
	providedIn: 'root'
})
export class SSOService {
	defaultSource = 'default';
	sourceParam = 'source';

	cognitoClient: CognitoClient;

	constructor(private environmentService: EnvService, private authApi: AuthApi, private router: Router) { }

	async navigateToSSObySource(sourceToken: string = null) {
		if (sourceToken) {
			await this.getCognitoClientBySource(sourceToken);
			if (this.cognitoClient) {
				return await this.navigateToSSO();
			} else {
				return await this.navigateToError();
			}
		} else {
			return await this.navigateToLogin();
		}
	}

	navigateToError() {
		// Cannot use NavigationService - injection would cause circular reference
		this.router.navigateByUrl(ROUTE_CONSTANTS.error.route);
	}

	navigateToLogin() {
		// Cannot use NavigationService - injection would cause circular reference
		this.router.navigateByUrl(ROUTE_CONSTANTS.login.route);
	}

	async navigateToSSObyEmail(userEmail: string = null) {
		try {
			await this.getCognitoClientByEmail(userEmail);
			await this.navigateToSSO();
		} catch (err) {
			console.log(err);
		}
		return this.cognitoClient ? true : false;
	}

	private async navigateToSSO() {
		const goToUrl = this.lookupDestination();
		/* Here we store Client/Auth for the final stage of the Cognito login process (landing page)
		It is not stored for subsequent logins, instead source is used to lookup Client/Auth for each new login
		*/
		this.storeSSOauth(goToUrl);
		window.location.href = goToUrl;
	}

	async getCognitoClientBySource(source: string) {
		this.cognitoClient = null;
		this.cognitoClient = await this.authApi.getCognitoClient(source)
			.toPromise()
			.catch((x) => this.onError(x)) as CognitoClient;
	}

	async getCognitoClientByEmail(userEmail: string) {
		this.cognitoClient = null;
		this.cognitoClient = await this.authApi.identifyClient(userEmail)
			.toPromise()
			.catch((x) => this.onError(x)) as CognitoClient;
	}

	private onError(error: any) {
		console.log(error);
	}

	lookupDestination() {
		return this.environmentService.cognitoUrl(this.cognitoClient.clientId);
	}

	determineSource(route: ActivatedRouteSnapshot) {
		// Source can be specified in the login PATH, in the URL, or can be stored in a cookie
		let ssoSource: string = null;
		try {
			ssoSource = route.paramMap.get(this.sourceParam);
		} catch (e) {}
		if (!ssoSource) {
			ssoSource = route.queryParamMap.get(this.sourceParam);
		}
		if (!ssoSource) {
			ssoSource = this.getSourceFromStorage();
		}

		if (ssoSource?.toLowerCase() == 'none') {
			this.clearSSOSource();
			ssoSource = null;
		}

		// We no longer store the Source here. It will only get stored upon successful login.
		return ssoSource;
	}

	storeSSOauth(url: string) {
		const urlparts = url.match(/https:\/\/([-_A-Za-z0-9\.]*).*client_id=([-_A-Za-z0-9]*)&?/);
		const urlAuth = (urlparts[1]) ? urlparts[1] : '';
		const clientId = (urlparts[2]) ? urlparts[2] : '';
		localStorage.setItem(LOCAL_STORAGE_KEY.SSO_AUTH, urlAuth);
		localStorage.setItem(LOCAL_STORAGE_KEY.SSO_CLIENTID, clientId);
	}

	storeSSOSource(sourceToken: string) {
		localStorage.setItem(LOCAL_STORAGE_KEY.SSO_SOURCE, sourceToken);
	}

	clearSSOSource() {
		localStorage.removeItem(LOCAL_STORAGE_KEY.SSO_SOURCE);
		localStorage.removeItem(LOCAL_STORAGE_KEY.SSO_CLIENTID);
		localStorage.removeItem(LOCAL_STORAGE_KEY.SSO_AUTH);
	}

	getSourceFromStorage() {
		return localStorage.getItem(LOCAL_STORAGE_KEY.SSO_SOURCE);
	}
}

export interface CognitoClient {
	source: string;
	clientId: string;
}
