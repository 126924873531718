import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class MarketScapeResolverService {

    private tracking: boolean;
    private $marketScapeTracker = new Observable<string>();
    marketScape = '';

    constructor(
        private router: Router) { }

    trackMarketScape(): Observable<string> {
        if (this.tracking) { return this.$marketScapeTracker; }

        this.$marketScapeTracker = this.router.events.pipe(
            filter(event => event.constructor.name === NavigationEnd.name),
            map((event: NavigationEnd) => this.marketScape = this._getMarketScape(event.url))
        );
        this.tracking = true;
        return this.$marketScapeTracker;
    }

    private _getMarketScape(url: string): string {
        const marketScape = url.split('/')[1];
        return marketScape;
    }
}