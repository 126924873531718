import { isArray, isEmpty } from 'lodash';
import Utils from '../Utils';
import { ComparisonBarOptions, ComparisonShadeOptions, ConfigColumnModel, SparklineOptions } from './report-config';
import { parseNumber } from '@progress/kendo-angular-intl';

/**
 * i == Integer
 * d == Decimal
 * p == Percent
 */
export const numericDataTypes = ['i', 'd', 'p'];

export interface SparklineGridColOptions {
	subtitles?: any[];
	fields: string[];
}

export interface Replacement {
	word: string;
	title: string;
	url: string;
}

export class GridColumn {
	field: string;
	title: string;
	rawTitle: string;
	definition: string;
	rawDefinition: string;
	replacements?: Replacement[];

	filterType: string;
	dataType: string;
	dataSubType: string;

	hidden = false;
	format: string;
	width: any;
	hideTitle = false;

	// Abilities
	clickable = false;
	sortable = true;
	filterable = true;
	decimalPoints = 1;
	definitionColumn = false;
	favoriteable = false;
	medicareModal = false;
	targetable = false;
	medicalDirector = false;
	locked = false;
	highlight = false;
	includeInColumnChooser = true;
	isWideColumn = false;
	isSmallColumn = false;

	comparisonBar?: ComparisonBarOptions;
	comparisonShade?: ComparisonShadeOptions;
	sparklineCol?: SparklineGridColOptions;
	columnType: GRID_COLUMN_TYPE;
	columns?: GridColumn[] = [];
	combinedFields?: GridColumn[] = [];

	maxCharacters?: number;

	constructor(config: ConfigColumnModel) {
		if (!config) {
			return;
		}

		this.columnType = config.type;
		if (this.columnType == GRID_COLUMN_TYPE.preheader) {
			this.setPreHeaderOptions(config);
		}

		this.setCommonOptions(config);
		this.setSparklineOptions(config.sparklineOptions);

		// pdf column logic - determing this logic on the grid level is too late and causes issues
		const isSparkLine = this.sparklineCol ? true : false;
		this.isWideColumn = !this.columnType && !isSparkLine && isEmpty(this.dataType) && this.width >= 150;
		this.isSmallColumn = this.favoriteable || this.targetable;
	}

	private setPreHeaderOptions(config: ConfigColumnModel) {
		let total = 0;
		this.columns = config.columns.map(x => {
			const newGridColumn = new GridColumn(x);
			if (x.combinedFields && x.combinedFields.length > 0) {
				newGridColumn.combinedFields = x.combinedFields.map(y => new GridColumn(y));
			}
			return newGridColumn;
		});
		this.columns.forEach((col) => {
			total += parseNumber(col.width);
		});

		this.width = total;
	}

	private setCommonOptions(config: ConfigColumnModel) {
		this.field = config.field;
		this.title = Utils.replaceQuarterWithinString(config.title);
		this.rawTitle = config.title;
		this.definition = Utils.replaceQuarterWithinString(config.definition);
		this.rawDefinition = config.definition;

		this.replacements = config.replacements;
		this.width = config.width;
		this.comparisonBar = config.comparisonBarOptions;
		this.comparisonShade = config.comparisonShadeOptions;
		this.hidden = config.hidden;
		this.hideTitle = config.hideTitle;
		this.filterable = config.filterable;
		this.decimalPoints = config.decimalPoints;
		this.definitionColumn = config.definitionColumn;
		this.locked = config.locked;
		this.highlight = config.highlight;
		this.includeInColumnChooser = config.includeInColumnChooser;

		if (config.sparklineOptions) {
			// Sparklines don't need a data type
			return;
		}

		this.combinedFields = config.combinedFields ? config.combinedFields.map(y => new GridColumn(y)) : null;

		this.format = config.flags;
		this.dataType = config.dataType;
		this.dataSubType = config.dataSubType;

		const isNumericType = this.dataType && numericDataTypes.find(x => x === this.dataType);

		this.filterType = isNumericType ? GRID_FILTER_TYPE.number : GRID_FILTER_TYPE.text;

		this.clickable = config.clickable;
		this.favoriteable = config.favoriteable;
		this.medicareModal = config.medicareModal;
		this.targetable = config.targetable;
		this.medicalDirector = config.medicalDirector;

		this.maxCharacters = config.maxCharacters;

		// Hide number filters for now as well anything with a fiterable value of false/undefined
		if (this.filterType === GRID_FILTER_TYPE.number || !config.filterable) {
			this.filterable = false;
		}
	}

	private setSparklineOptions(options: SparklineOptions) {
		if (!options) {
			return;
		}

		const fields = options.fields;
		if (isArray(fields)) {
			// Concatenate with a valid field character to get around console warnings
			this.field = fields.join('_');
		}

		this.sparklineCol = {
			subtitles: options.subtitle.map(subtitle => {
				return Utils.getParsedGridColumnHeader(subtitle);
			}),
			fields: fields
		};

		this.filterType = null;
		this.filterable = false;
		this.clickable = false;
		this.sortable = false;
		this.dataType = null;
		this.locked = false;
	}
}

export enum GRID_FILTER_TYPE {
	text = 'text',
	number = 'numeric',
	date = 'date',
	boolean = 'boolean'
}

export enum GRID_COLUMN_TYPE {
	preheader = 'preheader',
	normal = 'normal',
}

export enum COLUMN_DATA_TYPE {
	integer = 'i',
	percent = 'p',
	currency = 'c',
	date = 'd'
}
