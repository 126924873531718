import {Component, OnInit} from '@angular/core';
import { NavigationService } from '../shared/providers/navigation.service';
import {Router} from '@angular/router';

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['../../styles/components/cover-page.scss', './page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit{

	constructor(private navigationService: NavigationService, private router: Router) { }

	ngOnInit() {
		const currentURL = this.getCurrentURL();
		if (currentURL) {
			this.navigationService.navigateToLogin();
			return;
		}
	}

	getCurrentURL() {
		return this.router.url && !this.router.url.includes('login') && this.router.url.includes('.ashx') ? this.router.url : null;
	}
}
