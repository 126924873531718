<div id="chooseApplicationContent" class="row no-gutters p-3">
	<div class="col-auto mx-auto w-100">
		<div class="row">
			<div class="col cover-page-width">
				<div class="row">
					<div class="col-auto logo text-center">
						<img class="img-fluid logo-chooser" src="../../assets/icons/logo.png" />
					</div>
				</div>
				<div class="row justify-content-center" *ngIf="hasPortals">
					<div class="text-heading text-landing-big" id="chooseapplicationmessage">
						You're in Marketscape, {{userName}}.
					</div>
				</div>
				<div class="row justify-content-center mb-4" *ngIf="hasPortals">
					<div class="text-heading-sm text-landing-small" id="wherewouldyougo">
						Where would you like to go?
					</div>
				</div>
				<div class="row text-center">
					<div class="col-sm-12 col-md col-lg col-xl landing-button-wrapper">
						<div class="my-3 text-center single-landing-button" *ngFor="let portal of portals">
							<button type="button" id="goToMarketscape{{portal.toUpperCase()}}" (click)="navigateToPortal(portal)" class="landing-button">
								<div class="landing-icon mb-2 text-center"><img [src]="getDisplayIcon(portal)" class="landing-icon" /></div>
								<div class="landing-font">{{ getDisplayName(portal) }}</div>
							</button>
						</div>
					</div>
				</div>

				<div class="row" *ngIf="!hasPortals">
					<div class="col text-heading mt-4">
						<div class="row">
							<div class="col" id="noportalsmessage">
								You do not have access to any portals.
							</div>
						</div>
						<div class="row">
							<div class="col">
								Please contact customer service at
								<a class="text-primary"
									href="mailto:support@trellahealth.com">support@trellahealth.com</a>.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
