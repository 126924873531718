import {Injectable} from '@angular/core';
import {NpiInfo} from 'src/app/shared/models/npi';
import {PROVIDER_TYPE} from 'src/app/shared/shared.constants';

@Injectable({
	providedIn: 'root'
})
export class AnalyzeDataCacheService {
	private physicianData: { [npi: string]: NpiInfo } = {};
	private facilityData: { [npi: string]: NpiInfo } = {};
	private homeHealthData: { [npi: string]: NpiInfo } = {};
	private hospiceData: { [npi: string]: NpiInfo } = {};
	private skilledNursingData: { [npi: string]: NpiInfo } = {};

	getCachedData(type: PROVIDER_TYPE, npi: string) {
		if (!type || !npi) { return; }

		let cache = this.getCacheForProviderType(type);
		if (!cache) {
			return null;
		}

		return cache[npi];
	}

	updateCachedData(type: PROVIDER_TYPE, npi: string, info: NpiInfo) {
		if (!type || !npi) { return; }

		let cache = this.getCacheForProviderType(type);
		if (cache) {
			cache[npi] = info;
		}
	}

	private getCacheForProviderType(type: PROVIDER_TYPE) {
		switch (type) {
			case PROVIDER_TYPE.physician:
				return this.physicianData;

			case PROVIDER_TYPE.facility:
				return this.facilityData;

			case PROVIDER_TYPE.homehealth:
				return this.homeHealthData;

			case PROVIDER_TYPE.hospice:
				return this.hospiceData;
			case PROVIDER_TYPE.skillednursing:
				return this.skilledNursingData;
		}
	}
}
