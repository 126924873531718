<div class="row no-gutters p-3">
	<div class="col-auto mx-auto">
		<div class="row">
			<div class="col cover-page-width">
				<div class="row">
					<div class="col-auto logo text-center mb-2">
						<img class="img-fluid" src="../../assets/img/logo.jpg" />
					</div>
				</div>
                <div class="mt-5">
                    <p class="text-primary fa-15x">Please wait...</p>
                </div>
			</div>
		</div>
	</div>
</div>
