import { Component, ElementRef, Input } from '@angular/core';
import * as $ from 'jquery';

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
	isGlobal: boolean;
	showSpinner: boolean;

	get overlayClass() {
		return this.isGlobal ? 'global-overlay' : 'div-overlay';
	}

	get spinnerClass() {
		return this.isGlobal ? 'global-center-spinner' : 'center-spinner';
	}

	startSpinner(isGlobal: boolean = false) {
		this.showSpinner = true;
		this.isGlobal = isGlobal;
	}

	stopSpinner(isGlobal: boolean = false) {
		this.showSpinner = false;
		this.isGlobal = isGlobal;
	}
}
