import {Injectable} from '@angular/core';
import {UserApi} from 'src/app/api/user.api';
import {AuthApi} from '../../api/auth.api';
import {Auth} from 'aws-amplify';
import {LoginResponse} from '../models/authentication';
import {CurrentUserResponse} from '../models/user';
import {ApplicationCacheService} from './application-cache.service';
import {NavigationService} from './navigation.service';
import { CognitoClient, SSOService } from './sso.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	static async getCognitoToken() {
		return await Auth.currentSession()
			.then(res => {
				const token = res.getIdToken().getJwtToken();
				return token;
			})
			.catch(err => { console.log(err);
				return null;
			})
  }

	currentPromise: Promise<CurrentUserResponse>;

	constructor(private authApi: AuthApi, private userApi: UserApi, private navigationService: NavigationService, private appCache: ApplicationCacheService,
				private ssoService: SSOService) {
	}

	loginCognito(token: string) {
		return this.authApi.loginCognito(token);
	}

	identifyClient(username) {
		return this.authApi.identifyClient(username);
	}

	requestPasswordResetToken(username) {
		return this.userApi.requestPasswordResetToken(username);
	}

	updatePassword(newPassword, repeatPassword) {
		return this.userApi.updatePassword(newPassword, repeatPassword);
	}

	getDefaultCognitoClient(): Observable<CognitoClient> {
		return this.authApi.getDefaultCognitoClient();
	}

	async validPasswordResetToken(token) {
		return await this.userApi.validPasswordResetToken(token).toPromise();
	}

	onLogin(authResponse: LoginResponse) {
		if (!authResponse || !authResponse.authenticated) {
			return;
		}
		this.storeSource(authResponse.source);
	}

	storeSource(source: string) {
		if (source) {
			const currentSource = this.ssoService.getSourceFromStorage();
			if (source != currentSource) {
				this.ssoService.storeSSOSource(source);
			}
		}
	}

	checkTokenExpiryAndRefresh() {
		Auth.currentSession()
			.then(() => {
			})
			.catch(err =>
				console.log(err)
			);
	}

	async logout() {
		try {
			await this.authApi.logout().toPromise();
		} finally {
			await Auth.signOut();
			this.appCache.clearCachedValues();
			this.navigationService.navigateToLogOut();
		}
	}

	async getCurrentUser(): Promise<CurrentUserResponse> {
		if (this.currentPromise) {
			return this.currentPromise;
		}

		const resultsPromise = this.userApi.getCurrent().toPromise();
		this.currentPromise = resultsPromise;

		const results = await resultsPromise.catch(err => {
			this.currentPromise = null;
			this.navigationService.navigateToLogin();
			console.error(err);
			throw err;
		});

		const userResponse = new CurrentUserResponse(results);
		const {user: currentUser, configOptions, portals} = userResponse;
		this.appCache.setUser(currentUser);
		this.appCache.setConfigOptions(configOptions);

		this.currentPromise = null;
		return userResponse;
	}

	async getCachedUser() {
		let user = this.appCache.user;

		if (!user) {
			const response = await this.getCurrentUser();
			user = response.user;
		}

		return user;
	}
}
