import { Component, OnInit } from '@angular/core';
import { UserApi } from '../api/user.api';
import { ComponentWithSubscription } from '../shared/component-with-subscriptions';
import { ApplicationCacheService } from '../shared/providers/application-cache.service';
import { NavigationService } from '../shared/providers/navigation.service';
import { UserFeedbackService } from '../shared/providers/user-feedback.service';

@Component({
	selector: 'eula-page',
	templateUrl: './eula.component.html',
	styleUrls: ['./eula.component.scss']
})
export class EulaComponent extends ComponentWithSubscription implements OnInit {

	constructor(private navigationService: NavigationService,
				private applicationCacheService: ApplicationCacheService,
				private userApi: UserApi,
				private userFeedbackService: UserFeedbackService) {
		super();
	}

	ngOnInit() {}

	async updateEula(choice: boolean) {
		this.userFeedbackService.startSpinner();
		try {
			await this.userApi.updateEula(choice).toPromise();
		} catch(ex) {
			this.userFeedbackService.showCustomError(ex);
		}
		finally {
			this.userFeedbackService.stopSpinner();
		}

		if (!choice) {
			this.navigationService.navigateToLogin();
			return;
		}

		this.applicationCacheService.user.showEula = false;

		if(this.applicationCacheService.user.showDataEula) {
			this.navigationService.navigateToDataEula()
			return;
		}

		this.navigationService.navigateAfterLogin();
	}



}
