// configs that are both for CHARTS & GRIDS
export const COMMON = {
	TRENDED_ALOS_INP: 'facility_trended_alos_inp',
	TRENDED_ALOS_OUTP: 'facility_trended_alos_outp',
	TRENDED_ALOS_SNF: 'facility_trended_alos_snf',
	HOS_TRENDED_ALOS_INP: 'hos_facility_trended_alos_inp',
	HOS_TRENDED_ALOS_OUTP: 'hos_facility_trended_alos_outp',
	HOS_TRENDED_ALOS_SNF: 'hos_facility_trended_alos_snf',
	SNF_TRENDED_ALOS_INP: 'snf_facility_trended_alos_inp',
	SNF_TRENDED_ALOS_OUTP: 'snf_facility_trended_alos_outp',
	SNF_TRENDED_ALOS_SNF: 'snf_facility_trended_alos_snf',
	PATIENT_VOLUME_INP: 'facility_patient_volume_inp',
	PATIENT_VOLUME_OUTP: 'facility_patient_volume_outp',
	PATIENT_VOLUME_SNF: 'facility_patient_volume_snf',
	HOS_PATIENT_VOLUME_INP: 'hos_facility_patient_volume_inp',
	HOS_PATIENT_VOLUME_OUTP: 'hos_facility_patient_volume_outp',
	HOS_PATIENT_VOLUME_SNF: 'hos_facility_patient_volume_snf',
	SNF_PATIENT_VOLUME_INP: 'snf_facility_patient_volume_inp',
	SNF_PATIENT_VOLUME_OUTP: 'snf_facility_patient_volume_outp',
	SNF_PATIENT_VOLUME_SNF: 'snf_facility_patient_volume_snf',
	COUNTY: 'facility_county',
	ZIP: 'facility_zip',
	HOS_COUNTY: 'hos_facility_county',
	HOS_ZIP: 'hos_facility_zip',
	SNF_COUNTY: 'snf_facility_county',
	SNF_ZIP: 'snf_facility_zip',
	UTILIZATION_INP: 'facility_utilization_inp',
	UTILIZATION_OUTP: 'facility_utilization_outp',
	UTILIZATION_SNF: 'facility_utilization_snf',
	HOS_UTILIZATION_INP: 'hos_facility_utilization_inp',
	HOS_UTILIZATION_OUTP: 'hos_facility_utilization_outp',
	HOS_UTILIZATION_SNF: 'hos_facility_utilization_snf',
	SNF_UTILIZATION_INP: 'snf_facility_utilization_inp',
	SNF_UTILIZATION_OUTP: 'snf_facility_utilization_outp',
	SNF_UTILIZATION_SNF: 'snf_facility_utilization_snf',
	UTILIZATION_INP_INTENDED: 'facility_utilization_inp_intended',
	UTILIZATION_OUTP_INTENDED: 'facility_utilization_outp_intended',
	UTILIZATION_SNF_INTENDED: 'facility_utilization_snf_intended',
	UTILIZATION_INP_ACTUAL: 'facility_utilization_inp_actual',
	UTILIZATION_INP_INTENDED_ACTUAL: 'facility_utilization_inp_intended_actual',
	UTILIZATION_OUTP_ACTUAL: 'facility_utilization_outp_actual',
	UTILIZATION_SNF_ACTUAL: 'facility_utilization_snf_actual',
	HOS_UTILIZATION_INP_INTENDED: 'hos_facility_utilization_inp_intended',
	HOS_UTILIZATION_OUTP_INTENDED: 'hos_facility_utilization_outp_intended',
	HOS_UTILIZATION_SNF_INTENDED: 'hos_facility_utilization_snf_intended',
	HOS_UTILIZATION_INP_ACTUAL: 'hos_facility_utilization_inp_actual',
	HOS_UTILIZATION_INP_INTENDED_ACTUAL: 'hos_facility_utilization_inp_intended_actual',
	HOS_UTILIZATION_OUTP_ACTUAL: 'hos_facility_utilization_outp_actual',
	HOS_UTILIZATION_SNF_ACTUAL: 'hos_facility_utilization_snf_actual',
	SNF_UTILIZATION_INP_INTENDED: 'snf_facility_utilization_inp_intended',
	SNF_UTILIZATION_OUTP_INTENDED: 'snf_facility_utilization_outp_intended',
	SNF_UTILIZATION_SNF_INTENDED: 'snf_facility_utilization_snf_intended',
	SNF_UTILIZATION_INP_ACTUAL: 'snf_facility_utilization_inp_actual',
	SNF_UTILIZATION_INP_INTENDED_ACTUAL: 'snf_facility_utilization_inp_intended_actual',
	SNF_UTILIZATION_OUTP_ACTUAL: 'snf_facility_utilization_outp_actual',
	SNF_UTILIZATION_SNF_ACTUAL: 'snf_facility_utilization_snf_actual',
	TRENDED_READMIT_RATE: 'facility_trended_readmit_rate',
	HOS_TRENDED_READMIT_RATE: 'hos_facility_trended_readmit_rate',
	SNF_TRENDED_READMIT_RATE: 'snf_facility_trended_readmit_rate',
	MDC_ALL: 'facility_mdc_all',
	MDC_INPATIENT: 'facility_mdc_inpatient',
	HOSPICE_TIMING_INP: 'hospice_timing_inp',
	HOSPICE_TIMING_HHA: 'hospice_timing_hha',
	HOSPICE_TIMING_SNF: 'hospice_timing_snf',
	FACILITY_CHRONIC_CONDITIONS: 'facility_chronic_conditions',
	OUTPATIENT_GROUPS: 'facility_outpatient_groups',
	SOS_AMBULATORY: 'physician_sos_ambulatory',
	SOS_OTHER: 'physician_sos_other',
	SOS_HOSPITAL: 'physician_sos_hospital',
	SOS_SNF: 'physician_sos_snf',
	SOS_ASSISTED: 'physician_sos_assisted',
	SOS_OFFICE: 'physician_sos_office',
	SOS_TELEHEALTH: 'physician_sos_telehealth',
	PAYER_MIX: 'facility_payer_mix',
	PAYER_MIX_CLAIMS: 'facility_payer_mix_claims',
	PAYER_MIX_COUNTS: 'facility_payer_mix_counts'
};

export const CHARTS = {
	READMIT_RATE: 'facility_readmit_rate',
	READMIT_RATE_HOSPITAL: 'facility_readmit_rate_hospital',
	HOS_FACILITY_READMIT_RATE: 'hos_facility_readmit_rate_hha',
	HOS_READMIT_RATE_HOSPITAL: 'hos_facility_readmit_rate_hospital',
	READMIT_RATE_HHA: 'facility_readmit_rate_hha',
	HHA_READMIT_RATE_SNF: 'facility_readmit_rate_snf',
	HOS_READMIT_RATE_SNF: 'hos_facility_readmit_rate_snf',
	HOS_READMIT_RATE: 'hos_facility_readmit_rate',
	TOP_DESTINATION: 'facility_top_destination',
	TOP_DESTINATION_SNF: 'facility_top_destination_snf',
	TOP_DESTINATION_HOSPITAL: 'facility_top_destination_hospital',
	TOP_DRGS_HOSPITAL: 'facility_top_drgs_hospital',
	TOP_DESTINATION_HHA: 'facility_top_destination_hha',
	PATIENT_VOLUME: 'facility_patient_volume',
	PATIENT_VOLUME_HOSPITAL: 'facility_patient_volume_hospital',
	PATIENT_VOLUME_SNF: 'facility_patient_volume_snf',
	PATIENT_VOLUME_HHA: 'facility_patient_volume_hha',
	PATIENT_VOLUME_HOS: 'facility_patient_volume_hos',
	RISK_SCORE: 'facility_risk_score',
	RISK_SCORE_HOSPITAL: 'facility_risk_score_hospital',
	RISK_SCORE_SNF: 'facility_risk_score_snf',
	RISK_SCORE_HHA: 'facility_risk_score_hha',
	AGE: 'facility_age',
	MDC_INP: 'facility_mdc_inpatient',
	MDC_OUTP: 'facility_mdc_outpatient',
	MDC_HOME_HEALTH: 'facility_mdc_homehealth',
	MDC_SNF: 'facility_mdc_snf',
	DEBS_QUALITY_INP: 'debs_quality',
	DEBS_QUALITY_OUTP: 'debs_quality_outp',
	DEBS_QUALITY_SNF: 'debs_quality_snf',
	PAD_OUTCOMES_BY_SETTING_INP: 'pad_outcomes_by_setting',
	PAD_OUTCOMES_BY_SETTING_SNF: 'pad_outcomes_by_setting_snf',
	ADHERENCE_SETTINGS_INP: 'adherence_settings_inp',
	ADHERENCE_SETTINGS_OUTP: 'adherence_settings_outp',
	ADHERENCE_SETTINGS_SNF: 'adherence_settings_snf',
	DISCHARGE_SETTINGS_INP_HHA: 'discharge_events_inp_hha',
	DISCHARGE_SETTINGS_INP_HOS: 'discharge_events_inp_hos',
	DISCHARGE_SETTINGS_INP_SNF: 'discharge_events_inp_snf',
	DISCHARGE_SETTINGS_INP_IRF: 'discharge_events_inp_irf',
	DISCHARGE_SETTINGS_INP_LTCH: 'discharge_events_inp_ltch',
	DISCHARGE_SETTINGS_OUTP_HHA: 'discharge_events_outp_hha',
	DISCHARGE_SETTINGS_OUTP_HOS: 'discharge_events_outp_hos',
	DISCHARGE_SETTINGS_OUTP_SNF: 'discharge_events_outp_snf',
	DISCHARGE_SETTINGS_OUTP_IRF: 'discharge_events_outp_irf',
	DISCHARGE_SETTINGS_OUTP_LTCH: 'discharge_events_outp_ltch',
	DISCHARGE_SETTINGS_SNF_HHA: 'discharge_events_snf_hha',
	DISCHARGE_SETTINGS_SNF_HOS: 'discharge_events_snf_hos',
	DISCHARGE_SETTINGS_SNF_SNF: 'discharge_events_snf_snf',
	DISCHARGE_SETTINGS_SNF_IRF: 'discharge_events_snf_irf',
	DISCHARGE_SETTINGS_SNF_LTCH: 'discharge_events_snf_ltch',
	SNF_PAD_OUTCOMES_BY_SETTING_INP: 'snf_pad_outcomes_by_setting',
	SNF_PAD_OUTCOMES_BY_SETTING_SNF: 'snf_pad_outcomes_by_setting_snf',
	SNF_ADHERENCE_SETTINGS_INP: 'snf_adherence_settings_inp',
	SNF_ADHERENCE_SETTINGS_OUTP: 'snf_adherence_settings_outp',
	SNF_ADHERENCE_SETTINGS_SNF: 'snf_adherence_settings_snf',
	SNF_DISCHARGE_SETTINGS_INP_HHA: 'snf_discharge_events_inp_hha',
	SNF_DISCHARGE_SETTINGS_INP_HOS: 'snf_discharge_events_inp_hos',
	SNF_DISCHARGE_SETTINGS_INP_SNF: 'snf_discharge_events_inp_snf',
	SNF_DISCHARGE_SETTINGS_INP_IRF: 'snf_discharge_events_inp_irf',
	SNF_DISCHARGE_SETTINGS_INP_LTCH: 'snf_discharge_events_inp_ltch',
	SNF_DISCHARGE_SETTINGS_OUTP_HHA: 'snf_discharge_events_outp_hha',
	SNF_DISCHARGE_SETTINGS_OUTP_HOS: 'snf_discharge_events_outp_hos',
	SNF_DISCHARGE_SETTINGS_OUTP_SNF: 'snf_discharge_events_outp_snf',
	SNF_DISCHARGE_SETTINGS_OUTP_IRF: 'snf_discharge_events_outp_irf',
	SNF_DISCHARGE_SETTINGS_OUTP_LTCH: 'snf_discharge_events_outp_ltch',
	SNF_DISCHARGE_SETTINGS_SNF_HHA: 'snf_discharge_events_snf_hha',
	SNF_DISCHARGE_SETTINGS_SNF_HOS: 'snf_discharge_events_snf_hos',
	SNF_DISCHARGE_SETTINGS_SNF_SNF: 'snf_discharge_events_snf_snf',
	SNF_DISCHARGE_SETTINGS_SNF_IRF: 'snf_discharge_events_snf_irf',
	SNF_DISCHARGE_SETTINGS_SNF_LTCH: 'snf_discharge_events_snf_ltch',
	SNF_UTILIZATION: 'facility_snf_utilization',
	HOS_UTILIZATION: 'facility_hos_utilization',
	HHA_UTILIZATION: 'facility_hha_utilization',
	HHA_INTENDED_UTILIZATION: 'facility_hha_intended_utilization',
	HHA_ACTUAL_UTILIZATION: 'facility_hha_actual_utilization',
	HHA_TOP_FACILITY_SOURCES: 'homehealth_top_facility_sources',
	HHA_TOP_PHYSICIAN_SOURCES: 'homehealth_top_physician_sources',
	HOS_TOP_FACILITY_SOURCES: 'hospice_top_facility_sources',
	HOS_TOP_PHYSICIAN_SOURCES: 'hospice_top_physician_sources',
	SNF_TOP_FACILITY_SOURCES: 'skilled_nursing_top_facility_sources',
	SNF_TOP_PHYSICIAN_SOURCES: 'skilled_nursing_top_physician_sources',
};

export const HOS_CHARTS = {
	HOS_READMIT_RATE: 'hos_facility_readmit_rate',
	HOS_READMIT_RATE_HOSPITAL: 'hos_facility_readmit_rate_hospital',
	HOS_READMIT_RATE_HHA: 'hos_facility_readmit_rate_hha',
	HOS_READMIT_RATE_SNF: 'hos_facility_readmit_rate_snf',
	HOS_TOP_DESTINATION: 'hos_facility_top_destination',
	HOS_TOP_DRGS_HOSPITAL: 'hos_facility_top_drgs_hospital',
	HOS_TOP_DESTINATION_HHA: 'hos_facility_top_destination_hha',
	HOS_PATIENT_VOLUME: 'hos_facility_patient_volume',
	HOS_PATIENT_VOLUME_HOSPITAL: 'hos_facility_patient_volume_hospital',
	HOS_PATIENT_VOLUME_SNF: 'hos_facility_patient_volume_snf',
	HOS_PATIENT_VOLUME_HHA: 'hos_facility_patient_volume_hha',
	HOS_PATIENT_VOLUME_HOS: 'hos_facility_patient_volume_hos',
	HOS_RISK_SCORE: 'hos_facility_risk_score',
	HOS_RISK_SCORE_HOSPITAL: 'hos_facility_risk_score_hospital',
	HOS_RISK_SCORE_SNF: 'hos_facility_risk_score_snf',
	HOS_RISK_SCORE_HHA: 'hos_facility_risk_score_hha',
	HOS_AGE: 'hos_facility_age',
	HOS_PAD_OUTCOMES_BY_SETTING_INP: 'hos_pad_outcomes_by_setting',
	HOS_PAD_OUTCOMES_BY_SETTING_SNF: 'hos_pad_outcomes_by_setting_snf',
	HOS_ADHERENCE_SETTINGS_INP: 'hos_adherence_settings_inp',
	HOS_ADHERENCE_SETTINGS_OUTP: 'hos_adherence_settings_outp',
	HOS_ADHERENCE_SETTINGS_SNF: 'hos_adherence_settings_snf',
	HOS_DISCHARGE_SETTINGS_INP_HHA: 'hos_discharge_events_inp_hha',
	HOS_DISCHARGE_SETTINGS_INP_HOS: 'hos_discharge_events_inp_hos',
	HOS_DISCHARGE_SETTINGS_INP_SNF: 'hos_discharge_events_inp_snf',
	HOS_DISCHARGE_SETTINGS_INP_IRF: 'hos_discharge_events_inp_irf',
	HOS_DISCHARGE_SETTINGS_INP_LTCH: 'hos_discharge_events_inp_ltch',
	HOS_DISCHARGE_SETTINGS_OUTP_HHA: 'hos_discharge_events_outp_hha',
	HOS_DISCHARGE_SETTINGS_OUTP_HOS: 'hos_discharge_events_outp_hos',
	HOS_DISCHARGE_SETTINGS_OUTP_SNF: 'hos_discharge_events_outp_snf',
	HOS_DISCHARGE_SETTINGS_OUTP_IRF: 'hos_discharge_events_outp_irf',
	HOS_DISCHARGE_SETTINGS_OUTP_LTCH: 'hos_discharge_events_outp_ltch',
	HOS_DISCHARGE_SETTINGS_SNF_HHA: 'hos_discharge_events_snf_hha',
	HOS_DISCHARGE_SETTINGS_SNF_HOS: 'hos_discharge_events_snf_hos',
	HOS_DISCHARGE_SETTINGS_SNF_SNF: 'hos_discharge_events_snf_snf',
	HOS_DISCHARGE_SETTINGS_SNF_IRF: 'hos_discharge_events_snf_irf',
	HOS_DISCHARGE_SETTINGS_SNF_LTCH: 'hos_discharge_events_snf_ltch',
	HOS_SNF_UTILIZATION: 'hos_facility_snf_utilization',
	HOS_HOS_HHA_UTILIZATION: 'hos_facility_hha_utilization',
	HOS_HHA_INTENDED_UTILIZATION: 'hos_facility_hha_intended_utilization',
	HOS_HHA_ACTUAL_UTILIZATION: 'hos_facility_hha_actual_utilization'
};

export const MEDICARE_REIMBURSEMENT_CHARTS = {
	YEAR_TO_DATE: 'MedicareReimbursement_YTD',
	CURRENT_YEAR: 'MedicareReimbursement_CY',
	TWELVE_MONTH_ROLLING: 'MedicareReimbursement_12Rolling'
};

export const MEDICARE_REIMBURSEMENT_GRIDS = {
	MAIN_REPORT: 'medicare_reimbursement_main',
	DETAIL_REPORT: 'medicare_reimbursement_detail'
};

export const GRIDS = {
	MDC_PATIENT_MIX_INP: 'facility_mdc_patient_mix_inpatient',
	MDC_PATIENT_MIX_OUTP: 'facility_mdc_patient_mix_outpatient',
	MDC_PATIENT_MIX_SNF: 'facility_mdc_patient_mix_skillednursing',
	HOS_MDC_PATIENT_MIX_INP: 'hos_facility_mdc_patient_mix_inpatient',
	HOS_MDC_PATIENT_MIX_OUTP: 'hos_facility_mdc_patient_mix_outpatient',
	HOS_MDC_PATIENT_MIX_SNF: 'hos_facility_mdc_patient_mix_skillednursing',
	SNF_MDC_PATIENT_MIX_INP: 'snf_facility_mdc_patient_mix_inpatient',
	SNF_MDC_PATIENT_MIX_OUTP: 'snf_facility_mdc_patient_mix_outpatient',
	SNF_MDC_PATIENT_MIX_SNF: 'snf_facility_mdc_patient_mix_skillednursing',
	AGE: 'facility_chart_age_all',
	DEBS_INP: 'facility-debs_quality',
	DEBS_OUTP: 'facility-debs_quality_outp',
	DEBS_SNF: 'facility-debs_quality_snf',
	HOS_DEBS_INP: 'hos_facility-debs_quality',
	HOS_DEBS_OUTP: 'hos_facility-debs_quality_outp',
	HOS_DEBS_SNF: 'hos_facility-debs_quality_snf',
	SNF_DEBS_INP: 'snf_facility-debs_quality',
	SNF_DEBS_OUTP: 'snf_facility-debs_quality_outp',
	SNF_DEBS_SNF: 'snf_facility-debs_quality_snf',
	READMIT: 'facilityreadmit',
	POST_ACUTE_INP: 'facility-postacute-in',
	FAC_METRIC_ALL: 'facmetricall',
	FAC_METRIC_INP: 'facmetricinpatient',
	FAC_METRIC_OUTP: 'facmetricoutpatient',
	FAC_METRIC_HH: 'facmetrichomehealth',
	FAC_METRIC_SNF: 'facmetricsnf',
	FAC_SNF_METRIC_ALL: 'facsnfmetricall',
	FAC_SNF_METRIC_INP: 'facsnfmetricinpatient',
	FAC_SNF_METRIC_OUTP: 'facsnfmetricoutpatient',
	FAC_SNF_METRIC_HH: 'facsnfmetrichomehealth',
	FAC_SNF_METRIC_SNF: 'facsnfmetricsnf',
	FAC_MA_INP: 'facility_ma_inpatient',
	FAC_MA_OUTP: 'facility_ma_outpatient',
	FAC_MA_PATIENT_VOLUME_INP: 'facility_ma_patient_volume_inpatient',
	FAC_MA_PATIENT_VOLUME_OUTP: 'facility_ma_patient_volume_outpatient',
	FAC_DRG_INP: 'facility_drg_inpatient',
	FAC_BPCI: 'facility_bpci_metrics',
	HOS_FAC_BPCI: 'hos_facility_bpci_metrics',
	SNF_FAC_BPCI: 'snf_facility_bpci_metrics',
	FAC_RPG: 'facility_rpg',
	HOS_FAC_RPG: 'hos_facility_rpg',
	SNF_FAC_RPG: 'snf_facility_rpg'
};

