<div class="row no-gutters p-3">
	<div class="col-auto mx-auto">
		<div class="row">
			<div class="col cover-page-width">
				<div class="row">
					<div class="col-auto logo ">
						<img class="img-fluid" src="../../assets/img/logo.jpg" />
					</div>
				</div>

				<div class="row">
					<div class="col pt-3">
						<p class="text-primary fa-15x">Sorry, you do not have access to this page.</p>
						<p class="text-primary fa-15x">Please use your browser's back button to go back.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>