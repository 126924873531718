import { OnDestroy, Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
	template: ''
})
export class ComponentWithSubscription implements OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}