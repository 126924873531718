import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentWithSubscription } from '../shared/component-with-subscriptions';
import { CurrentUserResponse } from '../shared/models/user';
import { AuthenticationService } from '../shared/providers/authentication.service';
import { NavigationService } from '../shared/providers/navigation.service';
import { UserFeedbackService } from '../shared/providers/user-feedback.service';
import { PORTAL_TYPE, ROUTE_CONSTANTS } from '../shared/shared.constants';

@Component({
	selector: 'app-redirect',
	templateUrl: './redirect.component.html',
	styleUrls: ['../../styles/components/cover-page.scss']
})
export class RedirectComponent extends ComponentWithSubscription implements OnInit {
	ssoToken: string;
	ssoPortal: string;

	constructor(private activatedRoute: ActivatedRoute, private authService: AuthenticationService,
		private navigationService: NavigationService, private userFeedbackService: UserFeedbackService) {
		super();
	}

	async ngOnInit() {
		this.userFeedbackService.startSpinner();

		this.ssoToken = await AuthenticationService.getCognitoToken();
		this.ssoPortal = this.activatedRoute.snapshot.queryParams[ROUTE_CONSTANTS.sso.portalGetLabel];

		if (!this.ssoToken) {
			return this.onAuthError();
		}

		this.authService.getCurrentUser()
			.then((response: CurrentUserResponse) => {
				if (!response || !response.user) {
					// Something went wrong
					return this.onAuthError();
				}

				this.userFeedbackService.stopSpinner();

				switch (this.ssoPortal) {
					case PORTAL_TYPE.HHA:
					case PORTAL_TYPE.HOS:
					case PORTAL_TYPE.SNF:
						this.navigationService.navigateToPortal(this.ssoPortal);
						break;

					default:
						this.navigationService.navigateToChooseApp();
						break;
				}
			})
			.catch(() => {
				return this.onAuthError();
			})
	}

	private onAuthError() {
		this.navigationService.navigateToLogin();
		this.userFeedbackService.stopSpinner();
		return false;
	}

}
