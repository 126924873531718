import {Injectable} from '@angular/core';
import {TargetsApi} from '../../api/targets.api';
import {FavoritesApi} from '../../api/favorites.api';
import {AssigneeApi} from '../../api/assignee.api';
import {LIST_TYPES, PORTAL_TYPE} from '../shared.constants';
import {ApplicationCacheService} from './application-cache.service';
import {RecentService} from './recent.service';

@Injectable({
	providedIn: 'root'
})
export class MobileListService {
	// we should cache the lists
	favorites = {};
	targets = {};
	assignments = {};

	constructor(
		private targetsApi: TargetsApi,
		private favoriteApi: FavoritesApi,
		private assigneeApi: AssigneeApi,
		private appCache: ApplicationCacheService,
		private _recentService: RecentService) {
	}

	getItems(type: LIST_TYPES, portal: PORTAL_TYPE = this.appCache.portal) {
		const requestPortal = portal || this.appCache.portal;
		switch (type) {
			case LIST_TYPES.favorites:
				return this.favoriteApi.getPortalFavorites(requestPortal);
				break;
			case LIST_TYPES.targets:
				return this.targetsApi.getPortalTargets(requestPortal);
				break;
			case LIST_TYPES.assignments:
				return this.assigneeApi.getAll(requestPortal);
				break;
			case LIST_TYPES.recent:
				return this._recentService.getRecentItems();
				break;
		}
	}

	getPopulatedList(portal: PORTAL_TYPE) {
		// we'll just do them in order, if the first list is populated return its type
		if (this.assignments[portal] && this.assignments[portal].length > 0) {
			return LIST_TYPES.assignments;
		}

		if (this.favorites[portal] && this.favorites[portal].length > 0) {
			return LIST_TYPES.favorites;
		}

		if (this.targets[portal] && this.targets[portal].length > 0) {
			return LIST_TYPES.targets;
		}
	}
}
