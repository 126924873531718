import {Injectable} from '@angular/core';
import {remove} from 'lodash';
import {Target} from '../models/target';
import {ExcelConfigurationOptions, ExcelUser, UserFavorite} from '../models/user';
import {CLASSIFICATION_TYPE, CLASSIFICATION_TYPES, PAGE_TYPE, PORTAL_TYPE, PROVIDER_TYPE, ROUTE_CONSTANTS, TARGET_TYPE, TARGET_TYPE_OPTIONS, ProviderTypeConfig, SNF_Facility_Plural_Label, SNF_Facility_Singular_Label} from '../shared.constants';
import {NpiGroup, NpiGroupNpi} from '../models/npi-group';
import {NpiInfo} from '../models/npi';

@Injectable({
	providedIn: 'root'
})
export class ApplicationCacheService {
	portal: PORTAL_TYPE;
	page: PAGE_TYPE;
	provider: PROVIDER_TYPE;
	permissionedProviderTypes: PROVIDER_TYPE[];

	// TODO: set these fields in the config options class
	configOptions: ExcelConfigurationOptions;
	medicareReimbursementMarket = 'PA';

	user: ExcelUser = null;
	assigneeName = null;

	defaultTargetType: TARGET_TYPE;
	defaultClassificationType: CLASSIFICATION_TYPE;

	currentYear: string;
	currentMAYr: string;
	aco_new_year: string;
	psdCurrentQ: string;

	cachedNpiInfo = {};

	multiSortTracker = {};
	globalSearchType = {};


	constructor() {
		this.defaultTargetType = this.getTargetType();
		this.defaultClassificationType = this.getClassificationType();
	}

	get userId() {
		return this.user && this.user.userId;
	}

	get userIsAdmin() {
		return this.user && this.user.userIsAdmin;
	}

	get currentQuarter() {
		return this.configOptions && this.configOptions.currentQuarter;
	}

	setReturnUrl(url: string) {
		if (url) {
			localStorage.setItem('returnurl', url);
		} else {
			localStorage.removeItem('returnurl');
		}
	}

	getReturnUrl() {
		return localStorage.getItem('returnurl');
	}

	getTargetType() {
		if (this.defaultTargetType) {
			return this.defaultTargetType;
		}
		const targetTypeOptions = TARGET_TYPE_OPTIONS;
		this.defaultTargetType = JSON.parse(localStorage.getItem('targettype')) || targetTypeOptions[0].value;
		return this.defaultTargetType;
	}

	getClassificationType() {
		if (this.defaultClassificationType) {
			return this.defaultClassificationType;
		}

		const classificationTypeOptions = CLASSIFICATION_TYPES;
		this.defaultClassificationType = JSON.parse(localStorage.getItem('classificationtype')) || classificationTypeOptions[0];
		return this.defaultClassificationType;
	}

	setMultiSort(portal: PORTAL_TYPE, providerType: PROVIDER_TYPE, sortValue: boolean) {
		if (!portal || !providerType) {
			return;
		}
		const sortKey = `${portal}_${providerType}`;
		this.multiSortTracker[sortKey] = sortValue;
	}

	getMultiSort(portal: PORTAL_TYPE, providerType: PROVIDER_TYPE) {
		if (!portal || !providerType) {
			return;
		}
		const sortKey = `${portal}_${providerType}`;
		return this.multiSortTracker[sortKey];
	}


	setPortalType(type: PORTAL_TYPE) {
		if (!type) {
			console.warn('Invalid Portal Type');
			return;
		}
		localStorage.setItem('portal', type);
		this.portal = type;
	}

	setPageType(type: PAGE_TYPE) {
		if (!type) {
			console.warn('Invalid Page');
			return;
		}

		this.page = type;
	}

	setPermissionedProviders(providerTypes: PROVIDER_TYPE[]) {
		const providerType = this.provider || providerTypes[0];
		this.setProviderType(providerType);

		localStorage.setItem('permissionedProviderTypes', JSON.stringify(providerTypes));
		this.permissionedProviderTypes = providerTypes;
	}

	clearCachedValues() {
		this.setPermissionedProviders([]);
		this.setProviderType(null);
	}

	setProviderType(type: any) {
		switch (type) {
			case PROVIDER_TYPE.physician: {
				this.provider = PROVIDER_TYPE.physician;
				return;
			}
			case PROVIDER_TYPE.facility: {
				this.provider = PROVIDER_TYPE.facility;
				return;
			}
			case PROVIDER_TYPE.hospice: {
				this.provider = PROVIDER_TYPE.hospice;
				return;
			}
			case PROVIDER_TYPE.homehealth: {
				this.provider = PROVIDER_TYPE.homehealth;
				return;
			}
			case PROVIDER_TYPE.skillednursing: {
				this.provider = PROVIDER_TYPE.skillednursing;
				return;
			}
			default: {
				this.provider = null;
				console.warn('Invalid Provider Type');
				return;
			}
		}
	}

	setDefaultAgency(npi: string, portal: string) {
		if (!portal) {
			return;
		}

		if (!npi) {
			this.user.defaultAgency[portal] = null;
			return;
		}

		this.user.defaultAgency[portal] = npi;
	}

	setUser(user: ExcelUser) {
		this.user = user;
	}

	setConfigOptions(configOptions: ExcelConfigurationOptions) {
		this.configOptions = configOptions;
	}

	setFavorites(favorites: UserFavorite[], portal: string) {
		if (!portal) {
			return;
		}

		// if no favorites, just set to empty array
		if (!favorites) {
			this.user.favorites[portal] = [];
			return;
		}

		this.user.favorites[portal] = favorites;
	}

	clearFavorites(portal: string) {
		if (!portal) {
			return;
		}

		this.user.favorites[portal] = [];
	}

	setTargets(targets: Target[], portal: string) {
		if (!portal) {
			return;
		}

		this.user.targets[portal] = targets || [];
	}

	clearTargets(portal: string) {
		if (!portal) {
			return;
		}

		this.user.targets[portal] = [];
	}

	setNpiGroup(npiGroup: NpiGroup[], portal: string) {
		if (!portal) {
			return;
		}

		this.user.npiGroups[portal] = npiGroup || [];
	}

	setNpiGroupNpi(npiGroupNpi: Array<NpiGroupNpi>, npiGroup: NpiGroup, portal: string) {
		if (!portal) {
			return;
		}

		Object.assign(npiGroup.npis, npiGroupNpi || []);
	}

	addFavorite(favorite: UserFavorite, portal: string) {
		if (!portal || !favorite) {
			return;
		}

		if (!this.user || !this.user.favorites) {
			console.log('Unable to find user favorites cache');
			return;
		}

		this.user.favorites[this.portal].push(favorite);
	}

	removeFavorite(favorite: UserFavorite, portal: string) {
		if (!portal || !favorite) {
			return;
		}

		remove(this.user.favorites[portal], favorite);

	}

	addTarget(target: Target, portal: string) {
		if (!portal || !target) {
			return;
		}
		this.user.targets[this.portal].push(target);
	}

	removeTarget(target: Target, portal: string) {
		if (!portal || !target) {
			return;
		}

		remove(this.user.targets[portal], target);
	}

	isPortalHHA(): boolean {
		return this.portal === PORTAL_TYPE.HHA;
	}

	isPortalHOS(): boolean {
		return this.portal === PORTAL_TYPE.HOS;
	}

	isPortalSNF(): boolean {
		return this.portal === PORTAL_TYPE.SNF;
	}

	removeNpiGroup(npiGroup: NpiGroup, portal: string) {
		if (!portal || !npiGroup) {
			return;
		}

		remove(this.user.npiGroups[portal], npiGroup);
	}

	userSpecificKey(key: string) {
		if (!key) {
			return;
		}
		return `${key}_${this.userId}`;
	}

	providerTypePluralLabel(type: ProviderTypeConfig) {
		if (this.portal === PORTAL_TYPE.SNF && type && type.enum === PROVIDER_TYPE.facility) {
			return SNF_Facility_Plural_Label;
		}

		return type.label;
	}

	providerTypeSingularLabel(type: ProviderTypeConfig) {
		if (this.portal === PORTAL_TYPE.SNF && type && type.enum === PROVIDER_TYPE.facility) {
			return SNF_Facility_Singular_Label;
		}

		return type.label;
	}

	getGlobalSearchType() {
		return this.globalSearchType[this.portal];
	}

	getNpiInfo(npi: string, provider: PROVIDER_TYPE): NpiInfo {
		if (!npi || !provider) return;
		const key = this.npiInfoKey(npi, provider);
		return this.cachedNpiInfo[key];
	}

	saveNpiInfo(npi: string, provider: PROVIDER_TYPE, data: NpiInfo): void {
		if (!npi || !provider) return;
		const key = this.npiInfoKey(npi, provider);
		this.cachedNpiInfo[key] = data;
	}

	private npiInfoKey(npi: string, provider: PROVIDER_TYPE) {
		return `${this.portal}|${provider}|${npi}`;
	}
}
