import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { EnvService } from './env.service';
import { NavigationService } from './navigation.service';

@Injectable({
	providedIn: 'root'
})
export class PasswordResetGuard implements CanActivate {
	constructor(private envService: EnvService, private navigationService: NavigationService) { }

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (!this.envService.cognito_only) {
						return true;
				}
		this.navigationService.navigateToLogin();
		return false;
	}
}
