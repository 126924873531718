import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { takeUntil, finalize } from 'rxjs/operators';
import { UserFeedbackService } from 'src/app/shared/providers/user-feedback.service';
import { AuthenticationService } from 'src/app/shared/providers/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { ROUTE_CONSTANTS } from 'src/app/shared/shared.constants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from './custom-validators';
import { ComponentWithSubscription } from '@excelhealth/shared-components';

@Component({
	selector: 'app-password-change',
	templateUrl: './password-change.component.html',
	styleUrls: ['./password-change.component.scss']
})

export class PasswordChangeComponent extends ComponentWithSubscription implements OnInit {
	@Output() update = new EventEmitter();

	allRules = 4;
	metRules = 0;
	passwordValidations: FormGroup;

	get isValidPassword() {
		return this.allRules === this.metRules;
	}

	constructor(private activatedRoute: ActivatedRoute,
		private userFeedbackService: UserFeedbackService,
		private authenticationService: AuthenticationService,
		private formBuilder: FormBuilder) {
		super();
	}

	warning: string;
	password: string;
	confirmPassword: string;

	ngOnInit() {
		this.passwordValidations = this.setPasswordValidations();
	}

	setPasswordValidations(): FormGroup {
		return this.formBuilder.group(
			{
				password: ['', Validators.compose([
					// 1. Password Field is Required
					Validators.required,
					// 2. check whether the entered password has a number
					CustomValidators.patternValidator(/\d/, { hasNumber: true }),
					// 3. check whether the entered password has upper case letter
					CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
					// 4. check whether the entered password has a lower-case letter
					CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
					// 5. check whether the entered password has non-alphanumeric symbol
					CustomValidators.patternValidator(/[^a-zA-Z\d]/, { hasSpecialCharacter: true }),
					// 6. Has a minimum length of 8 characters
					Validators.minLength(8),
					])
				],
				confirmPassword: ['', Validators.compose([Validators.required])]
			},
			{
				// check whether our password and confirm password match
				validator: CustomValidators.passwordMatchValidator
			});
	}

	change() {
		this.warning = '';
		if (!this.password) {
			this.warning = 'New Password cannot be blank';
			return;
		}
		if (!this.confirmPassword) {
			this.warning = 'Repeated Password cannot be blank';
			return;
		}
		if (this.password !== this.confirmPassword) {
			this.warning = 'Passwords must match';
			return;
		}
		if (!/\d/.test(this.password)) {
			this.warning = 'Password must contain 1 number';
			return;
		}
		if (!/[A-Z]/.test(this.password)) {
			this.warning = 'Password must contain 1 uppercase letter';
			return;
		}
		if (!/[a-z]/.test(this.password)) {
			this.warning = 'Password must contain 1 lowercase letter';
			return;
		}
		if (this.password.length < 8) {
			this.warning = 'Password must be 8 characters';
			return;
		}
		if (!/[^a-zA-Z\d]/.test(this.password)) {
			this.warning = 'Password must contain 1 special character';
			return;
		}

		this.userFeedbackService.startSpinner();
		this.authenticationService.updatePassword(this.password, this.confirmPassword)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				async (obj) => {
					if (obj && obj.errorMsg) {
						this.userFeedbackService.showCustomWarning(obj.errorMsg);
						this.userFeedbackService.stopSpinner();
						return;
					}
					this.userFeedbackService.stopSpinner();
					this.update.emit([obj.result, this.password]);
				},
				x => {
					this.userFeedbackService.showError(x);
					this.userFeedbackService.stopSpinner();
				});
	}
}
