import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { HighchartsChartModule } from 'highcharts-angular';
import { Ng5SliderModule } from 'ng5-slider';
import { ToggleChartViewComponent } from '../main/analyze/toggle-chartview/toggle-chartview-component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { BarChartComponent } from './app-chart/chart-types/bar-chart/bar-chart.component';
import { BubbleChartComponent } from './app-chart/chart-types/bubble-chart/bubble-chart.component';
import { ChartTooltipComponent } from './app-chart/chart-types/chart-tooltip/chart-tooltip.component';
import { ChartWithAveragesComponent } from './app-chart/chart-types/chart-with-averages/chart-with-averages.component';
import { ColumnChartComponent } from './app-chart/chart-types/column-chart/column-chart.component';
import { InsightStarRatingComponent } from './app-chart/chart-types/insight-star-rating/insight-star-rating.component';
import { InsightComponent } from './app-chart/chart-types/insight/insight.component';
import { LineChartComponent } from './app-chart/chart-types/line-chart/line-chart.component';
import { PieChartComponent } from './app-chart/chart-types/pie-chart/pie-chart.component';
import { VennChartComponent } from './app-chart/chart-types/venn-chart/venn-chart.component';
import { InsightMultiComponent } from './app-chart/chart-types/label-data-extra/label-data-extra.component';
import { RiskScoreComponent } from './app-chart/chart-types/label-data-extra/data-label/data-label.component';
import { ChartComponent } from './app-chart/chart.component';
import { AddModalNpiSelectorComponent } from './app-grid/add-modal-npi-selector/add-modal-npi-selector.component';
import { ComparisonPopupComponent } from './app-grid/comparison-popup/comparison-popup.component';
import { CreateCustomListDialogComponent } from './app-grid/create-custom-list-dialog/create-custom-list-dialog.component';
import { CreateTargetListModalComponent } from './create-target-list-modal/create-target-list-modal.component';
import { DevInfoDialogComponent } from './app-grid/dev-info-dialog/dev-info-dialog.component';
import { ConfigDataInfoDialogComponent } from './app-grid/config-data-info/config-data-info-dialog.component';
import { ElementNotesComponent } from './app-grid/element-notes/element-notes.component';
import { ComparisonsDialogComponent } from './comparisons-dialog/comparisons-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { PrintDialogComponent } from './print-dialog/print-dialog.component';
import { EditShareListDialogComponent } from './edit-share-list-dialog/edit-share-list-dialog.component';
import { ListBoxComponent } from './list-box/list-box.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NpiCardDetailComponent } from './npi-card/npi-card-detail/npi-card-detail.component';
import { NpiCardComponent } from './npi-card/npi-card.component';
import { NpiFloatingList } from './npi-floating-list/npi-floating-list.component';
import { NpiInputVerifyComponent } from './npi-input-verify/npi-input-verify.component';
import { NpiSearchSelectComponent } from './npi-search-select/npi-search-select.component';
import { NpiSearchComponent } from './npi-search/npi-search.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SelectUserComponent } from './select-user/select-user.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StarsComponent } from './stars/stars.component';
import { TextTruncatePipe } from './text-truncate.pipe';
import { RouterModule } from '@angular/router';
import { TopInsightComponent } from './app-chart/chart-types/label-data-extra/topinsight/top-insight-component';
import { GridChartDropDownComponent } from '../shared/components/grid-chart-dropdown.component';
import { HasPermissionDirective } from '../shared/directives/hasPermissions.directive';
import { CustomTitleCasePipe } from '../shared/pipes/title-case.pipe';
import { MultiValueCellComponent } from './app-grid/multi-value-cell/multi-value-cell.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GridFormatterPipe } from '../shared/pipes/grid-formatter.pipe';
import { ColumnChooserComponent } from './column-chooser/column-chooser.component';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { ComponentWithSubscription } from '../shared/component-with-subscriptions';
import { AbstractValueAccessor } from '../shared/AbstractValueAccessor';
import { BaseChart } from './app-chart/chart-types/base-chart';
import { AnalyzeProvidersComponentBase } from '../main/analyze/analyze-providers-base';
import { TargetsService } from '../shared/providers/targets.service';
import { FavoritesService } from '../shared/providers/favorites.service';
import { BaseCreateListModal } from './app-grid/base-create-list-modal';
import { AssigneesService } from '../shared/providers/assignees.service';
import { UserListService } from './select-user/user-list.service';
import { ShareListService } from './edit-share-list-dialog/share-list.service';
import { GridActionService } from '../shared/providers/grid-action.service';
import { CompanyService } from '../shared/providers/company.service';
import { CustomValidators } from './password-change/custom-validators';
import { BaseComponent } from '../shared/base.component';
import { MultiSortToggleComponent } from './multi-sort-toggle/multi-sort-toggle.component';
import { BenchmarkInsightComponent } from './app-chart/chart-types/label-data-extra/benchmark/benchmark-component/benchmark.component';
import { TopPlacesInsightComponent } from './app-chart/chart-types/label-data-extra/top-places/top-places.component';
import { DropdownComponent } from './app-dropdown/app-dropdown.component';
import { DropDownService } from './app-dropdown/dropdown.service';
import { AdvancedSearchComponent } from './app-grid/advanced-search/advanced-search.component';
import { ComponentsModule, DemographicCardComponent } from '@excelhealth/shared-components';
import { AnalyzeInsightsService } from '../shared/providers/analyze-insights.service';
import { TalkingPointsService } from '../shared/providers/talking-points-service';
import { GeoLocationService } from '../shared/providers/geolocation.service';
import { NormalTabSectionComponent } from '../main/analyze/normal-tab-section/normal-tab-section.component';
import { InsightTabSectionComponent } from '../main/analyze/insight-tab-section/insight-tab-section.component';
import { AppTalkingPointComponent } from './app-talking-point/app-talking-point.component';
import { SpotlightEmailChooserComponent } from '../main/analyze/components/spotlight-email-chooser/spotlight-email-chooser.component';
import { PatientVolumeBenchmarkComponent } from './app-chart/chart-types/label-data-extra/benchmark/patient-volume-benchmark/patient-volume-benchmark.component';
import { BenchmarkFactoryComponent } from './app-chart/chart-types/label-data-extra/benchmark/benchmark-factory.component';
import { AppMultiInsightsComponent } from './app-multi-insights/app-multi-insights.component';
import { DrgBenchmarkInsightComponent } from './app-chart/chart-types/label-data-extra/benchmark/drg-benchmark-component/drg-benchmark.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationButtonDirective } from '../shared/directives/navigation-button.directive';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MobileListComponent} from './mobile-list/mobile-list.component';
import {TrellaListComponent} from './list/trellalist.component';
import {ListExpandComponent} from './list-expand/list-expand.component';
import {MobileHeaderComponent} from '../main/mobile-analyze/components/mobile-header/mobile-header.component';
import {TopMetricsComponent} from '../main/mobile-analyze/components/top-metrics/top-metrics.component';
import {AppPortalChartComponent} from './app-portal-chart/app-portal-chart.component';
import {BaseMetricComponent} from '../main/mobile-analyze/metrics/provider-metrics/base-metric.component';
import {MyAgencyDropdownComponent} from '../main/mobile-analyze/components/my-agency-dropdown/my-agency-dropdown.component';
import {MetricsComponent} from '../main/mobile-analyze/metrics/metrics.component';
import {FacilityComponent} from '../main/mobile-analyze/metrics/provider-metrics/facility/facility.component';
import {HomehealthComponent} from '../main/mobile-analyze/metrics/provider-metrics/homehealth/homehealth.component';
import {HospiceComponent} from '../main/mobile-analyze/metrics/provider-metrics/hospice/hospice.component';
import {PhysicianComponent} from '../main/mobile-analyze/metrics/provider-metrics/physician/physician.component';
import {SkillednursingComponent} from '../main/mobile-analyze/metrics/provider-metrics/skillednursing/skillednursing.component';
import {GridComponent} from './app-grid/app-grid.component';
import {CreateFavoriteListModalComponent} from './app-grid/create-favorite-list-modal/create-favorite-list-modal.component';
import {AddToCustomListDialogComponent} from './app-grid/add-to-custom-list-dialog/add-to-custom-list-dialog.component';
import {CreateAssigneeListModalComponent} from './app-grid/create-assignee-list-modal/create-assignee-list-modal.component';
import {RemoveAssigneeModalComponent} from './app-grid/remove-assignee-modal/remove-assignee-modal.component';
import {AppMobileTableComponent} from './app-mobile-table/app-mobile-table.component';
import {AppTableComponent} from './app-table/app-table.component';
import {AppTablePopupComponent} from './app-table/app-table-popup/app-table-popup.component';
import {SearchResultsComponent} from './npi-search/search-results/search-results.component';
import {MobileUpdateModalComponent} from './mobile-update-modal/mobile-update-modal.component';
import {AppMyAgencyModalComponent} from '../main/mobile-analyze/components/my-agency-dropdown/my-agency-modal/my-agency-modal.component';
import {TextBoxModule} from '@progress/kendo-angular-inputs';
import {SearchProviderFiltersComponent} from './npi-search/search-provider-filters/search-provider-filters.component';
import {SearchLocationFiltersComponent} from './npi-search/search-location-filters/search-location-filters.component';
import {CompareToModalComponent} from '../main/mobile-analyze/components/compare-to-dropdown/compare-to-modal/compare-to-modal.component';
import {CompareToDropdownComponent} from '../main/mobile-analyze/components/compare-to-dropdown/compare-to-dropdown.component';

@NgModule({
	imports: [
		CommonModule, FormsModule, ReactiveFormsModule, MatAutocompleteModule,
		MatCardModule, MatCheckboxModule, MatSlideToggleModule, MatDatepickerModule, MatInputModule, MatNativeDateModule,
		MatFormFieldModule, MatSelectModule,
		MatRadioModule, MatProgressBarModule, MatTabsModule, TooltipModule, PopupModule, ChartsModule, Ng5SliderModule, DialogsModule, HighchartsChartModule,
		ScrollingModule, MatListModule, DropDownListModule, DropDownsModule,

		GridModule, PDFModule, ExcelModule, RouterModule, // Not exported - use App-Grid instead
		DragDropModule,

		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatFormFieldModule,
		MatSelectModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		MatDatepickerModule,
		MatInputModule,
		MatNativeDateModule,
		MatRadioModule,
		MatProgressBarModule,
		MatTabsModule,
		MatCardModule,
		ChartsModule,
		DialogsModule,
		DropDownListModule,
		DropDownsModule,
		PopupModule,
		TooltipModule,
		ExcelModule,
		GridModule,
		PDFModule,
		HighchartsChartModule,
		RouterModule,
		NgbDropdownModule,
		ComponentsModule, MatSidenavModule, MatIconModule, TextBoxModule
	],
	declarations: [
		GridChartDropDownComponent, AppMultiInsightsComponent, DrgBenchmarkInsightComponent,
		SpinnerComponent, NotificationsComponent, TextTruncatePipe, GridComponent, ConfirmationDialogComponent,
		PrintDialogComponent, DropdownComponent, ListExpandComponent, MobileHeaderComponent,

		ListBoxComponent, ProgressBarComponent,
		BarChartComponent, LineChartComponent, ColumnChartComponent, BubbleChartComponent, VennChartComponent, PieChartComponent,
		ChartWithAveragesComponent, ChartTooltipComponent, InsightStarRatingComponent, StarsComponent, InsightComponent,
		ComparisonsDialogComponent, ChartComponent, SearchProviderFiltersComponent,
		NpiInputVerifyComponent, NpiCardComponent, NpiFloatingList, NpiCardDetailComponent,
		NpiSearchComponent, NpiSearchSelectComponent, SearchResultsComponent,
		CreateTargetListModalComponent, CreateFavoriteListModalComponent, AddModalNpiSelectorComponent, CreateCustomListDialogComponent, AddToCustomListDialogComponent, ConfirmDialogComponent,
		AdvancedSearchComponent, AppTalkingPointComponent, BenchmarkFactoryComponent,
		AlertDialogComponent, EditShareListDialogComponent, PasswordChangeComponent, SelectUserComponent,
		ElementNotesComponent, ToggleChartViewComponent, ComparisonPopupComponent,
		InsightMultiComponent, RiskScoreComponent, TopInsightComponent, BenchmarkInsightComponent, TopPlacesInsightComponent, MultiValueCellComponent, PatientVolumeBenchmarkComponent,
		DevInfoDialogComponent, ConfigDataInfoDialogComponent, CreateAssigneeListModalComponent, RemoveAssigneeModalComponent,
		HasPermissionDirective, CustomTitleCasePipe, SearchLocationFiltersComponent,
		GridFormatterPipe, ColumnChooserComponent, MultiSortToggleComponent, MobileUpdateModalComponent,
		NormalTabSectionComponent, InsightTabSectionComponent, AppPortalChartComponent, AppMobileTableComponent, AppTableComponent,
		SpotlightEmailChooserComponent, MobileListComponent, TrellaListComponent, TopMetricsComponent, MyAgencyDropdownComponent, AppMyAgencyModalComponent,
		MetricsComponent, FacilityComponent, HomehealthComponent, HospiceComponent, PhysicianComponent, SkillednursingComponent, AppTablePopupComponent,
		CompareToModalComponent, CompareToDropdownComponent,

		// abstract / base classes
		ComponentWithSubscription,
		AbstractValueAccessor,
		BaseChart,
		AnalyzeProvidersComponentBase,
		TargetsService,
		FavoritesService,
		BaseCreateListModal,
		AssigneesService,
		UserListService,
		ShareListService,
		CompanyService,
		CustomValidators,
		BaseComponent,
		GridActionService,
		DropDownService,
		AnalyzeInsightsService,
		GeoLocationService,
		NavigationButtonDirective,
		BaseMetricComponent,
	],
	exports: [
		CommonModule, FormsModule, ReactiveFormsModule, MatSelectModule,
		MatCheckboxModule, MatSlideToggleModule, MatDatepickerModule, MatInputModule, MatNativeDateModule, MatFormFieldModule,
		MatRadioModule, MatProgressBarModule, MatTabsModule, TooltipModule, PopupModule, ChartsModule, Ng5SliderModule, DialogsModule, HighchartsChartModule,
		ScrollingModule, MatListModule, DropDownListModule, DropDownsModule, DragDropModule, MobileHeaderComponent,

		SpinnerComponent, NotificationsComponent, TextTruncatePipe, GridComponent, ConfirmationDialogComponent,
		PrintDialogComponent, GridChartDropDownComponent, DropdownComponent, NormalTabSectionComponent, SearchResultsComponent,

		MobileListComponent, MetricsComponent, FacilityComponent, HomehealthComponent, HospiceComponent, PhysicianComponent, SkillednursingComponent,

		ToggleChartViewComponent, ListBoxComponent, ElementNotesComponent, ListExpandComponent, SearchProviderFiltersComponent, SearchLocationFiltersComponent,
		InsightTabSectionComponent, AppTalkingPointComponent, AppMultiInsightsComponent, DrgBenchmarkInsightComponent, MobileUpdateModalComponent,
		MultiValueCellComponent, ProgressBarComponent, BarChartComponent, LineChartComponent, ColumnChartComponent, BubbleChartComponent,
		VennChartComponent, PieChartComponent, ChartTooltipComponent, ChartWithAveragesComponent, InsightStarRatingComponent, StarsComponent, InsightComponent,
		CreateCustomListDialogComponent, AddToCustomListDialogComponent, ComparisonsDialogComponent, ChartComponent, TrellaListComponent,
		NpiInputVerifyComponent, NpiCardComponent, NpiFloatingList, ConfirmDialogComponent, ConfigDataInfoDialogComponent, DevInfoDialogComponent,
		AlertDialogComponent, EditShareListDialogComponent, SelectUserComponent, AppPortalChartComponent, AppMobileTableComponent, AppTableComponent,
		NpiCardDetailComponent, NpiSearchComponent, NpiSearchSelectComponent, PasswordChangeComponent, CreateAssigneeListModalComponent, RemoveAssigneeModalComponent,
		HasPermissionDirective, ColumnChooserComponent, MultiSortToggleComponent, BenchmarkFactoryComponent, AppMyAgencyModalComponent,
		NpiCardDetailComponent, NpiSearchComponent, NpiSearchSelectComponent, PasswordChangeComponent, CustomTitleCasePipe, GridFormatterPipe, AppTablePopupComponent,
		SpotlightEmailChooserComponent, NavigationButtonDirective, DemographicCardComponent, CreateTargetListModalComponent, TopMetricsComponent, MyAgencyDropdownComponent,
		CompareToModalComponent, CompareToDropdownComponent,
	]
})
export class ExcelComponentsModule { }
