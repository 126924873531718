<div class="row p-3">
	<div class="col-auto mx-auto">

		<div class="row">
			<div class="col cover-page-width">

				<div class="row py-md-5">
					<div class="col-auto logo ">
						<img class="img-fluid" src="../../assets/img/logo.jpg" />
					</div>
				</div>

				<div class="row text-center">
					<div class="col">
						<p class="text-primary fa-15x">You are logged out of Marketscape.</p>
							<div class="btn btn-lg btn-primary" (click)="toLogin()">Login to Marketscape</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
