import { PORTAL_TYPE, PROVIDER_TYPE, PERMISSIONS } from '../shared.constants';
import { Target } from './target';
import { NpiInfo } from './npi';
import { NpiGroup } from './npi-group';

export class ExcelUser {
	userId: number;
	username: string;
	name: string;
	email: string;
	status: string;
	title: string;
	customerId: number;
	failedLoginAttemptCount: number;
	expirationDate: string;
	lastPasswordChangeDate: string;
	userAccess: UserAccess;
	targets: Target[] = [];
	favorites: UserFavorite[] = [];
	npiGroups: NpiGroup[] = [];
	userIsAdmin: boolean;
	permissions: string[];
	customerName: string;
	purposeId: string;
	salesForceId: string;
	showEula?: boolean;
	showDataEula?: boolean;
	isTempPassword: boolean;
	token: string;
	isManager: boolean;

	defaultAgency: string[] = [];

	sparkUserId: string;

	get portals(): PORTAL_TYPE[] {
		return this.userAccess && this.userAccess.getPortals();
	}

	constructor(obj: any) {
		for (let key in obj) {
			switch (key) {
				case 'userAccess':
					this[key] = new UserAccess(obj[key], obj.userPermissions);
					break;
				case 'userPermissions':
					this.setPermissions(obj[key]);
					break;
				default:
					this[key] = obj[key];
					break;
			}
		}
	}

	setPermissions(permissions: any[]) {
		this.permissions = permissions.map(permission => permission.featureTag);
	}
}

export class UserAccess {
	hha;
	hos;
	snf;

	constructor(private _userAccess, private _permissions) {
		this.hha = _userAccess.HHA;
		this.hos = _userAccess.HOS;
		this.snf = _userAccess.SNF;
	}
	getPortals(): PORTAL_TYPE[] {
		return <PORTAL_TYPE[]>Object.keys(this._userAccess).map(portal => portal.toLocaleLowerCase());
	}
}

export class CurrentUserResponse {
	user: ExcelUser;
	configOptions: ExcelConfigurationOptions;
	portals: PORTAL_TYPE[];

	constructor(response: any) {
		this.user = new ExcelUser(response.user);
		this.configOptions = response.configOptions;
		this.portals = this.user.userAccess.getPortals();
	}
}

export interface ExcelConfigurationOptions {
	selectAllMax: number;
	currentQuarter: string;
	currentMAYr: string;
	acoNewYear: string;
	psdCurrentQ: string;
}

export interface ResetPasswordResponse {
	errorMsg(errorMsg: any);
	success: any;
	username: string;
}

export class UserFavorite {
	lookupInfo: NpiInfo;
	constructor(public npi: string, public providerType: PROVIDER_TYPE, public name?: string) { }
}

export interface UserListDetail {
	userId: number;
	name: string;
	email: string;
}
