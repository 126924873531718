import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { NavigationService } from './navigation.service';
import { ApplicationCacheService } from './application-cache.service';
import Utils from '../Utils';
import { PermissionsService } from './permissions.service';
import { SSOService } from './sso.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthenticationService,
		private appCache: ApplicationCacheService,
		private navigationService: NavigationService,
		private permissionService: PermissionsService,
		private ssoService: SSOService, ) { }

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const token = await AuthenticationService.getCognitoToken();
		if (token) {
			this.setPermissionedProviders();

			if (this.appCache.user && this.appCache.user.isTempPassword) {
				const params = { token: this.appCache.user.token };
				this.navigationService.navigateToChangePassword(params);
				return;
			}

			if (this.appCache.user) {
				return true;
			}

			// Haven't retrieved the current user yet, so do that now
			try {
				const response = await this.authService.getCurrentUser();
				const { user } = response;
				if (!user) {
					// Something went wrong
					return this.onAuthError(state, route);
				}
				this.navigationService.navigateToChooseApp();
				return true;
			} catch (ex) {
				return this.onAuthError(state, route);
			}
		}
		// when emptying local tokens, this works OK - navigate to CLI
		return this.onAuthError(state, route);
	}

	setPermissionedProviders() {
		let permissionedProviders = [];
		if (this.appCache.portal) {
			permissionedProviders = Utils.getAvailableProviderTypesConfigsForPortal(this.appCache.portal)
			.filter(p => this.permissionService.hasPermissions(p.permission))
			.map(p => p.enum);
		}
		this.appCache.setPermissionedProviders(permissionedProviders);
	}

	private onAuthError(state, route: ActivatedRouteSnapshot) {
		this.appCache.setReturnUrl(state.url && !state.url.includes('login') ? state.url : null);
		const sourceToken = this.ssoService.determineSource(route);
		this.navigationService.navigateToLogin(undefined, undefined, sourceToken);
		return false;
	}
}
