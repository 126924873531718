import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { LoginResponse } from '../shared/models/authentication';
import { ApplicationCacheService } from '../shared/providers/application-cache.service';
import { AuthenticationService } from '../shared/providers/authentication.service';
import { NavigationService } from '../shared/providers/navigation.service';
import { UserFeedbackService } from '../shared/providers/user-feedback.service';
import { ROUTE_CONSTANTS } from '../shared/shared.constants';
import { LogService } from '../shared/providers/log.service';
import { ComponentWithSubscription } from '@excelhealth/shared-components';
import { SSOService } from '../shared/providers/sso.service';
import { TokenService } from '../shared/services/token.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['../../styles/components/cover-page.scss', './login.component.scss']
})
export class LoginComponent extends ComponentWithSubscription implements OnInit {
	username: string;
	password: string;

	message: string;
	returnUrl: string;
	defaultError = 'Incorrect username or password';

	constructor(private activatedRoute: ActivatedRoute,
				private navigationService: NavigationService,
				private applicationCacheService: ApplicationCacheService,
				private authenticationService: AuthenticationService,
				private userFeedbackService: UserFeedbackService,
				private logService: LogService,
				private ssoService: SSOService) {
		super();
	}

	ngOnInit() {
		this.navigationService.setReturnUrl(this.returnUrl);
		const validCognitoToken = TokenService.getCognitoToken().then(token => {
			if (token)
				this.navigationService.navigateAfterLogin();
			});
		
		this.message = '';

		// If source is already specified, we do not need to stop at this page. Forward to actual login
		const source = this.ssoService.determineSource(this.activatedRoute.snapshot);
		if (source) {
			this.ssoService.navigateToSSObySource(source);
		}
		else {
			this.authenticationService.getDefaultCognitoClient().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
				next: cognitoClient => {
					console.log(cognitoClient);
					if (cognitoClient)
						this.ssoService.navigateToSSObySource(cognitoClient.source);
					else
						this.setErrorMessage();
				},
				error: _ => this.setErrorMessage()
			});
		}
	}

	private setErrorMessage(): void {
		this.message = 'Error processing request.';
	}
}
